import moment from "moment"

function ConfirmRequest(props: any) {

    const { serviceRequestDetails, saveServiceRequest,
        isConfirmRequest, setIsConfirmRequest, serviceRequestDynamicData } = props.props


    console.log({ serviceRequestDetails, serviceRequestDynamicData }, "confirm")
    console.log(serviceRequestDynamicData?.location_type?.filter((obj: any) => (obj.location_uuid === serviceRequestDetails.location_type_uuid))[0]?.location_name)


    const requestServiceCount = () => {
        let totalCount: number = 0;
        serviceRequestDetails?.officer_request_type?.map((value: any) => (
            totalCount = Number(totalCount) + Number(value?.request_count)
        ))
        return totalCount ? totalCount : 0;
    }

    return (
        <div
            className="modal fade show"
            id="exampleModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: "block", paddingLeft: "0px" }}
        >
            <div className="modal-dialog ">
                <div className="modal-content">
                    <div className="modal-header border-0 px-4">
                        <h5
                            className="modal-title font-bold font-20 color-black-v2"
                            id="exampleModalLabel"
                        >
                            Confirm Request
                        </h5>
                        <button
                            type="button"
                            className="btn-close custom-btn-close"
                            aria-label="Close"
                            onClick={() => { setIsConfirmRequest(!isConfirmRequest) }}
                        />
                    </div>
                    <div className="modal-body px-4 py-0 popup-scroll">
                        <div className="row ">
                            <div className="col-lg-8 p-3">
                                <div className="d-flex align-items-center gap-2 font-semibold font-14 text-color-1">
                                    <img className="icon-sty" src="../images/event.svg" alt="event" />
                                    {serviceRequestDetails?.service_request}
                                </div>
                            </div>
                            <div className="col-lg-4 p-3">
                                <div className="d-flex align-items-center gap-2 font-semibold font-14 text-color-1">
                                    <img
                                        className="icon-sty"
                                        src="../images/people.png"
                                        alt="people"
                                    />
                                    {requestServiceCount()}
                                </div>
                            </div>
                            <div className="col-lg-8 p-3">
                                <div className="d-flex align-items-center gap-2 font-semibold font-14 text-color-1">
                                    <img
                                        className="icon-sty"
                                        src="../images/location.svg"
                                        alt="location"
                                    />
                                    {serviceRequestDynamicData?.location_type?.filter((obj: any) => (obj.location_uuid === serviceRequestDetails.location_type_uuid))[0]?.location_name}
                                </div>
                            </div>
                            <div className="col-lg-4 p-3">
                                <div className="d-flex align-items-center gap-2 font-semibold font-14 text-color-1">
                                    <img className="icon-sty" src="../images/alert.svg" alt="alert" />
                                    {serviceRequestDynamicData?.cause_of_incident?.filter((obj: any) => (obj.incident_uuid === serviceRequestDetails.cause_of_incident_uuid))[0]?.incident_name}
                                </div>
                            </div>
                            <div className="col-lg-12 p-3">
                                <div className="d-flex align-items-center gap-2 font-semibold font-14 text-color-1">
                                    <img
                                        className="icon-sty"
                                        src="../images/calendar.svg"
                                        alt="calendar"
                                    />
                                    <span>{moment(serviceRequestDetails?.dates?.start_date)?.format('ddd, DD MMM YYYY')} - {moment(serviceRequestDetails?.dates?.end_date)?.format('ddd, DD MMM YYYY')} | {moment(serviceRequestDetails?.start_time, 'HH:mm')?.format('h:mm A')} - {moment(serviceRequestDetails?.end_time, 'HH:mm')?.format('h:mm A')}</span>
                                </div>
                            </div>
                            <div className="my-3">
                                <p className="font-semibold color-black-v2 font-13 mb-2">
                                    Officer Request Type
                                </p>
                                <p className="font-medium font-13 text-color-1">
                                    {serviceRequestDetails?.officer_request_type?.map((value: any, index: number): any => (
                                        `${value?.request_type_name}(${value?.request_count})`
                                    ))}
                                </p>
                            </div>
                            <div className="mb-3">
                                <p className="font-semibold color-black-v2 font-13 mb-2">
                                    Officer Request Duties
                                </p>
                                <p className="font-medium font-13 text-color-1">
                                    {serviceRequestDetails?.requested_duties?.map((value: any, index: number): any => (
                                        `${value?.officer_duty}`
                                    ))}
                                </p>
                            </div>
                            <div className="mb-3">
                                <p className="font-semibold color-black-v2 font-13 mb-2">
                                    Service Location
                                </p>
                                <p className="font-medium font-13 text-color-1">
                                    {`${serviceRequestDetails.address_line_1}, ${serviceRequestDetails.address_line_2}, ${serviceRequestDetails.city}, ${serviceRequestDetails.state}, ${serviceRequestDetails.zip_code}`}
                                </p>
                            </div>
                            <div className="mb-3">
                                <p className="font-semibold color-black-v2 font-13 mb-2">
                                    Officer Details
                                </p>
                                <p className="font-medium font-13 text-color-1">
                                    {serviceRequestDetails.service_details}
                                </p>
                            </div>
                            <div className="mb-3">
                                <p className="font-semibold color-black-v2 font-13 mb-2">
                                    Discount’s Applied
                                </p>
                                <p className="font-medium font-13 text-color-1">{serviceRequestDynamicData?.discounts?.filter((obj: any) => (obj.discount_uuid === serviceRequestDetails.discount_uuid))[0]?.discount_amount} Discount in Invoiced amount</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer align-items-center justify-content-end border-0 gap-4 px-4 pb-4">
                        <button className="btn px-0 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                            onClick={() => { setIsConfirmRequest(false) }}>
                            Cancel
                        </button>
                        <button
                            className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                            onClick={() => { saveServiceRequest() }}
                        >
                            Submit Request
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ConfirmRequest;