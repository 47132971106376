import { client } from '../../Client/Client';
import { serviceRequestServices } from '../../Configs/BackendRoutes';
import { backendUrl as URL } from '../../Configs/Constant';

export const getServiceRequestDynamicData = async () => {
    const config = {
        method: 'GET',
        url: URL + serviceRequestServices.serviceRequestDynamicData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: null
    };
    const response = await client(config);
    return response;
};

export const createServiceRequest = async (data: any) => {
    const config = {
        method: 'POST',
        url: URL + serviceRequestServices.createServiceRequest,
        headers: {
            'Content-Type': 'application/json'
        },
        data
    };
    const response = await client(config);
    return response;
};

export const updateServiceRequest = async (data: any) => {
    const config = {
        method: 'PUT',
        url: URL + serviceRequestServices.updateServiceRequest,
        headers: {
            'Content-Type': 'application/json'
        },
        data
    };
    const response = await client(config);
    return response;
};

export const manageServiceRequest = async (data: any) => {
    const config = {
        method: 'POST',
        url: URL + serviceRequestServices.manageServiceRequest,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    };
    const response = await client(config);
    return response;
};

export const getServiceBid = async () => {
    const config = {
        method: 'GET',
        url: URL + serviceRequestServices.getServiceBid,
        headers: {
            'Content-Type': 'application/json',
        },
        data: null
    };
    const response = await client(config);
    return response;
};

export const modifyServiceBid = async (data: any) => {
    const config = {
        method: 'POST',
        url: URL + serviceRequestServices.ModifyServiceBid,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    };
    const response = await client(config);
    return response;
};

export const getServiceRequest = async (id: string) => {
    const config = {
        method: 'POST',
        url: URL + serviceRequestServices.getServiceRequest,
        headers: {
            'Content-Type': 'application/json',
        },
        data: {service_request_id : id}
    };
    const response = await client(config);
    return response;
};

export const saveFeedback = async (data: any) => {
    const config = {
        method: 'POST',
        url: URL + serviceRequestServices.submitFeedback,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    };
    const response = await client(config);
    return response;
};

export const fetchSingleServiceRequest = async (id: string) => {
    const config = {
        method: 'POST',
        url: URL + serviceRequestServices.fetchSingleServiceRequest ,
        headers: {
            'Content-Type': 'application/json',
        },
        data: {retrieve_single_id : id}
    };
    const response = await client(config);
    return response;
};