/* eslint-disable jsx-a11y/anchor-is-valid */
import { ClientSignUpProps } from "../../Interface/SignUp"

export const SiteClientForm: React.FC<ClientSignUpProps> =
    ({ handleInputChange, error, siteFormData, MasterClientData, VerifyEmail, bindIndustryDropdown, bindRegionDropdown, bindStateDropdown, bindCityDropdown, isOtherIndustrySIte, handleValidation, DisableCity }) => {

        return (
            <>
                <div className="col-lg-6 mb-4">
                    <label
                        htmlFor="FullName"
                        className="font-semibold font-15 primary-text-color mb-2"
                    >
                        First Name
                    </label>
                    <input
                        type="text"
                        id="FullName"
                        // className="form-control custom-form-fields color-black-v1 font-14 font-medium"
                        className={`form-control custom-form-fields color-black-v1 font-14 font-medium ${error.first_name !== "" ? 'critical-field' : ''}`}
                        placeholder="Enter First Name"
                        name="first_name"
                        value={siteFormData.first_name}
                        onChange={(e: any) => {
                            handleInputChange(e.target.name, e.target.value)
                            handleValidation(e.target.name, e.target.value)
                        }}
                    />
                    {error.first_name !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {error.first_name}
                        </span>
                    }
                </div>
                <div className="col-lg-6 mb-4">
                    <label
                        htmlFor="LastName"
                        className="font-semibold font-15 primary-text-color mb-2"
                    >
                        Last Name
                    </label>
                    <input
                        type="text"
                        id="LastName"
                        // className="form-control custom-form-fields color-black-v1 font-14 font-medium"
                        className={`form-control custom-form-fields color-black-v1 font-14 font-medium ${error.last_name !== "" ? 'critical-field' : ''}`}
                        placeholder="Enter Last Name"
                        name="last_name"
                        value={siteFormData.last_name}
                        onChange={(e: any) => {
                            handleInputChange(e.target.name, e.target.value)
                            handleValidation(e.target.name, e.target.value)
                        }}
                    />
                    {error.last_name !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {error.last_name}
                        </span>
                    }
                </div>
                <div className="col-lg-6 mb-4">
                    <label
                        htmlFor="business"
                        className="font-semibold font-15 primary-text-color mb-2"
                    >
                        Doing Business as
                    </label>
                    <input
                        type="text"
                        id="business"
                        // className="form-control custom-form-fields critical-field color-black-v1 font-14 font-medium"
                        className={`form-control custom-form-fields color-black-v1 font-14 font-medium ${error.doing_business_as !== "" ? 'critical-field' : ''}`}
                        value={siteFormData.doing_business_as}
                        name="doing_business_as"
                        placeholder="Enter your DBA"
                        onChange={(e: any) => {
                            handleInputChange(e.target.name, e.target.value)
                            handleValidation(e.target.name, e.target.value)
                        }}

                    />
                    {error.doing_business_as !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {error.doing_business_as}
                        </span>
                    }
                </div>
                <div className="col-lg-6 mb-4">
                    <label
                        htmlFor="industry3"
                        // className="font-semibold font-15 primary-text-color mb-2"
                        className={`font-semibold font-15 primary-text-color mb-2 ${error.industry !== "" ? 'critical-field' : ''}`}
                    >
                        Specify your Industry
                    </label>
                    <select
                        name="industry"
                        id="Industry"
                        className={`w-100 d-block form-select custom-form-fields color-black-v1 font-14 font-medium cust-form-arrow ${error.industry !== "" ? 'critical-field' : ''}`}
                        onChange={(e: any) => {
                            handleInputChange(e.target.name, e.target.value)
                            handleValidation(e.target.name, e.target.value)
                        }}
                    >
                        <option value="option">Select</option>
                        {bindIndustryDropdown()}
                    </select>

                    {error.industry !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {error.industry}
                        </span>
                    }
                </div>
                {isOtherIndustrySIte &&
                    <div className="col-lg-6 mb-4">
                        <label
                            htmlFor="IndustryName"
                            className="font-semibold font-15 primary-text-color mb-2"
                        >
                            Industry Name
                        </label>
                        <input
                            type="text"
                            id="IndustryName"
                            // className="form-control custom-form-fields  color-black-v1 font-14 font-medium"
                            className={`form-control custom-form-fields color-black-v1 font-14 font-medium  ${error.industry_name !== "" ? 'critical-field' : ''}`}
                            name="industry_name"
                            value={siteFormData.industry_name}
                            placeholder="Enter your Industry"
                            onChange={(e: any) => {
                                handleInputChange(e.target.name, e.target.value)
                                handleValidation(e.target.name, e.target.value)
                            }}
                        />
                        {error.industry_name !== "" &&
                            <span className="font-14 font-medium alert-text">
                                {error.industry_name}
                            </span>
                        }
                    </div>
                }
                <div className="col-lg-6 mb-4">
                    <label
                        htmlFor="email"
                        className="font-semibold font-15 primary-text-color mb-2"
                    >
                        Business Email Address
                    </label>
                    <input
                        type="text"
                        id="email"
                        className={`form-control custom-form-fields color-black-v1 font-14 font-medium ${error.email_id !== ""  || error.unique_email !== "" ? 'critical-field' : ''}`}

                        placeholder="Enter Business Email Address"
                        name="email_id"
                        value={siteFormData.email_id}
                        onChange={(e: any) => {
                            handleInputChange(e.target.name, e.target.value)
                            handleValidation(e.target.name, e.target.value)
                        }}
                    />

                    {error.email_id !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {error.email_id}
                        </span>
                    }
                    {error.unique_email !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {error.unique_email}
                        </span>
                    }
                </div>
                <div className="col-lg-6 mb-4">
                    <label
                        htmlFor="phone"
                        className="font-semibold font-15 primary-text-color mb-2"
                    >
                        Phone #
                    </label>
                    <input
                        type="text"
                        id="phone"
                        className={`form-control custom-form-fields color-black-v1 font-14 font-medium ${error.phone_no !== "" ? 'critical-field' : ''}`}
                        name="phone_no"
                        value={siteFormData.phone_no}
                        placeholder="Enter Phone #"
                        pattern="\+1 \(\d{3}\) \d{3}-\d{4}"
                        onChange={(e: any) => {
                            handleInputChange(e.target.name, e.target.value)
                            handleValidation(e.target.name, e.target.value)
                        }}
                    />
                    {error.phone_no !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {error.phone_no}
                        </span>
                    }
                </div>

                <div className="col-lg-6 mb-4 position-relative">
                    <label
                        htmlFor="admin"
                        className="font-semibold font-15 primary-text-color mb-2"
                    >
                        Master Client Email Address
                    </label>
                    <div className="d-flex align-items-center position-relative verification-container">
                        <input
                            type="text"
                            id="admin"
                            className={`form-control custom-form-fields color-black-v1 font-14 font-medium ${error.master_client_email !== "" ? 'critical-field' : ''}`}
                            name="master_client_email"
                            placeholder="Enter Master Client Email Address"
                            value={MasterClientData.master_client_email}
                            onChange={(e: any) => {
                                handleInputChange(e.target.name, e.target.value)
                                handleValidation(e.target.name, e.target.value)
                            }}
                        >
                        </input>
                        {MasterClientData.master_client_email !== "" && error.master_client_email === "" &&
                            <a
                                className="link font-semibold cust-text-underline font-15 font-medium verification-text"
                                // className="link font-semibold cust-text-underline position-absolute font-15 font-medium verification-text"
                                style={{ float: 'right' }}
                                // disabled={MasterClientData.master_client_email == ""}
                                onClick={VerifyEmail}>Verify</a>
                        }

                    </div>
                    {error.master_client_email !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {error.master_client_email}
                        </span>
                    }
                </div>
                <div className="col-lg-6 mb-4 position-relative">
                    <label
                        htmlFor="admin"
                        className="font-semibold font-15 primary-text-color mb-2"
                    >
                        Master Client
                    </label>
                    <input
                        type="text"
                        id="admin"
                        className="form-control custom-form-fields color-black-v1 font-14 font-medium"
                        placeholder="Master Client DBA Name"
                        name="Master Client"
                        value={MasterClientData.master_name}
                        disabled={true}
                    />
                </div>
                <div className="d-flex align-items-center gap-2 my-4">
                    <h3 className="font-22 font-semibold color-black mb-0">
                        Site Addresses
                    </h3>
                </div>
                <div className="col-lg-6 mb-4">
                    <label
                        htmlFor="Address1"
                        className="font-semibold font-15 primary-text-color mb-2"
                    >
                        Address Line 1
                    </label>
                    <input
                        type="text"
                        id="Address1"
                        className={`form-control custom-form-fields color-black-v1 font-14 font-medium ${error.address_line_1 !== "" ? 'critical-field' : ''}`}
                        value={siteFormData.address_line_1}
                        name="address_line_1"

                        placeholder="Enter Address Line 1"
                        onChange={(e: any) => {
                            handleInputChange(e.target.name, e.target.value)
                            handleValidation(e.target.name, e.target.value)
                        }}
                    />
                    {error.address_line_1 !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {error.address_line_1}
                        </span>
                    }
                </div>
                <div className="col-lg-6 mb-4">
                    <label
                        htmlFor="Address2"
                        className="font-semibold font-15 primary-text-color mb-2"
                    >
                        Address Line 2
                    </label>
                    <input
                        type="text"
                        id="Address2"
                        className={`form-control custom-form-fields color-black-v1 font-14 font-medium ${error.address_line_2 !== "" ? 'critical-field' : ''}`}
                        value={siteFormData.address_line_2}
                        name="address_line_2"
                        placeholder="Enter Address Line 2"
                        onChange={(e: any) => {
                            handleInputChange(e.target.name, e.target.value)
                            handleValidation(e.target.name, e.target.value)
                        }}
                    />
                    {error.address_line_2 !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {error.address_line_2}
                        </span>
                    }
                </div>
                <div className="col-lg-6 mb-4">
                    <label
                        htmlFor="zipcode"
                        className="font-semibold font-15 primary-text-color mb-2"
                    >
                        Zip Code
                    </label>
                    <input
                        type="text"
                        id="zipcode"
                        className={`form-control custom-form-fields color-black-v1 font-14 font-medium ${error.zip_code !== "" ? 'critical-field' : ''}`}
                        value={siteFormData.zip_code}
                        name="zip_code"
                        placeholder="Enter Zip Code"
                        onChange={(e: any) => {
                            handleInputChange(e.target.name, e.target.value)
                            handleValidation(e.target.name, e.target.value)
                        }}
                    />
                    {error.zip_code !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {error.zip_code}
                        </span>
                    }
                </div>
                <div className="col-lg-6 mb-4">
                    <label
                        htmlFor="state"
                        className="font-semibold font-15 primary-text-color mb-2"
                    >
                        State
                    </label>
                    <select
                        name="state"
                        value={siteFormData.state}
                        id="state"
                        className={`w-100 d-block form-select custom-form-fields color-black-v1 font-14 font-medium cust-form-arrow ${error.state !== "" ? 'critical-field' : ''}`}
                        onChange={(e: any) => {
                            handleInputChange(e.target.name, e.target.value)
                            handleValidation(e.target.name, e.target.value)
                        }}
                    >
                        <option value="option" className="font-semibold font-15 primary-text-color mb-2">Select</option>
                        {bindStateDropdown()}
                    </select>
                    {error.state !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {error.state}
                        </span>
                    }
                </div>
                <div className="col-lg-6 mb-4">
                    <label
                        htmlFor="city"
                        className="font-semibold font-15 primary-text-color mb-2"
                    >
                        City
                    </label>
                    <select
                        name="city"
                        id="city"
                        className={`w-100 d-block form-select custom-form-fields color-black-v1 font-14 font-medium cust-form-arrow ${error.city !== "" ? 'critical-field' : ''}`}
                        disabled={DisableCity}
                        value={siteFormData.city}
                        onChange={(e: any) => {
                            handleInputChange(e.target.name, e.target.value)
                            handleValidation(e.target.name, e.target.value)
                        }}
                    >
                        <option value="option" className="font-semibold font-15 primary-text-color mb-2">Select</option>
                        {bindCityDropdown()}
                    </select>
                    {error.city !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {error.city}
                        </span>
                    }
                </div>
                <div className="col-lg-6 mb-4">
                    <label
                        htmlFor="state"
                        className="font-semibold font-15 primary-text-color mb-2"
                    >
                        Region
                    </label>
                    <select
                        name="region_id"
                        value={siteFormData.region}
                        id="state"
                        className={`w-100 d-block form-select custom-form-fields color-black-v1 font-14 font-medium cust-form-arrow ${error.region !== "" ? 'critical-field' : ''}`}
                        disabled={true}

                    >
                        <option value="master_name" className="font-semibold font-15 primary-text-color mb-2">{MasterClientData.master_name}</option>
                    </select>
                    {error.region !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {error.region}
                        </span>
                    }
                </div>

            </>
        )
    }