import { client } from "../../Client/Client";
import { backendUrl as URL } from "../../Configs/Constant";
// import { backendUrl as URL } from "../../Configs/Constant";
export const SignInService = async (payload: any) => {
    const config = {
        method: 'POST',
        url: URL + "/v1/api/login/sign-in",
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };
    const response = await client(config);

    return response;
};