import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getServiceRequest, modifyServiceBid } from "../../Service/ServiceRequest/ServiceRequestService";
import moment from "moment";
import { getDifferenceInMinutes } from "../../Configs/Constant";
import Countdown from "./ServiceCountdown";
import Loader from "../Loader/Loader";
import { toastDetailsType } from "../../Interface/ServiceRequest";

/* eslint-disable jsx-a11y/anchor-is-valid */
function ServiceRequestView() {

    const navigate = useNavigate();
    const { state } = useLocation();
    let { id, status } = state
    console.log(state, "state");

    useEffect(() => {
        pageload()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [serviceRequestDetails, setServiceRequestDetails] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [toastDetails, setToastDetails] = useState<toastDetailsType>({
        isToast: state?.isToast,
        toastMessage: state?.toastMessage,
        toastType: state?.toastType
    });

    const pageload = async () => {
        try {
            await fetchServiceRequest();
        } catch (error) {
            console.log(error, "pageload")
        }
    }



    const fetchServiceRequest = async () => {
        try {
            // id = "4b92de55-7b0d-43e5-9f1e-706344362e7f"
            setIsLoading(true);
            let response = await getServiceRequest(id)
            if (response.code === 200) {
                setServiceRequestDetails(response.data)
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error, "fetchServiceRequest")
        }
    }

    const serviceBidAction = async (id?: string, action?: string, bidId?: string) => {
        try {

            let modifyServiceBidObj = {
                service_request_uuid: id,
                action: action,
                bid_uuid: bidId
            }

            setIsLoading(true)
            let response = await modifyServiceBid(modifyServiceBidObj);

            if (response.code === 200) {
                setToastDetails({
                    isToast: action !== "close" ? true : false,
                    toastMessage: action === "Approve" ? "Service Requested Accepted Successfully" : action === "Rejected" ? "Service Requested Rejected Successfully" : "",
                    toastType: action !== "close" ? "Success" : "Failure"
                })
                await fetchServiceRequest()
            }

            setIsLoading(false);
        } catch (error) {
            console.log(error, "serviceBidAction")
        }
    }

    return (
        <>
            {isLoading ? <Loader /> :
                <div className="container-fluid p-sm-4 p-2 pt-md-5 pt-4 mt-md-4">
                    <div className="d-flex justify-content-between align-items-start w-100 gap-3 px-4 ">
                        <div className="d-flex align-items-start flex-wrap gap-3 justify-content-between w-100">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center gap-3 flex-wrap">
                                    <div className="d-flex align-items-center gap-lg-4 gap-2">
                                        <button className="icon-round-btn cust-round-width me-1"
                                            onClick={() => { navigate(-1) }} >
                                            <img
                                                className="icon-sty"
                                                src="../images/back-arrow.svg"
                                                alt="back-arrow"
                                            />
                                        </button>
                                        <h2 className="color-black font-22 font-semibold mb-0 lh-inherit">
                                            {serviceRequestDetails?.service_details?.service_request}
                                        </h2>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <span className="custom-status await-status-legend">
                                            <span className="await-status-sub-legend" />
                                        </span>
                                        <span className="font-15 font-semibold delay-color">
                                            {status === "awaiting-approval" ? "Awaiting Approval" : status === "in-review" ? "Waiting for Response" :
                                                status === "in-progress" ? "In Progress" : status === "closed" ? "Closed" : "None"}
                                        </span>
                                    </div>
                                </div>
                                <span className="font-14 font-medium color-grey-v4 d-flex align-items-center flex-wrap mt-2 cust-ms">
                                    {moment(serviceRequestDetails?.service_details?.service_start_date)?.format('ddd, DD MMM YYYY')} - {moment(serviceRequestDetails?.service_details?.service_end_date)?.format('ddd, DD MMM YYYY')}  {moment(serviceRequestDetails?.service_details?.service_start_time, 'HH:mm')?.format('h:mm A')} - {moment(serviceRequestDetails?.service_details?.service_end_time, 'HH:mm')?.format('h:mm A')}
                                    <span className="cust-dot" />
                                    {serviceRequestDetails?.service_details?.service_state}
                                    <span className="cust-dot" />
                                    {serviceRequestDetails?.service_details?.incident_name}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="px-md-5 px-sm-3 px-0 mx-3 mx-sm-4">
                        <div className="d-flex align-items-center gap-4 my-md-5 my-4  flex-wrap">
                            <button className="d-flex flex-column gap-2 align-items-center border-0 bg-transparent btn"
                                disabled={state.status === "awaiting-approval" || state.status === "closed" ? true : false}
                                onClick={() => {
                                    navigate('/fill-service-request', {
                                        state: {
                                            id: state?.id,
                                            status: state?.status,
                                        }
                                    })
                                }}
                            >
                                <span className="btn action-outline-btn">
                                    <img
                                        className="icon-sty"
                                        src="../images/edit-info.svg"
                                        alt="edit-info"
                                    />
                                </span>
                                <span className="font-11 font-medium color-black">Edit Icon</span>
                            </button>
                            <button className="d-flex flex-column gap-2 align-items-center border-0 bg-transparent btn"
                                onClick={() => {
                                    navigate('/fill-service-request', {
                                        state: {
                                            id: state?.id,
                                            status: state?.status,
                                            isDuplicate: true
                                        }
                                    })
                                }}>
                                <span className="btn action-outline-btn">
                                    <img
                                        className="icon-sty"
                                        src="../images/duplicate-icon.svg"
                                        alt="duplicate-icon"
                                    />
                                </span>
                                <span className="font-11 font-medium color-black">Duplicate</span>
                            </button>
                            <button
                                className="d-flex flex-column gap-2 align-items-center border-0 bg-transparent btn"
                                disabled={state?.status === "closed" ? false : true}
                            >
                                <span className="btn action-outline-btn">
                                    <img
                                        className="icon-sty"
                                        src="../images/feedback-icon.svg"
                                        alt="feedback-icon"
                                    />
                                </span>
                                <span className="font-11 font-medium color-black">Feedback</span>
                            </button>
                        </div>
                        {/* tabs */}
                        <div className="tab-scroll my-4 px-3">
                            <ul
                                className="nav nav-pills cust-nav-detail-pills flex-nowrap w-100"
                                id="pills-tab"
                                role="tablist"
                            >
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link cust-nav-link active text-nowrap"
                                        id="pills-home-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-home"
                                        aria-selected="true"
                                    >
                                        Job Info
                                    </button>
                                </li>
                                {(status === "in-review" || status === "awaiting-approval") && <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link cust-nav-link text-nowrap"
                                        id="pills-profile-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-profile"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-profile"
                                        aria-selected="false"
                                    >
                                        Bid Info
                                    </button>
                                </li>}
                            </ul>
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                            {/* 1st tab */}
                            <div
                                className="tab-pane fade show active"
                                id="pills-home"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                            >
                                {status === "in-review" ?
                                    getDifferenceInMinutes(serviceRequestDetails?.service_details?.response_time) < 180 ?
                                        <div className="d-flex align-items-center cust-padding gap-2">
                                            <img src="../images/await-time.svg" alt="await-time" />
                                            <span className="font-medium font-14 sucess-color">
                                                {`We're doing our best to provide you with a best Amount. Please wait `}
                                                <Countdown startValue={180 - getDifferenceInMinutes(serviceRequestDetails?.service_details?.response_time)} /> {`minutes.`}
                                            </span>
                                        </div> :
                                        <div className="d-flex align-items-start cust-padding gap-2 delay-msg">
                                            <img className="mt-1" src="../images/delay-time.svg" alt="delay-time" />
                                            <span className="font-medium font-14 ">It’s taking longer than expected.</span>
                                        </div> :
                                    <></>
                                }
                                <div className="row mt-2">
                                    <div className="col-md-3 p-3 mb-md-4 mb-0">
                                        <div className="d-flex flex-column justify-content-between  gap-2">
                                            <h4 className="font-14 font-medium color-grey-v4 mb-0">
                                                Location Type
                                            </h4>
                                            <p className="mb-0 color-black font-14 font-medium cust-lh">
                                                {serviceRequestDetails?.service_details?.location_type}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 p-3 mb-md-4 mb-0">
                                        <div className="d-flex flex-column justify-content-between  gap-2">
                                            <h4 className="font-14 font-medium color-grey-v4 mb-0">
                                                Officer Request Type
                                            </h4>
                                            <p className="mb-0 color-black font-14 font-medium cust-lh">
                                                {serviceRequestDetails?.request_types?.map((value: any) => (
                                                    value?.request_type_name + '(' + value?.security_people_count + ') '
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 p-3 mb-md-4 mb-0">
                                        <div className="d-flex flex-column justify-content-between  gap-2">
                                            <h4 className="font-14 font-medium color-grey-v4 mb-0">
                                                Officer Duties Type
                                            </h4>
                                            <p className="mb-0 color-black font-14 font-medium cust-lh">
                                                {serviceRequestDetails?.requested_duties?.map((value: any) => (
                                                    value?.duty_name
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 p-3 mb-md-4 mb-0">
                                        <div className="d-flex flex-column justify-content-between  gap-2">
                                            <h4 className="font-14 font-medium color-grey-v4 mb-0">
                                                Service Location
                                            </h4>
                                            <p className="mb-0 color-black font-14 font-medium cust-lh">
                                                {serviceRequestDetails?.service_details?.address_line_one + ', ' + serviceRequestDetails?.service_details?.address_line_two
                                                    + ', ' + serviceRequestDetails?.service_details?.service_city + ', ' + serviceRequestDetails?.service_details?.service_state
                                                    + ', ' + serviceRequestDetails?.service_details?.service_zip_code}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 p-3 mb-0">
                                        <div className="d-flex flex-column justify-content-between  gap-2">
                                            <h4 className="font-14 font-medium color-grey-v4 mb-0">
                                                About Service Request
                                            </h4>
                                            <p className="mb-0 color-black font-14 font-medium cust-lh">
                                                {serviceRequestDetails?.service_details?.about_service_request}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 p-3 mb-4">
                                        <div className="d-flex flex-column justify-content-between  gap-2">
                                            <h4 className="font-14 font-medium color-grey-v4 mb-0">
                                                Availed Discounts
                                            </h4>
                                            <p className="mb-0 color-black font-14 font-medium cust-lh">
                                                {serviceRequestDetails?.service_details?.discount_percentage !== "No" ?
                                                    `Complete payment in ${serviceRequestDetails?.service_details?.discount_days} Days and get the ${serviceRequestDetails?.service_details?.discount_percentage}% Discount offer` :
                                                    "No offer applied"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 2nd tab */}
                            <div
                                className="tab-pane fade"
                                id="pills-profile"
                                role="tabpanel"
                                aria-labelledby="pills-profile-tab"
                            >
                                {serviceRequestDetails?.bid_history?.map((value: any, index: number) => (
                                    value?.bid_status_name === "RM Approved" || value?.bid_status_name === "Approve" ?
                                        <div className="bid-info-container">
                                            <div className="d-flex align-items-center justify-content-between flex-wrap gap-md-3 gap-4 gap-md-0">
                                                <div className="d-block">
                                                    <h4 className="font-bold font-20 color-black">
                                                        Awaiting Your Confirmation
                                                    </h4>
                                                    <p className="font-14 font-medium color-grey">
                                                        A quote amount has been provided for this request:{" "}
                                                        <span className="font-bold font-20 color-black">$35</span>/per
                                                        Hour
                                                    </p>
                                                    <div className="d-flex align-items-center flex-wrap gap-3 gap-sm-5">
                                                        <p className="font-14 font-medium color-grey mb-0">
                                                            # of Bids Received:{" "}
                                                            <span className="font-semibold color-grey-v5">12</span>
                                                        </p>
                                                        <p className="font-14 font-medium color-grey mb-0">
                                                            Bid Ranges from:{" "}
                                                            <span className="font-semibold color-grey-v5">$29 - $48</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column gap-2">
                                                    <div className="d-flex align-items-center gap-3">
                                                        <button className="btn px-4 px-sm-5 font-semibold font-14 shadow-none accept-btn"
                                                            onClick={() => {
                                                                serviceBidAction(value?.service_request_uuid, "Approve", value?.bid_uuid)
                                                            }}
                                                        >
                                                            Accept
                                                        </button>
                                                        <button className="btn px-4 px-sm-5 font-semibold font-14 shadow-none reject-btn"
                                                            onClick={() => {
                                                                serviceBidAction(value?.service_request_uuid, "Rejected", value?.bid_uuid)
                                                            }}
                                                        >
                                                            Reject
                                                        </button>
                                                    </div>
                                                    <span className="font-12 font-medium color-grey-v3 text-end">
                                                        <img
                                                            className="me-1 tab-icon"
                                                            src="../images/tooltip-icon.svg"
                                                            alt="tooltip-icon"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="top"
                                                            title="Tooltip on top"
                                                        />
                                                        quoted amount here excludes sales taxes.
                                                    </span>
                                                </div>
                                            </div>
                                        </div> : value?.bid_status_name === "Client Approved" || "Client Rejected" ?
                                            <div className="bid-info-container mt-4">
                                                <div className="d-flex align-items-center justify-content-between flex-wrap gap-md-3 gap-4 gap-md-0">
                                                    <div className="d-block">
                                                        <h4 className="font-bold font-20 color-black">Bid Accepted</h4>
                                                        <p className="font-14 font-medium color-grey">
                                                            A quote amount has been provided for this request:{" "}
                                                            <span className="font-bold font-20 color-black">$35</span>/per Hour
                                                        </p>
                                                        <div className="d-flex align-items-center flex-wrap gap-3 gap-sm-5">
                                                            <p className="font-14 font-medium color-grey mb-0">
                                                                # of Bids Received:{" "}
                                                                <span className="font-semibold color-grey-v5">11</span>
                                                            </p>
                                                            <p className="font-14 font-medium color-grey mb-0">
                                                                Bid Ranges from:{" "}
                                                                <span className="font-semibold color-grey-v5">$12 - $58</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-start align-items-lg-end flex-column gap-2">
                                                        <div className="d-flex align-items-center gap-2 cust-indicator font-14 font-semibold bid-accepted">
                                                            <img
                                                                className="tab-icon"
                                                                src="../images/accepted-tick.svg"
                                                                alt="accepted"
                                                            />
                                                            {value?.bid_status_name === "Client Approved" ? "Accepted" : "Rejected"}
                                                        </div>
                                                        <span className="font-12 font-medium color-grey-v3">
                                                            <img
                                                                className="me-1 tab-icon"
                                                                src="../images/tooltip-icon.svg"
                                                                alt="tooltip-icon"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="top"
                                                                title="Tooltip on top"
                                                            />
                                                            quoted amount here excludes sales taxes.
                                                        </span>
                                                    </div>
                                                </div>
                                            </div> : <></>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default ServiceRequestView;