import moment from "moment";
import { useState } from "react";

function ServiceBid(props: any) {

    const { serviceBid, serviceBidAction } = props.props
    const [carouselId, setCarouselId] = useState<number>(0);

    return (
        <div
            className="modal fade show"
            id="exampleModal2"
            tabIndex={-1}
            style={{ display: "block" }}
        >
            <div className="modal-dialog modal-dialog-centered   ">
                <div className="modal-content border-radius-10">
                    <div className="modal-header border-0 px-4 pt-4 align-items-start">
                        <div className="d-block">
                            <h5
                                className="modal-title font-bold font-18 color-black-v2 mb-2"
                                id="exampleModalLabel"
                            >
                                Confirm Request
                            </h5>
                            <p className="font-14 font-medium color-grey mb-0">
                                Confirm Before Submitting the request
                            </p>
                        </div>
                        <button
                            type="button"
                            className="btn-close custom-btn-close mt-1"
                            onClick={() => {
                                serviceBidAction("", "close", "")
                            }}
                        />
                    </div>
                    <div className="modal-body px-4 pt-2 pb-4">
                        <div
                            id="carouselExampleIndicators"
                            className="carousel slide"
                            data-bs-ride="false"
                        >
                            <div className="carousel-indicators custom-indicator">
                                {serviceBid?.map((value: any, index: number) => (
                                    <button
                                        type="button"
                                        data-bs-target="#carouselExampleIndicators"
                                        data-bs-slide-to={index}
                                        className="active carousel-btn"
                                        aria-current="true"
                                        aria-label="Slide 1"
                                        onClick={() => setCarouselId(index)}
                                    />
                                ))}
                            </div>
                            <div className="carousel-inner">
                                {serviceBid?.map((value: any, index: number) => (
                                    <div className={`carousel-item ${carouselId === index ? 'active' : ""}`} >
                                        <div className="row align-items-center justify-content-center px-1" key={index}>
                                            <div className="col-lg-8 p-2 pb-3" key={index}>
                                                <div className="d-flex align-items-center gap-2 font-semibold font-14 text-color-1" key={index}>
                                                    <img
                                                        className="popup-icon-sty"
                                                        src="../images/event.svg"
                                                        alt="event"
                                                    />
                                                    {value.service_request}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 p-2 pb-3" key={index}>
                                                <div className="d-flex align-items-center gap-2 font-semibold font-14 text-color-1" key={index}>
                                                    <img
                                                        className="popup-icon-sty"
                                                        src="../images/people.png"
                                                        alt="people"
                                                    />
                                                    {value.security_people_count}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 p-2 pb-3" key={index}>
                                                <div className="d-flex align-items-center gap-2 font-semibold font-14 text-color-1" key={index}>
                                                    <img
                                                        className="popup-icon-sty"
                                                        src="../images/location.svg"
                                                        alt="location"
                                                    />
                                                    {value.service_city}, {value.service_state}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 p-2 pb-3" key={index}>
                                                <div className="d-flex align-items-center gap-2 font-semibold font-14 text-color-1" key={index}>
                                                    <img
                                                        className="popup-icon-sty"
                                                        src="../images/calendar.svg"
                                                        alt="calendar"
                                                    />
                                                    From {moment(value?.service_start_date)?.format('ddd, DD MMM YYYY')} - To {moment(value?.service_end_date)?.format('ddd, DD MMM YYYY')}
                                                </div>
                                            </div>
                                            {/* price */}
                                            <div className="d-flex align-items-center flex-column justify-content-center my-3 gap-2" key={index}>
                                                <span className="font-12 font-semibold  color-grey text-center">
                                                    QUOTED PRICE
                                                </span>
                                                <span className="font-12 font-semibold color-grey">
                                                    <span className="font-30 font-semibold text-color-3 me-1">
                                                        $ {value.bid_price}
                                                    </span>
                                                    /per Hour
                                                </span>
                                                <span className="font-12 font-medium color-grey-v3">
                                                    quoted amount here excludes sales taxes.
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-center gap-2 border-0 gap-2 px-4 pb-4 pt-2">
                                                <button
                                                    className="btn px-5 font-semibold font-14 shadow-none accept-btn"
                                                    onClick={() => {
                                                        serviceBidAction(value?.service_request_uuid, "Approve", value?.bid_uuid)
                                                    }}
                                                >
                                                    Accept
                                                </button>
                                                <button
                                                    className="btn px-5 font-semibold font-14 shadow-none reject-btn"
                                                    onClick={() => {
                                                        serviceBidAction(value?.service_request_uuid, "Rejected", value?.bid_uuid)
                                                    }}
                                                >
                                                    Reject
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceBid;
