import { ClientSignUpPopupProps } from "../../Interface/SignUp"

export const SiteClientPopup: React.FC<ClientSignUpPopupProps> =
    ({ handleInputChange, error, siteFormData, bindIndustryDropdown, bindRegionDropdown, bindStateDropdown, bindCityDropdown, isOtherIndustrySIte, handleValidation, presentIn, SetPresentIn, SavePopupForm, PopupCancel, sumbitButtonEnabled, DisableCity }) => {

        return (
            <><div
                className="modal  fade show"
                id="exampleModal13"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-modal="true"
                data-backdrop="static"
                data-keyboard="false"
                style={{ display: `${presentIn === "Site Client Popup" ? "block" : null}`, backgroundColor: "#000" }}
            >
                <div className="modal-dialog modal-dialog-centered  modal-lg ">
                    <div className="modal-content border-radius-10">
                        <div className="modal-header border-0 px-4 pt-4 pb-2 align-items-start">
                            <h5
                                className="modal-title font-semibold font-18 color-black-v2 mb-2"
                                id="exampleModalLabel"
                            >
                                Add New Site Info
                            </h5>
                            <button
                                type="button"
                                className="btn-close custom-btn-close mt-1"
                                aria-label="Close"
                                onClick={() => {
                                    PopupCancel()
                                }}
                            />
                        </div>
                        <div className="modal-body px-4 py-2 popup-scroll">
                            <p className="font-18 font-semibold color-black">Admin Info</p>
                            <div className="row">
                                <div className="col-lg-6 mb-4">
                                    <label
                                        htmlFor="FullName"
                                        className="font-semibold font-15 primary-text-color mb-2"
                                    >
                                        Full Name
                                    </label>
                                    <input
                                        type="text"
                                        id="FullName"
                                        className={`form-control custom-form-fields color-black-v1 font-14 font-medium  ${error.first_name !== "" && presentIn === "Site Client Popup" ? 'critical-field' : ''}`}
                                        placeholder="Enter First Name"
                                        value={siteFormData.first_name}
                                        name="first_name"
                                        onChange={(e: any) => {
                                            handleInputChange(e.target.name, e.target.value)
                                            handleValidation(e.target.name, e.target.value)
                                        }}
                                    />
                                    {error.first_name !== "" && presentIn === "Site Client Popup" &&
                                        <span className="font-14 font-medium alert-text">
                                            {error.first_name}
                                        </span>
                                    }
                                </div>
                                <div className="col-lg-6 mb-4">
                                    <label
                                        htmlFor="FullName"
                                        className="font-semibold font-15 primary-text-color mb-2"
                                    >
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        id="FullName"
                                        className={`form-control custom-form-fields color-black-v1 font-14 font-medium  ${error.first_name !== "" && presentIn === "Site Client Popup" ? 'critical-field' : ''}`}
                                        placeholder="Enter Last Name"
                                        value={siteFormData.last_name}
                                        name="last_name"
                                        onChange={(e: any) => {
                                            handleInputChange(e.target.name, e.target.value)
                                            handleValidation(e.target.name, e.target.value)
                                        }}
                                    />
                                    {error.last_name !== "" && presentIn === "Site Client Popup" &&
                                        <span className="font-14 font-medium alert-text">
                                            {error.last_name}
                                        </span>
                                    }
                                </div>
                                <div className="col-lg-6 mb-4">
                                    <label
                                        htmlFor="business"
                                        className="font-semibold font-15 primary-text-color mb-2"
                                    >
                                        Doing Business as
                                    </label>
                                    <input
                                        type="text"
                                        id="business"
                                        // className="form-control custom-form-fields  color-black-v1 font-14 font-medium"
                                        className={`form-control custom-form-fields color-black-v1 font-14 font-medium  ${error.doing_business_as !== "" && presentIn === "Site Client Popup" ? 'critical-field' : ''}`}
                                        name="doing_business_as"
                                        value={siteFormData.doing_business_as}
                                        placeholder="Enter your DBA"
                                        onChange={(e: any) => {
                                            handleInputChange(e.target.name, e.target.value)
                                            handleValidation(e.target.name, e.target.value)
                                        }}

                                    />
                                    {error.doing_business_as !== "" && presentIn === "Site Client Popup" &&
                                        <span className="font-14 font-medium alert-text">
                                            {error.doing_business_as}
                                        </span>
                                    }
                                </div>
                                <div className="col-lg-6 mb-4">
                                    <label
                                        htmlFor="industry1"
                                        className="font-semibold font-15 primary-text-color mb-2"
                                    >
                                        Specify your Industry
                                    </label>
                                    <select

                                        id="Industry2"

                                        className={`w-100 d-block form-select custom-form-fields  color-black-v1 font-14 font-medium cust-form-arrow  ${error.industry !== "" && presentIn === "Site Client Popup" ? 'critical-field' : ''}`}
                                        name="industry"
                                        value={siteFormData.industry}
                                        onChange={(e: any) => {
                                            handleInputChange(e.target.name, e.target.value)
                                            handleValidation(e.target.name, e.target.value)
                                        }}
                                    >
                                        <option value="option" className="font-semibold font-15 primary-text-color mb-2">Select</option>
                                        {bindIndustryDropdown()}
                                    </select>
                                    {error.industry !== "" && presentIn === "Site Client Popup" &&
                                        <span className="font-14 font-medium alert-text">
                                            {error.industry}
                                        </span>
                                    }
                                </div>
                                {isOtherIndustrySIte &&
                                    <div className="col-lg-6 mb-4">
                                        <label
                                            htmlFor="IndustryName"
                                            className="font-semibold font-15 primary-text-color mb-2"
                                        >
                                            Industry Name
                                        </label>
                                        <input
                                            type="text"
                                            id="IndustryName"
                                            // className="form-control custom-form-fields  color-black-v1 font-14 font-medium"
                                            className={`form-control custom-form-fields color-black-v1 font-14 font-medium  ${error.doing_business_as !== "" && presentIn === "Site Client Popup" ? 'critical-field' : ''}`}
                                            name="industry_name"
                                            value={siteFormData.doing_business_as}
                                            placeholder="Enter your Industry"
                                            onChange={(e: any) => {
                                                handleInputChange(e.target.name, e.target.value)
                                                handleValidation(e.target.name, e.target.value)
                                            }}

                                        />
                                        {error.doing_business_as !== "" && presentIn === "Site Client Popup" &&
                                            <span className="font-14 font-medium alert-text">
                                                {error.doing_business_as}
                                            </span>
                                        }
                                    </div>}
                                <div className="col-lg-6 mb-4">
                                    <label
                                        htmlFor="email"
                                        className="font-semibold font-15 primary-text-color mb-2"
                                    >
                                        Business Email Address
                                    </label>
                                    <input
                                        type="text"
                                        id="email"
                                        // className="form-control custom-form-fields  color-black-v1 font-14 font-medium"
                                        className={`form-control custom-form-fields color-black-v1 font-14 font-medium  ${(error.email_id !== "" || error.unique_email !== "") && presentIn === "Site Client Popup" ? 'critical-field' : ''}`}
                                        name="email_id"
                                        value={siteFormData.email_id}
                                        placeholder="Enter Business Email Address"
                                        onChange={(e: any) => {
                                            handleInputChange(e.target.name, e.target.value)
                                            handleValidation(e.target.name, e.target.value)
                                        }}

                                    />
                                    {error.email_id !== "" && presentIn === "Site Client Popup" &&
                                        <span className="font-14 font-medium alert-text">
                                            {error.email_id}
                                        </span>
                                    }
                                    {error.unique_email !== "" && presentIn === "Site Client Popup" &&
                                        <span className="font-14 font-medium alert-text">
                                            {error.unique_email}
                                        </span>
                                    }
                                </div>
                                <div className="col-lg-6 mb-4">
                                    <label
                                        htmlFor="phone"
                                        className="font-semibold font-15 primary-text-color mb-2"
                                    >
                                        Phone #
                                    </label>
                                    <input
                                        type="text"
                                        id="phone"
                                        // className="form-control custom-form-fields color-black-v1 font-14 font-medium"
                                        className={`form-control custom-form-fields color-black-v1 font-14 font-medium  ${error.phone_no !== "" && presentIn === "Site Client Popup" ? 'critical-field' : ''}`}
                                        name="phone_no"
                                        placeholder="Enter Phone #"
                                        pattern="\+1 \(\d{3}\) \d{3}-\d{4}"
                                        value={siteFormData.phone_no}

                                        onChange={(e: any) => {
                                            handleInputChange(e.target.name, e.target.value)
                                            handleValidation(e.target.name, e.target.value)
                                        }}


                                    />
                                    {error.phone_no !== "" && presentIn === "Site Client Popup" &&
                                        <span className="font-14 font-medium alert-text">
                                            {error.phone_no}
                                        </span>
                                    }
                                </div>
                                <div className="d-flex align-items-center gap-2 my-4">
                                    <h3 className="font-18 font-semibold color-black mb-0">
                                        Site Addresses
                                    </h3>
                                </div>
                                <div className="col-lg-6 mb-4">
                                    <label
                                        htmlFor="Address1"
                                        className="font-semibold font-15 primary-text-color mb-2"
                                    >
                                        Address Line 1
                                    </label>
                                    <input
                                        type="text"
                                        id="Address1"
                                        // className="form-control custom-form-fields color-black-v1 font-14 font-medium"
                                        className={`form-control custom-form-fields color-black-v1 font-14 font-medium  ${error.address_line_1 !== "" && presentIn === "Site Client Popup" ? 'critical-field' : ''}`}
                                        name="address_line_1"
                                        value={siteFormData.address_line_1}
                                        placeholder="Enter Address Line 1"
                                        onChange={(e: any) => {
                                            handleInputChange(e.target.name, e.target.value)
                                            handleValidation(e.target.name, e.target.value)
                                        }}

                                    />
                                    {error.address_line_1 !== "" && presentIn === "Site Client Popup" &&
                                        <span className="font-14 font-medium alert-text">
                                            {error.address_line_1}
                                        </span>
                                    }
                                </div>
                                <div className="col-lg-6 mb-4">
                                    <label
                                        htmlFor="Address2"
                                        className="font-semibold font-15 primary-text-color mb-2"
                                    >
                                        Address Line 2
                                    </label>
                                    <input
                                        type="text"
                                        id="Address2"
                                        // className="form-control custom-form-fields color-black-v1 font-14 font-medium"
                                        className={`form-control custom-form-fields color-black-v1 font-14 font-medium  ${error.address_line_2 !== "" && presentIn === "Site Client Popup" ? 'critical-field' : ''}`}
                                        name="address_line_2"
                                        value={siteFormData.address_line_2}
                                        placeholder="Enter Address Line 2"
                                        onChange={(e: any) => {
                                            handleInputChange(e.target.name, e.target.value)
                                            handleValidation(e.target.name, e.target.value)
                                        }}

                                    />
                                    {error.address_line_2 !== "" && presentIn === "Site Client Popup" &&
                                        <span className="font-14 font-medium alert-text">
                                            {error.address_line_2}
                                        </span>
                                    }
                                </div>
                                <div className="col-lg-6 mb-4">
                                    <label
                                        htmlFor="zipcode"
                                        className="font-semibold font-15 primary-text-color mb-2"
                                    >
                                        Zip Code
                                    </label>
                                    <input
                                        type="text"
                                        id="zipcode"
                                        // className="form-control custom-form-fields color-black-v1 font-14 font-medium"
                                        className={`form-control custom-form-fields color-black-v1 font-14 font-medium  ${error.zip_code !== "" && presentIn === "Site Client Popup" ? 'critical-field' : ''}`}
                                        name="zip_code"
                                        value={siteFormData.zip_code}
                                        placeholder="Enter Zip Code"
                                        onChange={(e: any) => {
                                            handleInputChange(e.target.name, e.target.value)
                                            handleValidation(e.target.name, e.target.value)
                                        }}

                                    />
                                    {error.zip_code !== "" && presentIn === "Site Client Popup" &&
                                        <span className="font-14 font-medium alert-text">
                                            {error.zip_code}
                                        </span>
                                    }
                                </div>
                                <div className="col-lg-6 mb-4">
                                    <label
                                        htmlFor="state"
                                        className="font-semibold font-15 primary-text-color mb-2"
                                    >
                                        State
                                    </label>
                                    <select
                                        id="state"
                                        className={`w-100 d-block form-select custom-form-fields color-black-v1 font-14 font-medium cust-form-arrow  ${error.state !== "" && presentIn === "Site Client Popup" ? 'critical-field' : ''}`}
                                        name="state"
                                        value={siteFormData.state}
                                        onChange={(e: any) => {
                                            handleInputChange(e.target.name, e.target.value)
                                        }}
                                    >
                                        <option value="option" className="font-semibold font-15 primary-text-color mb-2">Select</option>
                                        {bindStateDropdown()}
                                    </select>
                                    {error.state !== "" && presentIn === "Site Client Popup" &&
                                        <span className="font-14 font-medium alert-text">
                                            {error.state}
                                        </span>
                                    }
                                </div>
                                <div className="col-lg-6 mb-4">
                                    <label
                                        htmlFor="city"
                                        className="font-semibold font-15 primary-text-color mb-2"
                                    >
                                        City
                                    </label>
                                    <select
                                        id="city"
                                        className={`w-100 d-block form-select custom-form-fields color-black-v1 font-14 font-medium cust-form-arrow  ${error.city !== "" && presentIn === "Site Client Popup" ? 'critical-field' : ''}`}
                                        name="city"
                                        value={siteFormData.city}
                                        disabled={DisableCity}
                                        onChange={(e: any) => {
                                            handleInputChange(e.target.name, e.target.value)
                                            handleValidation(e.target.name, e.target.value)
                                        }}
                                    >
                                        <option value="option" className="font-semibold font-15 primary-text-color mb-2">Select</option>
                                        {bindCityDropdown()}
                                    </select>
                                    {error.city !== "" && presentIn === "Site Client Popup" &&
                                        <span className="font-14 font-medium alert-text">
                                            {error.city}
                                        </span>
                                    }
                                </div>
                                <div className="col-lg-6 mb-4">
                                    <label
                                        htmlFor="state"
                                        className="font-semibold font-15 primary-text-color mb-2"
                                    >
                                        Region
                                    </label>
                                    <select
                                        id="state"
                                        className={`w-100 d-block form-select custom-form-fields color-black-v1 font-14 font-medium cust-form-arrow  ${error.region !== "" && presentIn === "Site Client Popup" ? 'critical-field' : ''}`}
                                        name="region_id"
                                        value={siteFormData.region_id}
                                        onChange={(e: any) => {
                                            handleInputChange(e.target.name, e.target.value)
                                            handleValidation(e.target.name, e.target.value)
                                        }}
                                    >
                                        <option value="option" className="font-semibold font-15 primary-text-color mb-2">Select</option>
                                        {bindRegionDropdown()}
                                    </select>
                                    {error.region !== "" && presentIn === "Site Client Popup" &&
                                        <span className="font-14 font-medium alert-text">
                                            {error.region}
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer gap-2 border-0 px-4 py-4 justify-content-end">
                            <button className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                                onClick={() => { PopupCancel() }}>
                                Cancel
                            </button>
                            <button
                                className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                                disabled={!sumbitButtonEnabled && presentIn === "Site Client Popup"}
                                onClick={() => {
                                    SavePopupForm()
                                }}
                            >
                                Save Details
                            </button>
                        </div>
                    </div>
                </div>
            </div></>
        )
    }

