import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { getServiceBid, manageServiceRequest, modifyServiceBid, saveFeedback } from "../../Service/ServiceRequest/ServiceRequestService";
import Toast from "../Toast/Toast";
import { feedbackDetails, gridDataType, serviceBidType, toastDetailsType } from "../../Interface/ServiceRequest";
import ServiceBid from "./ServiceBid";
import moment from "moment";
import { getDifferenceInMinutes, ppsContext } from "../../Configs/Constant";
import Countdown from "./ServiceCountdown";
import Feedback from "./ServiceFeedback";
import Loader from "../Loader/Loader";
import NoRecordFound from "../NoRecordFound/NoRecordFound";

function ServiceRequestGrid() {

    const navigate = useNavigate();
    const { state } = useLocation();

    const contextVal: any = useContext(ppsContext);
    console.log(contextVal.userDetails, "context");

    const [filter, setFilter] = useState<any>({
        from: "",
        to: "",
        status: ""
    })
    const [gridPayload, setGridPayload] = useState<gridDataType>({
        type: "customer",
        tab: "awaiting-approval",
        search: "",
        sort: "srd.created_on DESC",
        limit: 100,
        filter: filter
    })
    const [gridDetails, setGridDetails] = useState<any>();
    const [serviceBid, setServiceBid] = useState<serviceBidType>();
    const [isServiceBid, setIsServiceBid] = useState<boolean>(false);
    const [toastDetails, setToastDetails] = useState<toastDetailsType>({
        isToast: state?.isToast,
        toastMessage: state?.toastMessage,
        toastType: state?.toastType
    });
    const [feedbackId, setFeedbackId] = useState<string>("")
    const [feedbackDetails, setFeedbackDetails] = useState<feedbackDetails>({
        feedback_rating: 0,
        feedback_description: ""
    })
    const [editId, setEditId] = useState<string>("");
    const [gridSearch, setGridSearch] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowFilter, setIsShowFilter] = useState<boolean>(false);

    useEffect(() => {
        pageload()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridPayload])

    useEffect(() => {
        fetchServiceBid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const pageload = async () => {
        try {
            await fetchGridDetails()
        }
        catch (error: any) {
            console.log(error, "pageload")
        }
    }

    const fetchGridDetails = async () => {
        try {
            setIsLoading(true);
            let response = await manageServiceRequest(gridPayload)
            console.log(response, "getGridDetails Response")
            if (response?.code === 200) {
                setGridDetails(response?.data?.grid_details)
            }
            setIsLoading(false);
        }
        catch (error) {
            console.log(error, "fetchGridDetails")
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const fetchServiceBid = async () => {
        try {
            setIsLoading(true);
            let response = await getServiceBid();
            if (response.code === 200) {
                setServiceBid(response.data);
                if (response?.data?.length > 0) {
                    setIsServiceBid(true)
                }
            } else {
                setIsServiceBid(false)
            }
            setIsLoading(false);
        }
        catch (error: any) {
            console.log(error, "fetchServiceBid")
        }
    }

    const serviceBidAction = async (id?: string, action?: string, bidId?: string) => {
        try {

            let modifyServiceBidObj = {
                service_request_uuid: id,
                action: action,
                bid_uuid: bidId
            }

            setIsLoading(true)
            let response = await modifyServiceBid(modifyServiceBidObj);

            if (response.code === 200) {
                setToastDetails({
                    isToast: action !== "close" ? true : false,
                    toastMessage: action === "Approve" ? "Service Requested Accepted Successfully" : action === "Rejected" ? "Service Requested Rejected Successfully" : "",
                    toastType: action !== "close" ? "Success" : "Failure"
                })
                await fetchServiceBid();
            }

            setIsLoading(false);
        } catch (error) {
            console.log(error, "serviceBidAction")
        }
    }

    const handleGridPayload = (name: string, value: any) => {
        try {
            setGridPayload({ ...gridPayload, [name]: value })
        }
        catch (error: any) {
            console.log(error, "handleGridPayload")
        }
    }

    const sumbitFeedback = async (id: string) => {
        try {
            let feedbackObj = {
                ...feedbackDetails,
                service_request_uuid: id
            }
            setIsLoading(true);
            let response = await saveFeedback(feedbackObj);
            if (response?.code === 200) {
                setFeedbackId("")
                await fetchGridDetails();
                setToastDetails({
                    isToast: true,
                    toastMessage: "Feedback Submitted Successfully",
                    toastType: "Success"
                })
            }
            setIsLoading(false);
        }
        catch (error: any) {

        }
    }

    const handleFilter = (name: string, value: any) => {
        try {
            setFilter({ ...filter, [name]: value })
        } catch (error: any) {
            console.log(error, "handleFilter")
        }
    }

    console.log(filter, "filter")
    return (
        <>
            {isLoading ? <Loader /> :
                <div className="container-fluid p-4 pt-5">
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <h2 className="color-black font-20 font-bold">Manage Requests</h2>
                        <div className="d-flex align-items-center justify-content-md-center gap-3 flex-wrap mt-2 mt-md-0">
                            {/* search */}
                            <div className="position-relative">
                                <input
                                    type="search"
                                    className=" custom-input-search font-14 font-medium color-black custom-search"
                                    placeholder="Search here"
                                    name="search"
                                    value={gridSearch}
                                    onChange={(e: any) => { setGridSearch(e.target.value) }}
                                    onKeyUp={(e) => { e.key === "Enter" ? setGridPayload({ ...gridPayload, search: gridSearch }) : <></> }}
                                />
                                <button className="btn bg-transparent border-0 focus-none search-icon"
                                    onClick={() => setGridPayload({ ...gridPayload, search: gridSearch })}
                                >
                                    <img
                                        className="tab-icon"
                                        src="../images/search-icon.svg"
                                        alt="search"
                                    />
                                </button>
                            </div>
                            {/* filter */}
                            <button
                                className="btn common-btn px-3"
                                aria-expanded="true"
                                data-bs-auto-close="outside"
                                onClick={() => setIsShowFilter(!isShowFilter)}
                            >
                                <img src="../images/filter-icons.svg" alt="filter-icons" />
                            </button>
                            {isShowFilter && <div
                                className="dropdown-menu dd-box shadow-sm p-4 border-0 show"
                                data-popper-placement="bottom-start"
                                style={{
                                    position: "absolute",
                                    inset: "0px auto auto 0px",
                                    margin: 0,
                                    transform: "translate(1113px, 163px)"
                                }}
                            >
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5 className="font-16 font-semibold color-black mb-0">
                                            Advanced Filter
                                        </h5>
                                        <button
                                            className="btn-close font-12"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => setIsShowFilter(false)}
                                        />
                                    </div>
                                    <div className="row mt-3">
                                        {gridPayload?.tab === "closed" && <div className="col-md-12 col-sm-12">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="Rating"
                                                    className="form-label color-grey-v1 font-12 font-medium"
                                                >
                                                    Status
                                                </label>
                                                <select
                                                    name="status"
                                                    id="Incident"
                                                    className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular bg-transparent"
                                                    value={filter?.status}
                                                    onChange={(e: any) => handleFilter(e.target.name, e.target.value)}
                                                >
                                                    <option value="option1">Select</option>
                                                    <option value="Duplicate">Duplicate</option>
                                                    <option value="Closed">Closed</option>
                                                </select>
                                            </div>
                                        </div>}
                                        <div className="col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="From"
                                                    className="form-label color-grey-v1 font-12 font-medium"
                                                >
                                                    From
                                                </label>
                                                <input
                                                    type="date"
                                                    id="From"
                                                    name="from"
                                                    className="form-control service-form-field color-black-v2 font-14 font-regular bg-transparent filter-field"
                                                    value={filter?.from}
                                                    onChange={(e: any) => handleFilter(e.target.name, e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="To"
                                                    className="form-label color-grey-v1 font-12 font-medium"
                                                >
                                                    To
                                                </label>
                                                <input
                                                    type="date"
                                                    id="To"
                                                    name="to"
                                                    className="form-control service-form-field color-black-v2 font-14 font-regular bg-transparent filter-field"
                                                    value={filter?.to}
                                                    onChange={(e: any) => handleFilter(e.target.name, e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap-2 border-0 px-0 pt-5 pb-3 justify-content-end">
                                        <button className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                                        onClick={()=>handleGridPayload("filter", {
                                            
                                        })}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3"
                                            onClick={() => handleGridPayload("filter", filter)}
                                        >
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </div>}

                            {/* service request */}
                            <button className="btn border-0 d-flex align-items-center gap-3 color-white font-semibold font-14 primary-btn px-4 rounded-3"
                                onClick={() => {
                                    navigate('/fill-service-request')
                                }} >
                                <img src="../images/plus-white-icon.svg" alt="Plus Icon" />
                                Service Request
                            </button>
                        </div>
                    </div>
                    {/* tab structure */}
                    <div className="tab-scroll my-4">
                        <ul
                            className="nav nav-pills cust-nav-pills flex-nowrap"
                            id="pills-tab"
                            role="tablist"
                        >
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link cust-nav-link text-nowrap ${gridPayload.tab === "awaiting-approval" ? 'active' : ''}`}
                                    id="pills-home-tab"
                                    role="tab"
                                    aria-controls="pills-home"
                                    onClick={() => { handleGridPayload("tab", "awaiting-approval"); setGridDetails("") }}
                                >
                                    Awaiting Approval <span>{gridPayload?.tab === "awaiting-approval" ? `(${gridDetails?.length ? gridDetails?.length : '0'})` : ""}</span>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link cust-nav-link text-nowrap ${gridPayload.tab === "in-review" ? 'active' : ''}`}
                                    id="pills-profile-tab"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    onClick={() => { handleGridPayload("tab", "in-review"); setGridDetails("") }}
                                >
                                    In Review <span>{gridPayload?.tab === "in-review" ? `(${gridDetails?.length ? gridDetails?.length : '0'})` : ""}</span>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link cust-nav-link text-nowrap ${gridPayload.tab === "in-progress" ? 'active' : ''}`}
                                    id="pills-contact-tab"
                                    role="tab"
                                    aria-controls="pills-contact"
                                    onClick={() => { handleGridPayload("tab", "in-progress"); setGridDetails("") }}
                                >
                                    In Progress <span>{gridPayload?.tab === "in-progress" ? `(${gridDetails?.length ? gridDetails?.length : '0'})` : ""}</span>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link cust-nav-link text-nowrap ${gridPayload.tab === "closed" ? 'active' : ''}`}
                                    id="pills-contact-tab2"
                                    role="tab"
                                    aria-controls="pills-contact2"
                                    onClick={() => { handleGridPayload("tab", "closed"); setGridDetails("") }}
                                >
                                    Closed <span>{gridPayload?.tab === "closed" ? `(${gridDetails?.length ? gridDetails?.length : '0'})` : ""}</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                        {/* 1st tab */}
                        <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                        >
                            <div className="row">
                                {gridDetails?.length > 0 ?
                                    gridDetails?.map((value: any, index: number) => (
                                        gridPayload.tab === "awaiting-approval" ?
                                            <div className="col-xl-4 col-md-6   col-12 p-3">
                                                <div className="event-detail">
                                                    <div className="d-flex align-items-center justify-content-between p-3">
                                                        <div className="d-flex align-items-center gap-3">
                                                            <img
                                                                className="event-logo"
                                                                src="../images/event-logo.svg"
                                                                alt="event-logo"
                                                            />
                                                            <div className="d-block">
                                                                <p className="font-16 font-semibold mb-0 text-color-4 cursor-pointer"
                                                                    onClick={() => {
                                                                        navigate('/view-service-request', {
                                                                            state: {
                                                                                id: value?.service_request_uuid,
                                                                                status: gridPayload?.tab
                                                                            }
                                                                        })
                                                                    }}>
                                                                    {value?.service_request}
                                                                </p>
                                                                <span className="font-14 font-medium color-grey-v3 ">
                                                                    <span>{moment(value?.service_start_date)?.format('MMM DD, YYYY')}</span> - <span>{moment(value?.service_end_date)?.format('MMM DD, YYYY')}</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="position-relative">
                                                            <button
                                                                className="position-relative action-btn shadow-none btn"
                                                                aria-expanded="false"
                                                                onClick={() => setEditId(editId === value?.service_request_uuid ? "" : value?.service_request_uuid)}
                                                            >
                                                                <img src="../images/detail.svg" alt="detail" />
                                                            </button>
                                                            {editId === value?.service_request_uuid && <ul
                                                                className="dropdown-menu action-drp border-0 px-2 show"
                                                                aria-labelledby="dropdownMenuLink"
                                                            >
                                                                <li className="font-medium font-16 color-black">
                                                                    <button className="btn px-1 font-medium font-14 color-black px-2 p-2 border-0 w-100 text-start"
                                                                        disabled={true} >
                                                                        <img
                                                                            className="me-2"
                                                                            src="../images/edit-icon.svg"
                                                                            alt="edit-icon"
                                                                        />
                                                                        Edit
                                                                    </button>
                                                                </li>
                                                                <li className="font-medium font-16 color-black">
                                                                    <button className="btn px-1 font-medium font-14 color-black px-2 p-2 border-0 w-100 text-start"
                                                                        onClick={() => {
                                                                            navigate('/fill-service-request', {
                                                                                state: {
                                                                                    id: value?.service_request_uuid,
                                                                                    status: gridPayload?.tab,
                                                                                    isDuplicate: true
                                                                                }
                                                                            })
                                                                        }}>
                                                                        <img
                                                                            className="me-2"
                                                                            src="../images/duplicate.svg"
                                                                            alt="duplicate"
                                                                        />
                                                                        Duplicate
                                                                    </button>
                                                                </li>
                                                            </ul>}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between gap-sm-4 gap-2 mt-2 p-3 flex-wrap">
                                                        <div className="d-flex align-items-center gap-2 font-14 font-semibold text-color-5 flex-nowrap text-nowrap">
                                                            <img
                                                                className="tab-icon"
                                                                src="../images/filled-location.svg"
                                                                alt="location"
                                                            />
                                                            {value?.location_type}
                                                        </div>
                                                        <div className="d-flex align-items-center gap-2 font-14 font-semibold text-color-5 flex-nowrap text-nowrap">
                                                            <img
                                                                className="icon-sty"
                                                                src="../images/filled-people.svg"
                                                                alt="people"
                                                            />
                                                            {value?.security_people_count}
                                                        </div>
                                                        <div className="d-flex align-items-center gap-2 font-14 font-semibold text-color-5 flex-nowrap text-nowrap">
                                                            <img
                                                                className="tab-icon"
                                                                src="../images/filled-guards.svg"
                                                                alt="Guards"
                                                            />
                                                            {value?.service_requested}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between cust-padding cust-border-top flex-wrap gap-2">
                                                        <span className="color-grey font-14 font-medium color-grey-v4">
                                                            Quoted Amount per Hour
                                                        </span>
                                                        <span className="font-16 font-semibold text-color-5">
                                                            $ <span>{value?.amount}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div> :
                                            gridPayload?.tab === "in-review" ?
                                                <div className="col-xl-4 col-md-6 col-12 p-3">
                                                    <div className="event-detail">
                                                        <div className="d-flex align-items-center justify-content-between p-3">
                                                            <div className="d-flex align-items-center gap-3">
                                                                <img
                                                                    className="event-logo"
                                                                    src="../images/event-logo.svg"
                                                                    alt="event-logo"
                                                                />
                                                                <div className="d-block">
                                                                    <p className="font-16 font-semibold mb-0 text-color-4 cursor-pointer"
                                                                        onClick={() => {
                                                                            navigate('/view-service-request', {
                                                                                state: {
                                                                                    id: value?.service_request_uuid,
                                                                                    status: gridPayload?.tab
                                                                                }
                                                                            })
                                                                        }}
                                                                    >
                                                                        {value?.service_request}
                                                                    </p>
                                                                    <span className="font-14 font-medium color-grey-v3 ">
                                                                        <span>{moment(value?.service_start_date)?.format('MMM DD, YYYY')}</span> - <span>{moment(value?.service_end_date)?.format('MMM DD, YYYY')}</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="position-relative">
                                                                <button
                                                                    className="position-relative action-btn shadow-none btn"
                                                                    aria-expanded="false"
                                                                    onClick={() => setEditId(editId === value?.service_request_uuid ? "" : value?.service_request_uuid)}
                                                                >
                                                                    <img src="../images/detail.svg" alt="detail" />
                                                                </button>
                                                                {editId === value?.service_request_uuid && <ul
                                                                    className="dropdown-menu action-drp border-0 px-2 show"
                                                                    style={{ display: "block" }}
                                                                    aria-labelledby="dropdownMenuLink"
                                                                >
                                                                    <li className="font-medium font-16 color-black">
                                                                        <button className="btn px-1 font-medium font-14 color-black px-2 p-2 border-0 w-100 text-start"
                                                                            onClick={() => {
                                                                                navigate('/fill-service-request', {
                                                                                    state: {
                                                                                        id: value?.service_request_uuid,
                                                                                        status: gridPayload?.tab
                                                                                    }
                                                                                })
                                                                            }}>
                                                                            <img
                                                                                className="me-2"
                                                                                src="../images/edit-icon.svg"
                                                                                alt="edit-icon"
                                                                            />
                                                                            Edit
                                                                        </button>
                                                                    </li>
                                                                    <li className="font-medium font-16 color-black">
                                                                        <button className="btn px-1 font-medium font-14 color-black px-2 p-2 border-0 w-100 text-start"
                                                                            onClick={() => {
                                                                                navigate('/fill-service-request', {
                                                                                    state: {
                                                                                        id: value?.service_request_uuid,
                                                                                        status: gridPayload?.tab,
                                                                                        isDuplicate: true
                                                                                    }
                                                                                })
                                                                            }}>
                                                                            <img
                                                                                className="me-2"
                                                                                src="../images/duplicate.svg"
                                                                                alt="duplicate"
                                                                            />
                                                                            Duplicate
                                                                        </button>
                                                                    </li>
                                                                </ul>}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between gap-sm-4 gap-2 mt-2 p-3 flex-wrap">
                                                            <div className="d-flex align-items-center gap-2 font-14 font-semibold text-color-5 flex-nowrap text-nowrap">
                                                                <img
                                                                    className="tab-icon"
                                                                    src="../images/filled-location.svg"
                                                                    alt="location"
                                                                />
                                                                {value?.location_type}
                                                            </div>
                                                            <div className="d-flex align-items-center gap-2 font-14 font-semibold text-color-5 flex-nowrap text-nowrap">
                                                                <img
                                                                    className="icon-sty"
                                                                    src="../images/filled-people.svg"
                                                                    alt="people"
                                                                />
                                                                {value?.security_people_count}
                                                            </div>
                                                            <div className="d-flex align-items-center gap-2 font-14 font-semibold text-color-5 flex-nowrap text-nowrap">
                                                                <img
                                                                    className="tab-icon"
                                                                    src="../images/filled-guards.svg"
                                                                    alt="Guards"
                                                                />
                                                                {value?.service_requested}
                                                            </div>
                                                        </div>
                                                        {getDifferenceInMinutes(value?.response_time) < 180 ?
                                                            <div className="d-flex align-items-center cust-padding gap-2 success-msg">
                                                                <img src="../images/await-time.svg" alt="await-time" />
                                                                <span className="font-medium font-14 ">
                                                                    You'll receive a response in <Countdown startValue={180 - getDifferenceInMinutes(value?.response_time)} /> minutes.
                                                                </span>
                                                            </div> :
                                                            <div className="d-flex align-items-start cust-padding gap-2 delay-msg">
                                                                <img className="mt-1" src="../images/delay-time.svg" alt="delay-time" />
                                                                <span className="font-medium font-14 ">It’s taking longer than expected.</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                : gridPayload.tab === "in-progress" ?
                                                    <div className="col-xl-4 col-md-6   col-12 p-3">
                                                        <div className="event-detail">
                                                            <div className="d-flex align-items-center justify-content-between p-3">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        className="event-logo"
                                                                        src="../images/event-logo.svg"
                                                                        alt="event-logo"
                                                                    />
                                                                    <div className="d-block">
                                                                        <p className="font-16 font-semibold mb-0 text-color-4 cursor-pointer"
                                                                            onClick={() => {
                                                                                navigate('/view-service-request', {
                                                                                    state: {
                                                                                        id: value?.service_request_uuid,
                                                                                        status: gridPayload?.tab
                                                                                    }
                                                                                })
                                                                            }}
                                                                        >
                                                                            {value?.service_request}
                                                                        </p>
                                                                        <span className="font-14 font-medium color-grey-v3 ">
                                                                            <span>{moment(value?.service_start_date)?.format('MMM DD, YYYY')}</span> - <span>{moment(value?.service_end_date)?.format('MMM DD, YYYY')}</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="position-relative">
                                                                    <button
                                                                        className="position-relative action-btn shadow-none btn"
                                                                        aria-expanded="false"
                                                                        onClick={() => setEditId(editId === value?.service_request_uuid ? "" : value?.service_request_uuid)}
                                                                    >
                                                                        <img src="../images/detail.svg" alt="detail" />
                                                                    </button>
                                                                    {editId === value?.service_request_uuid && <ul
                                                                        className="dropdown-menu action-drp border-0 px-2 show"
                                                                        aria-labelledby="dropdownMenuLink"
                                                                    >
                                                                        <li className="font-medium font-16 color-black">
                                                                            <button className="btn px-1 font-medium font-14 color-black px-2 p-2 border-0 w-100 text-start"
                                                                                onClick={() => {
                                                                                    navigate('/fill-service-request', {
                                                                                        state: {
                                                                                            id: value?.service_request_uuid,
                                                                                            status: gridPayload?.tab
                                                                                        }
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    className="me-2"
                                                                                    src="../images/edit-icon.svg"
                                                                                    alt="edit-icon"
                                                                                />
                                                                                Edit
                                                                            </button>
                                                                        </li>
                                                                        <li className="font-medium font-16 color-black">
                                                                            <button className="btn px-1 font-medium font-14 color-black px-2 p-2 border-0 w-100 text-start"
                                                                                onClick={() => {
                                                                                    navigate('/fill-service-request', {
                                                                                        state: {
                                                                                            id: value?.service_request_uuid,
                                                                                            status: gridPayload?.tab,
                                                                                            isDuplicate: true
                                                                                        }
                                                                                    })
                                                                                }}>
                                                                                <img
                                                                                    className="me-2"
                                                                                    src="../images/duplicate.svg"
                                                                                    alt="duplicate"
                                                                                />
                                                                                Duplicate
                                                                            </button>
                                                                        </li>
                                                                    </ul>}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between gap-sm-4 gap-2 mt-2 p-3 flex-wrap">
                                                                <div className="d-flex align-items-center gap-2 font-14 font-semibold text-color-5 flex-nowrap text-nowrap">
                                                                    <img
                                                                        className="tab-icon"
                                                                        src="../images/filled-location.svg"
                                                                        alt="location"
                                                                    />
                                                                    {value?.location_type}
                                                                </div>
                                                                <div className="d-flex align-items-center gap-2 font-14 font-semibold text-color-5 flex-nowrap text-nowrap">
                                                                    <img
                                                                        className="icon-sty"
                                                                        src="../images/filled-people.svg"
                                                                        alt="people"
                                                                    />
                                                                    {value?.security_people_count}
                                                                </div>
                                                                <div className="d-flex align-items-center gap-2 font-14 font-semibold text-color-5 flex-nowrap text-nowrap">
                                                                    <img
                                                                        className="tab-icon"
                                                                        src="../images/filled-guards.svg"
                                                                        alt="Guards"
                                                                    />
                                                                    {value?.service_requested}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    gridPayload?.tab === "closed" ?
                                                        <div className="col-xl-4 col-md-6   col-12 p-3">
                                                            <div className="event-detail">
                                                                <div className="d-flex align-items-center justify-content-between p-3">
                                                                    <div className="d-flex align-items-center gap-3">
                                                                        <img
                                                                            className="event-logo"
                                                                            src="../images/event-logo.svg"
                                                                            alt="event-logo"
                                                                        />
                                                                        <div className="d-block">
                                                                            <p className="font-16 font-semibold mb-0 text-color-4 cursor-pointer"
                                                                                onClick={() => {
                                                                                    navigate('/view-service-request', {
                                                                                        state: {
                                                                                            id: value?.service_request_uuid,
                                                                                            status: gridPayload?.tab
                                                                                        }
                                                                                    })
                                                                                }}
                                                                            >
                                                                                {value?.service_request}
                                                                            </p>
                                                                            <span className="font-14 font-medium color-grey-v3 ">
                                                                                <span>{moment(value?.service_start_date)?.format('MMM DD, YYYY')}</span> - <span>{moment(value?.service_end_date)?.format('MMM DD, YYYY')}</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="position-relative">
                                                                        <button
                                                                            className="position-relative action-btn shadow-none btn"
                                                                            aria-expanded="false"
                                                                            onClick={() => setEditId(editId === value?.service_request_uuid ? "" : value?.service_request_uuid)}
                                                                        >
                                                                            <img src="../images/detail.svg" alt="detail" />
                                                                        </button>
                                                                        {editId === value?.service_request_uuid && <ul
                                                                            className="dropdown-menu action-drp border-0 px-2 show"
                                                                            aria-labelledby="dropdownMenuLink"
                                                                        >
                                                                            <li className="font-medium font-16 color-black">
                                                                                <button className="btn px-1 font-medium font-14 color-black px-2 p-2 border-0 w-100 text-start"
                                                                                    disabled={true}
                                                                                >
                                                                                    <img
                                                                                        className="me-2"
                                                                                        src="../images/edit-icon.svg"
                                                                                        alt="edit-icon"
                                                                                    />
                                                                                    Edit
                                                                                </button>
                                                                            </li>
                                                                            <li className="font-medium font-16 color-black">
                                                                                <button className="btn px-1 font-medium font-14 color-black px-2 p-2 border-0 w-100 text-start"
                                                                                    onClick={() => {
                                                                                        navigate('/fill-service-request', {
                                                                                            state: {
                                                                                                id: value?.service_request_uuid,
                                                                                                status: gridPayload?.tab,
                                                                                                isDuplicate: true
                                                                                            }
                                                                                        })
                                                                                    }}>
                                                                                    <img
                                                                                        className="me-2"
                                                                                        src="../images/duplicate.svg"
                                                                                        alt="duplicate"
                                                                                    />
                                                                                    Duplicate
                                                                                </button>
                                                                            </li>
                                                                        </ul>}
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center justify-content-between gap-sm-4 gap-2 mt-2 p-3 flex-wrap">
                                                                    <div className="d-flex align-items-center gap-2 font-14 font-semibold text-color-5 flex-nowrap text-nowrap">
                                                                        <img
                                                                            className="tab-icon"
                                                                            src="../images/filled-location.svg"
                                                                            alt="location"
                                                                        />
                                                                        {value?.location_type}
                                                                    </div>
                                                                    <div className="d-flex align-items-center gap-2 font-14 font-semibold text-color-5 flex-nowrap text-nowrap">
                                                                        <img
                                                                            className="icon-sty"
                                                                            src="../images/filled-people.svg"
                                                                            alt="people"
                                                                        />
                                                                        {value?.security_people_count}
                                                                    </div>
                                                                    <div className="d-flex align-items-center gap-2 font-14 font-semibold text-color-5 flex-nowrap text-nowrap">
                                                                        <img
                                                                            className="tab-icon"
                                                                            src="../images/filled-guards.svg"
                                                                            alt="Guards"
                                                                        />
                                                                        {value?.service_requested}
                                                                    </div>
                                                                </div>
                                                                {value?.rejected_date ?
                                                                    <div className="d-flex align-items-center justify-content-between cust-padding cust-border-top flex-wrap gap-2">
                                                                        <span className="color-grey font-13 font-medium text-color-5">Rejected Date: {moment(value?.rejected_date)?.format('DD MMM, YYYY')}</span>
                                                                        <span className="status-indicator reject-status">
                                                                            <span className="status-text font-medium font-12 d-flex">Reject</span>
                                                                        </span>
                                                                    </div>
                                                                    : !value?.feedback_rating ?
                                                                        <div className="d-flex align-items-center justify-content-between cust-padding cust-border-top flex-wrap gap-2">
                                                                            <span className="color-grey font-13 font-medium text-color-5">
                                                                                Completed Date: <span>{moment(value?.service_end_date)?.format('DD MMM, YYYY')}</span>
                                                                            </span>
                                                                            <button className="btn font-semibold font-12 rating-btn text-nowrap"
                                                                                onClick={() => setFeedbackId(value?.service_request_uuid)}
                                                                            >
                                                                                Provide your Rating
                                                                            </button>
                                                                        </div>
                                                                        :
                                                                        <div className="d-flex align-items-center justify-content-between cust-padding cust-border-top flex-wrap gap-2">
                                                                            <span className="color-grey font-13 font-medium text-color-5">Completed Date: <span>{moment(value?.service_end_date)?.format('DD MMM, YYYY')}</span></span>
                                                                            {value?.feedback_rating === "5" ? <span className="font-semibold font-12 text-color-6 feedback-content">
                                                                                <img className="me-2" src="../images/lovely-emoji.svg" alt="lovely-emoji" />
                                                                                Very Satisfied
                                                                            </span> : value?.feedback_rating === "4" ? <span className="font-semibold font-12 text-color-6 feedback-content">
                                                                                <img className="me-2" src="../images/lovely-emoji.svg" alt="lovely-emoji" />
                                                                                Satisfied
                                                                            </span> : value?.feedback_rating === "3" ? <span className="font-semibold font-12 text-color-6 feedback-content">
                                                                                <img className="me-2" src="../images/lovely-emoji.svg" alt="lovely-emoji" />
                                                                                Neutral
                                                                            </span> : value?.feedback_rating === "2" ? <span className="font-semibold font-12 text-color-6 feedback-content">
                                                                                <img className="me-2" src="../images/lovely-emoji.svg" alt="lovely-emoji" />
                                                                                Dissatisfied
                                                                            </span> : value?.feedback_rating === "1" ? <span className="font-semibold font-12 text-color-6 feedback-content">
                                                                                <img className="me-2" src="../images/lovely-emoji.svg" alt="lovely-emoji" />
                                                                                Very Dissatisfied
                                                                            </span> : <span>None</span>}
                                                                        </div>}
                                                            </div>
                                                        </div> : <></>
                                    )) : <NoRecordFound />}
                            </div>
                        </div>
                    </div>
                </div>}
            {toastDetails?.isToast && <Toast props={
                {
                    isToast: toastDetails?.isToast,
                    setToastDetails: setToastDetails,
                    toastMessage: toastDetails?.toastMessage,
                    toastType: toastDetails?.toastType
                }
            } />}
            {isServiceBid && <ServiceBid props={{
                serviceBid: serviceBid,
                setIsServiceBid: setIsServiceBid,
                serviceBidAction: serviceBidAction
            }} />}

            {feedbackId && <Feedback feedbackDetails={feedbackDetails} setFeedbackDetails={setFeedbackDetails}
                feedbackId={feedbackId} setFeedbackId={setFeedbackId} sumbitFeedback={sumbitFeedback}
            />}

            {(isServiceBid || feedbackId) && <div className="modal-backdrop fade show"></div>}
        </>
    )
}

export default ServiceRequestGrid