import { client } from "../../Client/Client";
import { profileServices } from "../../Configs/BackendRoutes";
import {  backendUrl as URL } from "../../Configs/Constant";
import { APIResponseInterface, RetrieveProfileDataPayloadInterface } from "../../Interface/Profile";



export const getUserProfileDataService = async (data: RetrieveProfileDataPayloadInterface) => {
    const config = {
        method: 'POST',
        url: URL + profileServices.retrieveProfileData,
        headers: {
            'Content-Type': 'application/json'
        },
        data
    };
    const response : APIResponseInterface | undefined= await client(config);
    return response;
};
