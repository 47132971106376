const Loader = () => {
  
    return (
        <div className="vh-100 custom-loader">
            <div className="d-flex align-items-center overlay justify-content-center h-100">
                <div
                    id="loader"
                    className="loader"
                    role="alert"
                    aria-busy="true"
                    aria-label="Loading animation"
                ></div>
            </div>
        </div>
    );
};

export default Loader;