import React, { useEffect, useState } from 'react';

interface CountdownProps {
    startValue: number;
}

const Countdown: React.FC<CountdownProps> = ({ startValue }) => {
    const [count, setCount] = useState(startValue);

    useEffect(() => {
        if (count > 0) {
            const timerId = setTimeout(() => {
                setCount((prevCount) => prevCount - 1);
            }, 60000);

            return () => clearTimeout(timerId);
        }
    }, [count]);

    return (
        <>
            {count}
        </>
    );
};

export default Countdown;