import { client } from '../../Client/Client';
import { Endpoints } from '../../Configs/BackendRoutes';
import { backendUrl as URL } from '../../Configs/Constant';

// PS_CF_SU_04
export const getDynamicDropdownData = async () => {
    const config = {
        method: 'GET',
        url: URL + Endpoints.GetDropdown,
        headers: {
            'Content-Type': 'application/json'
        },
        data: null
    };
    const response = await client(config);
    return response;
};

// PS_CF_SU_56
export const getRegionData = async () => {
    const config = {
        method: 'GET',
        url: URL + Endpoints.GetRegion,
        headers: {
            'Content-Type': 'application/json'
        },
        data: null
    };
    const response = await client(config);
    return response;
};

// PS_CF_SU_45
export const VerifyMasterEmail = async (payload : any) => {
    const config = {
        method: 'POST',
        url: URL + Endpoints.PostVerifyEmail,
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };
    const response = await client(config);
    return response;
};

// PS_CF_SU_78 - 
export const SaveSignUp = async (payload : any) => {
    const config = {
        method: 'POST',
        url: URL + Endpoints.PostSignUp,
        // url: URL + Endpoints.PostSignUp,
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };
    const response = await client(config);
    return response;
};

