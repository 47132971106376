/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import {
  APIResponseInterface,
  CustomerProfileInterface,
  EditProfileInterface,
  EditProfileValidationErrorInterface,
  industry,
  RetrieveProfileDataPayloadInterface,
  ToastInterface,
} from "../../Interface/Profile";
import { State, City, ICity, IState } from "country-state-city";
import { useNavigate } from "react-router-dom";
import {
  getUserProfileDataService
} from "../../Service/Profile/ProfileService";
import Toast from "../../Components/Toast";
// import ChangePasswordPopUp from "./ChangePasswordPopUp";
import Loader from "../Loader";
import {
  isRegexValidMobile,
  ppsContext,
  usPhoneNumberFormat,
} from "../../Configs/Constant";
import { contextValueInterface } from "../../Interface/HeaderDetails";
import ChangePassword from "../Login/ChangePassword";
import { getDynamicDropdownData, SaveSignUp } from "../../Service/Login/SignUpService";
// import ConfirmationPopUp from "./ConfirmationPopUp";

const Profile = () => {
  const contextValue: contextValueInterface = useContext(ppsContext);

  const initialToastDetails: ToastInterface = {
    isToast: false,
    toastDescription: "",
    toastMessage: "",
    toastType: "",
  };

  const initialEditProfileData: EditProfileInterface = {
    address_line_1: "",
    address_line_2: "",
    city: "",
    doing_business_as: "",
    email_id: "",
    first_name: "",
    industry: "",
    industry_name: "",
    last_name: "",
    phone_no: "",
    state: "",
    user_id: "",
    zip_code: "",
  };
  const initialErrorMessage: EditProfileValidationErrorInterface = {
    address_line_1: "",
    address_line_2: "",
    city: "",
    doing_business_as: "",
    email_id: "",
    first_name: "",
    industry: "",
    industry_name: "",
    last_name: "",
    phone_no: "",
    state: "",
    zip_code: "",
  };

  const [toastDetails, setToastDetails] =
    useState<ToastInterface>(initialToastDetails);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [citiesList, setCitiesList] = useState<ICity[]>([]);
  const [states, setStates] = useState<IState[]>([]);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  // const [showSavePopUp, setShowSavePopUp] = useState<boolean>(false);

  const [showPasswordChangePopUp, setShowPasswordChangePopUp] =
    useState<boolean>(false);

  const [editProfileData, setEditProfileData] = useState<EditProfileInterface>(
    initialEditProfileData
  );

  const [industryDropdownData, setIndustryDropdownData] = useState<industry[]>(
    [] as industry[]
  );

  const [isOtherIndustrySIte, SetisOtherIndustrySIte] = useState(false);

  // const [retrieveProfileDataPayload, setRetrieveProfileDataPayload] =
  //   useState<RetrieveProfileDataPayloadInterface>(
  //     {} as RetrieveProfileDataPayloadInterface
  //   );
  // const [profileData, setProfileData] = useState<ProfileDataInterface>({} as ProfileDataInterface)

  const [customerProfileData, setCustomerProfileData] =
    useState<CustomerProfileInterface>({} as CustomerProfileInterface);

  const navigate = useNavigate();
  const [error, setError] =
    useState<EditProfileValidationErrorInterface>(initialErrorMessage);
  const [retrieveProfileAPI, setRetrieveProfileAPI] = useState<boolean>(false)

  useEffect(() => {
    fetchUserProfileData();
    if (industryDropdownData.length < 0) {
      fetchDropdownData();
    }
    const statesData = State?.getStatesOfCountry("US");
    setStates(statesData);
    if (editProfileData.state !== "") {
      const citiesList = City.getCitiesOfState("US", customerProfileData?.state);
      setCitiesList(citiesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editProfileData.state]);

  useEffect(() => {
    if (isEdit) {
      mapProfileData();
      setError(initialErrorMessage);
      const statesData = State?.getStatesOfCountry("US");
      setStates(statesData);
      const citiesList = City.getCitiesOfState("US", editProfileData.state);
      setCitiesList(citiesList);
    } else {
      setError(initialErrorMessage);
    }
    if (retrieveProfileAPI) {
      fetchUserProfileData();
      if (industryDropdownData.length < 0) {
        fetchDropdownData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, retrieveProfileAPI]);

  const fetchUserProfileData = async () => {
    setIsLoader(true);
    try {
      const response: APIResponseInterface | undefined =
        await getUserProfileDataService({
          type: "customer",
          profile_uuid: contextValue?.userDetails?.user_uuid,
        } as RetrieveProfileDataPayloadInterface);
      setIsLoader(false);
      if (response !== undefined && response?.status === "Success") {
        if (response?.data?.customer_profile_data !== null) {
          setCustomerProfileData(response?.data?.customer_profile_data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRetrieveProfileAPI(false)
    }
  };

  const fetchDropdownData = async () => {
    setIsLoader(true);
    try {
      const response = await getDynamicDropdownData();
      setIsLoader(false);
      if (response?.code === 200) {
        setIndustryDropdownData(response?.data?.industry);
      } else {
        console.error("Error fetching dynamic data");
        console.error("Error saving form data");
        // setShowToastProp({ type: "Error", title: "Failed to fatch Indusry data", message: "Failed to fatch Indusry data" })
        // setShowToast(true)
      }
    } catch (error: any) {
      console.error(error.message);
      console.error("Error saving form data");
      // setShowToastProp({ type: "Error", title: "Failed to fatch Indusry data", message: "Failed to fatch Indusry data" })
      // setShowToast(true)
    } finally {
      // setShowLoader(false)
      // setTimeout(() => {
      //     setShowToast(false);
      // }, 4000);
      setRetrieveProfileAPI(false)
    }
  };

  const mapProfileData = () => {
    setEditProfileData((prevState) => ({
      ...prevState,
      address_line_1: customerProfileData.address_line_one,
      address_line_2: customerProfileData.address_line_two,
      city: customerProfileData.city,
      doing_business_as: customerProfileData.customer_dba,
      email_id: customerProfileData.business_email,
      first_name: customerProfileData.first_name,
      industry: customerProfileData.industry_uuid,
      industry_name: customerProfileData.other_industry,
      last_name: customerProfileData.last_name,
      phone_no: customerProfileData.phone_number,
      state: customerProfileData.state,
      user_id: customerProfileData.customer_uuid,
      zip_code: customerProfileData.zip_code,
    }));
  };

  const handleInputChange = (e: any) => {
    let field: string = e.target.name;
    let value: string = e.target.value;
    // setError((prevState)=>({...prevState, first_name: "updated"}))
    if (field === "state") {
      setEditProfileData((prevState) => ({ ...prevState, [field]: value }));
      const citiesList = City.getCitiesOfState("US", value);
      setCitiesList(citiesList);
    } else if (field === "industry") {
      let industryid = industryDropdownData?.find(
        (ele) => ele?.industry_uuid === value
      );
      setEditProfileData((prevState) => ({ ...prevState, industry: value }));
      if (industryid?.industry_name.toLowerCase() === "other") {
        SetisOtherIndustrySIte(true);
      } else {
        SetisOtherIndustrySIte(false);
        setEditProfileData((prevState) => ({
          ...prevState,
          industry_name: "",
        }));
      }
    } else if (field === "industry_name") {
      setEditProfileData((prevState) => ({
        ...prevState,
        industry_name: value,
      }));

      if (value !== "" && !/^[a-zA-Z]+$/.test(value)) {
        setError({
          ...error,
          industry_name: `Please Enter the valid industry name`,
        });
      } else {
        setError({
          ...error,
          industry_name: "",
        });
      }
    } else {
      setEditProfileData((prevState) => ({ ...prevState, [field]: value }));
    }
  };

  const handleValidation = (field: string, value: string) => {
    let valid = false;

    //check all fields
    // console.log(presentIn, value, field, "fieldddd");

    switch (field) {
      case "first_name":
      case "last_name":
        if (!/^[a-zA-Z]+$/.test(value) || "") {
          valid = false;
          setError({
            ...error,
            [field]: `Please Enter the ${field === "first_name" ? "First" : "Last"
              } Name`,
          });
        } else {
          valid = true;
          setError({ ...error, [field]: "" });
        }
        break;

      case "zip_code":
        if (!/^\d{5,10}$/.test(value) || "") {
          valid = false;
          setError({ ...error, zip_code: "Please Enter the Zip code" });
        } else {
          valid = true;
          setError({ ...error, zip_code: "" });
        }
        break;
      case "doing_business_as":
        if (value === "") {
          valid = false;
          setError({
            ...error,
            doing_business_as: "Please Enter doing business as",
          });
        } else {
          valid = true;
          setError({ ...error, doing_business_as: "" });
        }
        break;
      case "address_line_1":
        if (value === "") {
          valid = false;
          setError({ ...error, address_line_1: "Please Enter Address line 1" });
        } else {
          valid = true;
          setError({ ...error, address_line_1: "" });
        }
        break;
      case "address_line_2":
        if (value === "") {
          valid = false;
          setError({ ...error, address_line_2: "Please Enter address line 2" });
        } else {
          valid = true;
          setError({ ...error, address_line_2: "" });
        }
        break;
      case "state":
        if (value === "") {
          valid = false;
          setError({ ...error, state: "Please Enter the State" });
        } else {
          valid = true;
          setError({ ...error, state: "" });
        }
        break;
      case "city":
        if (value === "") {
          valid = false;
          setError({ ...error, city: "Please Enter the city" });
        } else {
          valid = true;
          setError({ ...error, city: "" });
        }
        break;
      case "industry":
        if (value === "") {
          valid = false;
          setError({ ...error, industry: "Please Enter the industry" });
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          valid = true;
          setError({ ...error, industry: "" });
        }
        break;
      default:
        setError({ ...error, [field]: "" });
    }
  };

  const handleSave = async () => {
    if (!Object.values(error).find((e) => e !== "")) {
      setIsLoader(true);
      try {
        setIsEdit(false);
        const response = await SaveSignUp({
          type: "customer",
          mode: "update",
          data: editProfileData,
        });
        setIsLoader(false);
        if (response?.status?.code === 200) {
          if (response?.status?.toLowerCase() === "success") {
            setToastDetails((prevState) => ({
              ...prevState,
              isToast: true,
              toastDescription: "Profile Data Updated Successfully",
              toastMessage: "Profile Updated",
              toastType: "success",
            }));
          }
        } else {
          if (response?.status?.toLowerCase() === "error") {
            setToastDetails((prevState) => ({
              ...prevState,
              isToast: true,
              toastDescription: "Failed to update the profile",
              toastMessage: "Profile Updated Failed",
              toastType: "error",
            }));
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setRetrieveProfileAPI(true)
      }
    }
  };

  const handleLogoutClick = () => {
    // Cookies.remove('authToken'); // Replace 'authToken' with the actual cookie name you want to remove
    window.location.href = "/"; // Redirect to the login page
  };

  // const handleSavePopUp: (confirmation: string) => void = (
  //   confirmation: string
  // ) => {
  //   if (confirmation === "close" || confirmation === "no") {
  //     setShowSavePopUp(false);
  //   } else if (confirmation === "yes") {
  //     handleSave();
  //     setShowSavePopUp(false);
  //   }
  // };

  const bindIndustryDropdown = () => {
    return industryDropdownData?.map((data) => {
      return (
        <>
          <option
            value={data?.industry_uuid}
            className="font-semibold font-15 primary-text-color mb-2"
          >
            {data?.industry_name}
          </option>
        </>
      );
    });
  };

  const bindStateDropdown = () => {
    return states.map((state: any) => {
      return (
        <>
          <option
            key={state.isoCode}
            value={state.isoCode}
            className="font-semibold font-15 primary-text-color mb-2"
          >
            {state.name}
          </option>
        </>
      );
    });
  };

  const bindCityDropdown = () => {
    return citiesList?.map((city: ICity) => {
      return (
        <option
          key={city.name}
          value={city.name}
          className="font-semibold font-15 primary-text-color mb-2"
        >
          {city.name}
        </option>
      );
    });
  };

  // const onClickLogout = () => {
  //   sessionStorage.removeItem("login");
  //   navigate("/signup");
  // };

  // const handleOnCloseToast = () => {
  //   setShowToast(false);
  //   setToastDetails((prevState: ToastInterface) => ({
  //     messaage: "",
  //     title: "",
  //   }));
  // };

  return isLoader ? (
    <Loader />
  ) : (
    <div className="container-fluid p-4 position-relative h-100">
      {toastDetails?.isToast && (
        <Toast
          props={{
            isToast: toastDetails?.isToast,
            setToastDetails: setToastDetails,
            toastMessage: toastDetails?.toastMessage,
            toastType: toastDetails?.toastType,
            toastDescription: toastDetails?.toastDescription,
          }}
        />
      )}
      {showPasswordChangePopUp && (
        <ChangePassword
          setShowPasswordChangePopUp={setShowPasswordChangePopUp}
          setToastDetails={setToastDetails}
        />
      )}
      <div className="d-flex align-items-center gap-3 mt-4">
        <a
          onClick={() => {
            if (isEdit) {
              setIsEdit(false);
            } else {
              navigate(-1);
            }
          }}
          className="icon-round-btn"
        >
          <img
            className="icon-sty"
            src="../images/back-arrow.svg"
            alt="back-arrow"
          />
        </a>
        <h2 className="color-black font-22 font-semibold mb-0">
          {isEdit ? "Edit your Profile" : "User Profile"}
        </h2>
      </div>
      {!isEdit ? (
        <div className="px-md-5 px-md-3 mx-md-3 mb-4">
          <div className="row d-flex align-items-center justify-content-center h-100  position-relative ">
            <div className="col-lg-7 col-sm-9 py-5">
              <div className="position-relative">
                <div
                  className="creation-container p-4 p-md-5 position-relative user-profile"
                  style={{ zIndex: "900" }}
                >
                  <div className="row">
                    <div className="col-md-6  mb-4 mb-md-5">
                      <label className="font-semibold font-15 primary-text-color mb-2">
                        Full Name
                      </label>
                      <p className="mb-0 font-15 font-medium primary-text-color">
                        {/* Joanna Clark */}
                        {customerProfileData?.first_name +
                          " " +
                          customerProfileData?.last_name}
                      </p>
                    </div>
                    <div className="col-md-6  mb-4 mb-md-5">
                      <label className="font-semibold font-15 primary-text-color mb-2">
                        DBA
                      </label>
                      <p className="mb-0 font-15 font-medium primary-text-color">
                        {/* ABC Event Planners */}
                        {customerProfileData?.customer_dba}
                      </p>
                    </div>
                    <div className="col-md-6  mb-4 mb-md-5">
                      <label className="font-semibold font-15 primary-text-color mb-2">
                        Industry
                      </label>
                      <p className="mb-0 font-15 font-medium primary-text-color">
                        {/* Event Management */}
                        {customerProfileData?.industry_name === ""
                          ? customerProfileData?.other_industry
                          : customerProfileData?.industry_name}
                      </p>
                    </div>
                    <div className="col-md-6  mb-4 mb-md-5">
                      <label className="font-semibold font-15 primary-text-color mb-2">
                        Email Address
                      </label>
                      <p className="mb-0 font-15 font-medium primary-text-color">
                        {/* joanna.c@google.com */}
                        {customerProfileData?.business_email}
                      </p>
                    </div>
                    <div className="col-md-6  mb-4 mb-md-5">
                      <label className="font-semibold font-15 primary-text-color mb-2">
                        Phone #
                      </label>
                      <p className="mb-0 font-15 font-medium primary-text-color">
                        {/* +1 839 728 9238 */}
                        {/* {customerProfileData?.phone_number &&
              formatPhoneNumber(customerProfileData?.phone_number)} */}
                        {/* {customerProfileData?.phone_number} */}
                        {customerProfileData?.phone_number !== ""
                          ? isRegexValidMobile(customerProfileData?.phone_number)
                            ? customerProfileData?.phone_number
                            : usPhoneNumberFormat(
                              customerProfileData?.phone_number
                            )
                          : "-"}
                      </p>
                    </div>
                    <h3 className="font-16 font-semibold color-black mb-4">
                      Address Details
                    </h3>
                    <div className="col-sm-6 mb-3 mb-md-5">
                      <label className="font-semibold font-15 primary-text-color mb-2">
                        Address Line 1
                      </label>
                      <p className="mb-0 font-15 font-medium primary-text-color">
                        {/* 1234 Oak Drive */}
                        {customerProfileData?.address_line_one}
                      </p>
                    </div>
                    <div className="col-sm-6 mb-3 mb-md-5">
                      <label className="font-semibold font-15 primary-text-color mb-2">
                        Address Line 2
                      </label>
                      <p className="mb-0 font-15 font-medium primary-text-color">
                        {/* 2nd Midway Sides */}
                        {customerProfileData?.address_line_two}
                      </p>
                    </div>
                    {/* <div class="row justify-content-md-between justify-content-center mb-md-5 mb-3"> */}
                    <div className="col-md-4 col-sm-6 mb-3 mb-md-5">
                      <label className="font-semibold font-15 primary-text-color mb-2">
                        City
                      </label>
                      <p className="mb-0 font-15 font-medium primary-text-color">
                        {/* Metropolis */}
                        {customerProfileData?.city}
                      </p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3 mb-md-5">
                      <label className="font-semibold font-15 primary-text-color mb-2">
                        State
                      </label>
                      <p className="mb-0 font-15 font-medium primary-text-color">
                        {/* NY */}
                        {customerProfileData?.state}
                      </p>
                    </div>
                    <div className="col-md-3 col-12 mb-3 mb-md-5">
                      <label className="font-semibold font-15 primary-text-color mb-2 text-nowrap">
                        Zip Code
                      </label>
                      <p className="mb-0 font-15 font-medium primary-text-color">
                        {/* 29920 */}
                        {customerProfileData?.zip_code}
                      </p>
                    </div>
                    {/* </div> */}
                    <div className="col-md-6 mb-lg-5 mb-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          className="text-nowrap btn action-outline-btn font-13 font-medium color-black d-flex align-items-center justify-content-center gap-2 px-3 px-lg-4"
                          onClick={() => {
                            setIsEdit(true);
                          }}
                        >
                          <img
                            className="user-action-icon"
                            src="../images/edit-profile.svg"
                            alt="edit"
                          />
                          Edit Profile Info
                        </button>
                      </div>
                    </div>
                    <div className="col-md-6 mb-lg-5 mb-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          className="text-nowrap btn action-outline-btn font-13 font-medium color-black d-flex align-items-center justify-content-center gap-2 px-3 px-lg-4"
                          onClick={() => {
                            setShowPasswordChangePopUp(true);
                            console.log(showPasswordChangePopUp);
                          }}
                        >
                          <img
                            className="user-action-icon"
                            src="../images/password.svg"
                            alt="password"
                          />
                          Change Password
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          className="text-nowrap btn px-5 logout-btn font-13 font-medium color-black d-flex align-items-center justify-content-center gap-2"
                          onClick={() => {
                            handleLogoutClick();
                          }}
                        >
                          <img
                            className="user-action-icon"
                            src="../images/logout-icon.svg"
                            alt="Logout"
                          />
                          Logout
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="creation-gradient-container"></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="px-md-5 px-md-3 mx-md-3">
          <div className="row d-flex align-items-center justify-content-center h-100  position-relative ">
            <div className="col-lg-7 col-sm-9 py-5 mb-4 mb-md-0">
              <div className="position-relative">
                <div
                  className="creation-container p-4 px-md-5 pb-4 pt-5 position-relative user-profile"
                  style={{ zIndex: "900" }}
                >
                  <div className="row">
                    <div className="col-md-6  mb-4">
                      <div className=" mb-1">
                        <label
                          htmlFor="FirstName"
                          className="font-semibold font-15 primary-text-color mb-2"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          id="FirstName"
                          name="first_name"
                          className="form-control service-form-field bg-transparent py-2 color-black-v1 font-15 font-medium"
                          placeholder="Enter First Name"
                          value={editProfileData.first_name}
                          onChange={(e) => {
                            handleInputChange(e);
                            handleValidation(e.target.name, e.target.value);
                          }}
                        />
                        {error.first_name !== "" && (
                          <span className="font-14 font-medium alert-text">
                            {error.first_name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6  mb-4">
                      <div className=" mb-1">
                        <label
                          htmlFor="LastName"
                          className="font-semibold font-15 primary-text-color mb-2"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="LastName"
                          name="last_name"
                          className="form-control service-form-field bg-transparent py-2 color-black-v1 font-15 font-medium"
                          placeholder="Enter Last Name"
                          value={editProfileData.last_name}
                          onChange={(e) => {
                            handleInputChange(e);
                            handleValidation(e.target.name, e.target.value);
                          }}
                        />
                        {error.last_name !== "" && (
                          <span className="font-14 font-medium alert-text">
                            {error.last_name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6  mb-4">
                      <div className=" mb-1">
                        <label
                          htmlFor="business"
                          className="font-semibold font-15 primary-text-color mb-2"
                        >
                          DBA
                        </label>
                        <input
                          type="text"
                          id="business"
                          name="doing_business_as"
                          value={editProfileData.doing_business_as}
                          className="form-control service-form-field bg-transparent py-2 color-black-v1 font-15 font-medium"
                          placeholder="Enter DBA"
                          onChange={(e) => {
                            handleInputChange(e);
                            handleValidation(e.target.name, e.target.value);
                          }}
                        />
                        {error.doing_business_as !== "" && (
                          <span className="font-14 font-medium alert-text">
                            {error.doing_business_as}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6  mb-4">
                      <div className=" mb-1">
                        <label
                          htmlFor="industry"
                          className="font-semibold font-15 primary-text-color mb-2"
                        >
                          Industry
                        </label>
                        <select
                          id="Industry3"
                          // className="w-100 d-block form-select custom-form-fields critical-field color-black-v1 font-14 font-medium cust-form-arrow"
                          // className="font-semibold font-15 primary-text-color mb-2"
                          className={`w-100 d-block form-select custom-form-fields primary-text-color font-14 font-medium`}
                          name="industry"
                          value={editProfileData.industry}
                          onChange={(e: any) => {
                            handleInputChange(e);
                            handleValidation(e.target.name, e.target.value);
                          }}
                        >
                          <option
                            value=""
                            className="font-semibold font-15 primary-text-color mb-2"
                          >
                            Select
                          </option>
                          {bindIndustryDropdown()}
                        </select>
                        {/* <span className="font-14 font-medium alert-text">
                                                Please select the industry type
                                            </span> */}
                        {error.industry !== "" && (
                          <span className="font-14 font-medium alert-text">
                            {error.industry}
                          </span>
                        )}
                      </div>
                    </div>
                    {(isOtherIndustrySIte ||
                      editProfileData?.industry_name !== "") && (
                        <div className="col-lg-6 mb-4">
                          <label
                            htmlFor="IndustryName"
                            className="font-semibold font-15 primary-text-color mb-2"
                          >
                            Other Industry
                          </label>
                          <input
                            type="text"
                            id="IndustryName"
                            // className="form-control custom-form-fields  color-black-v1 font-14 font-medium"
                            className={`form-control custom-form-fields color-black-v1 font-14 font-medium`}
                            name="industry_name"
                            value={editProfileData.industry_name}
                            placeholder="Enter your Industry"
                            onChange={(e: any) => {
                              handleInputChange(e);
                              handleValidation(e.target.name, e.target.value);
                            }}
                          />
                          {error.industry_name !== "" && (
                            <span className="font-14 font-medium alert-text">
                              {error.industry_name}
                            </span>
                          )}
                        </div>
                      )}
                    <div className="col-md-6  mb-4">
                      <div className=" mb-1">
                        <label
                          htmlFor="email"
                          className="font-semibold font-15 primary-text-color mb-2"
                        >
                          Email Address
                        </label>
                        <input
                          type="email"
                          id="email"
                          className="form-control service-form-field bg-transparent py-2 color-black-v1 font-15 font-medium"
                          placeholder="Enter Email Address"
                          value={editProfileData.email_id}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6  mb-4">
                      <div className="mb-1">
                        <label
                          htmlFor="phone"
                          className="font-semibold font-15 primary-text-color mb-2"
                        >
                          Phone #
                        </label>
                        <input
                          type="text"
                          id="phone"
                          className="form-control service-form-field bg-transparent py-2 color-black-v1 font-15 font-medium"
                          placeholder="Enter Phone #"
                          value={editProfileData?.phone_no !== ""
                            ? isRegexValidMobile(editProfileData?.phone_no)
                              ? editProfileData?.phone_no
                              : usPhoneNumberFormat(
                                editProfileData?.phone_no
                              )
                            : editProfileData?.phone_no}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <h3 className="font-16 font-semibold color-black mb-4">
                      Address Details
                    </h3>
                    <div className="col-sm-6 mb-4">
                      <div className=" mb-1">
                        <label
                          htmlFor="Address1"
                          className="font-14 font-semibold primary-text-color mb-2"
                        >
                          Address Line 1
                        </label>
                        <input
                          type="text"
                          id="Address1"
                          name="address_line_1"
                          className="form-control service-form-field bg-transparent py-2 color-black-v1 font-15 font-medium"
                          placeholder="Enter Address Line 1"
                          value={editProfileData.address_line_1}
                          onChange={(e: any) => {
                            handleInputChange(e);
                            handleValidation(e.target.name, e.target.value);
                          }}
                        />
                        {error.address_line_1 !== "" && (
                          <span className="font-14 font-medium alert-text">
                            {error.address_line_1}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 mb-4">
                      <div className="mb-1">
                        <label
                          htmlFor="Address2"
                          className="font-semibold font-15 primary-text-color mb-2"
                        >
                          Address Line 2
                        </label>
                        <input
                          type="text"
                          id="Address2"
                          className="form-control service-form-field bg-transparent py-2 color-black-v1 font-15 font-medium"
                          placeholder="Enter Address Line 2"
                          name="address_line_2"
                          onChange={(e: any) => {
                            handleInputChange(e);
                            handleValidation(e.target.name, e.target.value);
                          }}
                          value={editProfileData?.address_line_2}
                        />
                        {error.address_line_1 !== "" && (
                          <span className="font-14 font-medium alert-text">
                            {error.address_line_2}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-12 mb-4">
                      <div className="mb-md-0 ">
                        <label
                          htmlFor="zip"
                          className="font-semibold font-15 primary-text-color mb-2 text-nowrap"
                        >
                          Zip Code
                        </label>
                        <input
                          type="text"
                          id="zip"
                          className="form-control service-form-field bg-transparent py-2 color-black-v1 font-15 font-medium"
                          placeholder="Enter Zip Code"
                          name="zip_code"
                          value={editProfileData.zip_code}
                          onChange={(e: any) => {
                            handleInputChange(e);
                            handleValidation(e.target.name, e.target.value);
                          }}
                        />
                        {error.zip_code !== "" && (
                          <span className="font-14 font-medium alert-text">
                            {error.zip_code}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                      <div className=" mb-md-0">
                        <label
                          htmlFor="City"
                          className="font-semibold font-15 primary-text-color mb-2"
                        >
                          City
                        </label>
                        <select
                          name="city"
                          id="City"
                          value={editProfileData?.city}
                          disabled={
                            citiesList?.length > 0 || editProfileData?.city !== ""
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            handleInputChange(e);
                            handleValidation(e.target.name, e.target.value);
                          }}
                          className="w-100 d-block form-select service-form-field cust-form-arrow bg-transparent py-2 color-black-v1 font-14 font-regular"
                        >
                          <option value="">Select</option>
                          {citiesList?.length > 0 && bindCityDropdown()}
                        </select>
                        {error.city !== "" && (
                          <span className="font-14 font-medium alert-text">
                            {error.city}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                      <div className=" mb-md-0 ">
                        <label
                          htmlFor="state"
                          className="font-semibold font-15 primary-text-color mb-2"
                        >
                          State
                        </label>
                        <select
                          name="state"
                          value={editProfileData?.state}
                          id="state"
                          className="w-100 d-block form-select service-form-field cust-form-arrow bg-transparent py-2 color-black-v1 font-14 font-regular"
                          onChange={(e: any) => {
                            handleInputChange(e);
                            handleValidation(e.target.name, e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          {bindStateDropdown()}
                        </select>
                        {error.state !== "" && (
                          <span className="font-14 font-medium alert-text">
                            {error.state}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-2 border-0 pt-5 mt-3 justify-content-end">
                      <button
                        className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                        onClick={() => {
                          setEditProfileData(initialEditProfileData);
                          setIsEdit(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal3"
                        onClick={() => {
                          handleSave();
                          setEditProfileData(initialEditProfileData);
                          setIsEdit(false);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
                <div className="creation-gradient-container"></div>
              </div>
            </div>
          </div>
        </div>
      )}
      <p className="color-grey-v1 font-semibold font-15 text-center position-absolute w-100 helpline mt-2">
        Reach our command center 24/7 at:{" "}
        <a href="tel:+1234567890" className="link cust-text-underline">
          1-800-775-0805
        </a>{" "}
        |{" "}
        <a
          href="mailto:example@example.com"
          className="link cust-text-underline"
        >
          command@ppsinctn.com
        </a>
      </p>
    </div>
  );
};

export default Profile;
