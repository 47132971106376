import { ProfileServicesInterface } from "../Interface/Profile"


export const serviceRequestServices = {
    serviceRequestDynamicData: "/v1/api/protect/service-request-dynamic-data",
    createServiceRequest: "/v1/api/protect/create-service-request",
    updateServiceRequest: "/v1/api/protect/update-service-request",
    manageServiceRequest: "/v1/api/protect/manage-service-request",
    getServiceBid: "/v1/api/protect/retrieve-service-bid",
    ModifyServiceBid: "/v1/api/protect/modify-service-bid",
    getServiceRequest: "/v1/api/protect/service-request",
    submitFeedback: "/v1/api/protect/post-feedback",
    fetchSingleServiceRequest: "/v1/api/protect/retrieve-single-service"
}

export const loginService = {
    signup: '/signup',
    newPassword: '/newPassword'
}


export const Endpoints = {
    GetDropdown: "/v1/api/login/dropdowns/client",
    GetRegion: "/v1/api/login/region",
    PostVerifyEmail: "/v1/api/login/verify-email",
    PostSignUp: "/v1/api/login/sign-up",
}

export const getHeaderDetails={
      getHeaderDet:"/v1/api/admin/retrieve-user-details",
    readNotifications:"/v1/api/login/read-notifications"
}


export const profileServices : ProfileServicesInterface={
    retrieveProfileData: "/v1/api/admin/retrieve-profile-details",
}


