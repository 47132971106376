/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
    Notifications,
    readPayload,
} from "../../Interface/HeaderDetails";
import {
    readNotifications,
} from "../../Service/HeaderDetails/HeaderDetail";
import { useNavigate } from "react-router-dom";
import WebsocketClient from '../../Client/WebSocket';

import moment from "moment-timezone";
import { ppsContext } from '../../Configs/Constant';
import { contextValueInterface } from '../../Interface/HeaderDetails';

import ContactPopUp from '../Popups/Popupcomponent';

export const HeaderComponent: React.FC = () => {
    const websocket = useRef<WebSocket>();
    const navigate = useNavigate();
    const contextValue: contextValueInterface = useContext(ppsContext)
    const [showNotifications, setShowNotifications] = useState<boolean>(false);
    const [showProfile, setshowProfile] = useState<boolean>(false);
    const [showKeyContacts, setShowKeyContacts] = useState<boolean>(false);
    const regionalManagerDetails = useState({ RMPhone: contextValue.userDetails.rmPhone, RMEmail: contextValue.userDetails.rmEmail })

    useEffect(() => {

        const socket = WebsocketClient(contextValue.userDetails.user_uuid, []);
        console.log("socketRes", socket)
        websocket.current = socket;
        socket.onmessage = handleOnMessage;
        socket.onerror = handleOnError;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextValue]);
    const playNotificationSound = () => {
        const notificationSound = new Audio('../images/notification_sound.mp3');
        notificationSound.play();
    };
    const handleOnMessage = useCallback(async (event: any) => {
        try {
            const receivedMessage = JSON.parse(event.data);
            console.log("socketmessage", receivedMessage)
            if (receivedMessage?.data?.notification_details) {
                const newNotification = receivedMessage?.data?.notification_details[0];
                console.log("Previous Noti", contextValue.notifications)
                console.log("New Noti", newNotification)

                contextValue.setNotifications((prevNotifications: Notifications[]) => {
                    return [
                        newNotification,
                        ...prevNotifications,
                    ]
                })
                contextValue.setNotificationsCount((prevCount: number) => {
                    return prevCount + receivedMessage?.data?.unread_notifications_count
                })
                playNotificationSound()
                // Show a desktop notification
                //  await showNotification("New Notification", {
                //   body: receivedMessage?.data?.notification_details[0].service_request,
                //   icon: "path/to/icon.png", // Optional: replace with your icon path

                // });
            }
        } catch (error: any) {
            console.log("an error occurred: ", error);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextValue.notifications]
    );

    const handleOnError = useCallback((error: any) => {
        console.log("an error occured in websocket:", error);
    }, []);

    //PC_34 User clicks on a single notification to mark as read
    const handleNotificationIconClick = (val: any, ind: number) => {
        let payload: readPayload = {
            is_read: !val.is_read,
            notification_uuid: val.notification_uuid,
        };
        if (!val.is_read) {
            try {
                //PC_39 Call readNotification with single notification UUID
                let response = readNotifications(payload);
                console.log(response)
            } catch (error) {
                console.log("Error Occured While Marking Notification as read", error);
            }
        }
        //PC_36 update the clicked notification as read
        const updateNotifications = [...contextValue.notifications];
        updateNotifications[ind].is_read = true;
        contextValue.setNotifications(updateNotifications);
        contextValue.setNotificationsCount((prevCount: number) => {
            return prevCount - 1
        })
        if (val.notification_type !== "contractorDeletesInvoice") {
            navigate(val.notification_path);
        }
    };

    const handleLogoutClick = () => {
        // Cookies.remove('authToken'); // Replace 'authToken' with the actual cookie name you want to remove
        window.location.href = "/" // Redirect to the login page
    };

    const DateDifference = (dateString: string) => {
        // Parse the input date string
        const inputDate = moment(dateString);

        // Get the current date and time in US timezone
        const currentDate = moment().tz("America/New_York");

        // Calculate the difference in hours
        const differenceInHours = currentDate.diff(inputDate, "hours");

        return differenceInHours >= 1
            ? differenceInHours === 1
                ? differenceInHours + " hour ago . "
                : differenceInHours + " hours ago . "
            : "few mins ago . ";
    };

    const bindNotifications = () => {
        return contextValue.notifications.map((val: any, index: number) => {
            const timeAgos = DateDifference(val.created_date)
            return (val.notification_type === "AdminConfirmBidPPSOwned" ?
                <li className="cust-border-bottom unread show" key={val.notification_uuid}>
                    <span className={val.is_read ? "" : "unread-msg"} />
                    <a href="#" className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
                        <img className="ms-2 notification-icon-sty" src="../images/work-icon.svg" alt="work-icon" />
                        <div className="d-block" onClick={() => { handleNotificationIconClick(val, index) }} >
                            <span className="font-14 font-medium color-grey-v4 d-block">Quoted price has been submitted for your <span className="font-bold color-black">{val.service_request}</span> job request.</span>
                            <span className="font-13 font-regular color-grey-v3">{timeAgos} <span>Bids</span></span>
                        </div>
                    </a>
                </li> :
                val.notification_type === "AdminConfirmBidContOwned" ?
                    <li className="cust-border-bottom unread show" key={val.notification_uuid}>
                        <span className={val.is_read ? "" : "unread-msg"} />
                        <a href="#" className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
                            <img className="ms-2 notification-icon-sty" src="../images/work-icon.svg" alt="work-icon" />
                            <div className="d-block" onClick={() => { handleNotificationIconClick(val, index) }}>
                                <span className="font-14 font-medium color-grey-v4 d-block">Quoted price has been submitted for your <span className="font-bold color-black">{val.service_request}</span> job request.</span>
                                <span className="font-13 font-regular color-grey-v3">{timeAgos} <span>Bids</span></span>
                            </div>
                        </a>
                    </li> :
                    val.notification_type === "contractorAcceptsExtendedDateSR" ?
                        <li className="cust-border-bottom unread show" key={val.notification_uuid}>
                            <span className={val.is_read ? "" : "unread-msg"} />
                            <a href="#" className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
                                <img className="ms-2 notification-icon-sty" src="../images/work-icon.svg" alt="work-icon" />
                                <div className="d-block" onClick={() => { handleNotificationIconClick(val, index) }}>
                                    <span className="font-14 font-medium color-grey-v4 d-block">Your extended job request for <span className="font-bold color-black">{val.service_request}</span> has been accepted by <span className="font-bold color-black">{val.contractor_name}</span></span>
                                    <span className="font-13 font-regular color-grey-v3">{timeAgos} <span>Bids</span></span>
                                </div>
                            </a>
                        </li> :
                        val.notification_type === "NewExtendedActiveJobAcceptedByAnyOneContractor" ? <li className="cust-border-bottom unread show" key={val.notification_uuid}>
                            <span className={val.is_read ? "" : "unread-msg"} />
                            <a href="#" className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
                                <img className="ms-2 notification-icon-sty" src="../images/work-icon.svg" alt="work-icon" />
                                <div className="d-block" onClick={() => { handleNotificationIconClick(val, index) }}>
                                    <span className="font-14 font-medium color-grey-v4 d-block">Your extended job request for <span className="font-bold color-black">{val.service_request}</span> has been accepted</span>
                                    <span className="font-13 font-regular color-grey-v3">{timeAgos} <span>Bids</span></span>
                                </div>
                            </a>
                        </li> :
                            val.notification_type === "SRComesUnderClosed" ? <li className="cust-border-bottom unread show" key={val.notification_uuid}>
                                <span className={val.is_read ? "" : "unread-msg"} />
                                <a href="#" className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
                                    <img className="ms-2 notification-icon-sty" src="../images/work-icon.svg" alt="work-icon" />
                                    <div className="d-block" onClick={() => { handleNotificationIconClick(val, index) }}>
                                        <span className="font-14 font-medium color-grey-v4 d-block"><span className="font-bold color-black">{val.service_request}</span> mission accomplished! How'd we do? Drop us a quick review! </span>
                                        <span className="font-13 font-regular color-grey-v3">{timeAgos} <span>Bids</span></span>
                                    </div>
                                </a>
                            </li> : null
            )
        })
    }

    function handleCloseModal(): void {
        setShowKeyContacts(!showKeyContacts)
    }

    return (
        <nav className="navbar navbar-expand-xl color-white nav-bg position-sticky top-0 z-index-1">
            <div className="container-fluid position-relative nav-bar-ht py-4 px-0">
                <a className="navbar-logo" href="#">
                    <img src="../images/police-batch-logo.svg" alt="police-batch-logo" onClick={() => { navigate('/default') }} />
                </a>
                <button className="navbar-toggler shadow-none  px-0 py-0 " type="button" data-bs-toggle="collapse"  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon cursor-pointer p-0">
                        <span className="navbar-toggler-icon d-none" />
                        <input id="menu__toggle" type="checkbox" />
                        <label className="menu__btn" htmlFor="menu__toggle">
                            <span />
                        </label>
                    </span>
                </button>
                <div className="collapse navbar-collapse nav-bg pt-5 mt-3 pb-2" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 custom-navbar-nav">
                        <li className="nav-item custom-nav-item d-xl-none d-block" onClick={() => { setShowKeyContacts(!showKeyContacts) }}>
                            <a className="nav-link" href="#">Key Contacts</a>
                        </li>
                    </ul>
                    <div className="d-flex align-items-center gap-2 d-xl-flex d-none pe-4">
                        <a className="nav-link font-16 font-semibold text-nowrap color-white" href="#" onClick={() => { setShowKeyContacts(!showKeyContacts) }}>Key Contacts</a>
                        {/* desktop notification */}
                        <div className="notification-drp position-relative">
                            <button type="button" className="position-relative font-16 color-white text-white shadow-none font-semibold p-0 mx-3 btn border-0 bg-transparent text-nowrap"
                                onClick={() => { setShowNotifications(prevState => !prevState) }} aria-expanded="false">
                                <img className="notify-icon" src="../images/notification-icon.svg" alt="notification-icon" />
                                {/* notification number */}
                                <span className="notify-count font-bold">{contextValue.notificationsCount}</span>
                            </button>
                            {showNotifications && contextValue.notifications.length > 0 ? <ul className="dropdown-menu custom-drp-menu border-0 p-0 show" aria-labelledby="dropdownMenuLink">
                                <li className="font-14 font-medium color-grey-v1 d-flex align-items-center gap-2 p-3 py-2 cust-border-bottom">
                                    Notification
                                    <span className="notification-count font-12 color-white rounded-3 font-bold">{contextValue.notificationsCount}</span>
                                </li>
                                <ul className="notification-scroll me-1 show">
                                    {contextValue.notifications.length  > 0 ? bindNotifications() : null}
                                </ul>
                            </ul> : null}
                        </div>
                        <div className="download-option-dropdown position-relative">
                            <button type="button" className="position-relative font-16 color-white text-white shadow-none font-semibold btn border-0 bg-transparent text-nowrap"
                                onClick={() => { setshowProfile(!showProfile) }} aria-expanded="false">{`Hi, ${contextValue.userDetails.userName}`}
                                <img className="drp-icon ms-2" src="../images/white-drp-arrow.svg" alt="dropdown-arrow" />
                            </button>
                            {showProfile && (<ul className="dropdown-menu custom-drp-menu border-0 px-1 show" aria-labelledby="dropdownMenuLink">
                                <li className="font-medium font-16 color-black px-4">
                                    <a className="font-medium font-16 color-black text-decoration-none px-1" onClick={() => { navigate('/profile') }}>User Profile</a>
                                </li>
                                <li className="font-medium font-16 color-black px-4">
                                    <a className="red-color text-decoration-none px-1" onClick={() => { handleLogoutClick() }}>Logout</a>
                                </li>
                            </ul>)}
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-end w-100 d-xl-none custom-profile-info">
                    {/* hambergur notification */}
                    <div className="notification-drp position-relative">
                        <button type="button" onClick={() => { setShowNotifications(prevState => !prevState) }} className="position-relative font-16 color-white text-white shadow-none font-semibold p-0 mx-3 btn border-0 bg-transparent text-nowrap"
                            aria-expanded="false">
                            <img className="notify-icon" src="../images/notification-icon.svg" alt="notification-icon" />
                            {/* notification number */}
                            <span className="notify-count font-bold">{contextValue.notificationsCount}</span>
                        </button>
                        {showNotifications && contextValue.notifications.length  > 0 ? <ul className="dropdown-menu custom-drp-menu border-0 p-0 show" aria-labelledby="dropdownMenuLink">
                            <li className="font-14 font-medium color-grey-v1 d-flex align-items-center gap-2 p-3 py-2 cust-border-bottom">
                                Notification
                                <span className="notification-count font-12 color-white rounded-3 font-bold">{contextValue.notificationsCount}</span>
                            </li>
                            <ul className="notification-scroll me-1 show">
                                {contextValue.notifications.length  > 0 ? bindNotifications() : null}
                            </ul>
                        </ul> : null}
                    </div>
                    <div className="download-option-dropdown position-relative">
                        <button type="button" className="position-relative font-16 color-white text-white shadow-none font-semibold btn border-0 bg-transparent text-nowrap"
                            onClick={() => { setshowProfile(!showProfile) }} aria-expanded="false">
                            <img className="profile-icon" src="../images/profile-logo.svg" alt="profile-logo" />
                            <img className="drp-icon ms-2" src="../images/white-drp-arrow.svg" alt="dropdown-arrow" />
                        </button>
                        {showProfile && (<ul className="dropdown-menu custom-drp-menu border-0 px-1 py-3 show" aria-labelledby="dropdownMenuLink">
                            <li className="font-medium font-16 color-black px-4">
                                <a className="font-medium font-16 color-black text-decoration-none px-1" onClick={() => { navigate('/profile') }}>User Profile</a>
                            </li>
                            <li className="font-medium font-16 color-black px-4">
                                <a className="red-color text-decoration-none px-1" onClick={() => { handleLogoutClick() }}>Logout</a>
                            </li>
                        </ul>)}
                    </div>
                </div>
            </div>
            {showKeyContacts && (
                <ContactPopUp
                    filter={regionalManagerDetails}
                    onClose={handleCloseModal}
                    type='Contacts'
                />
            )}
            {showKeyContacts && <div className="modal-backdrop fade show"></div>}
        </nav>

    )
}


