import { useContext, useEffect, useState } from "react";
import { encryptStorage, ppsContext } from "../../Configs/Constant";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { contextValueInterface } from "../../Interface/SignIn";
import { ToastInterface } from "../../Interface/Profile";
import Loader from "../Loader";

const ChangePassword = (props: {
  setShowPasswordChangePopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setToastDetails: React.Dispatch<React.SetStateAction<ToastInterface>>;
}) => {
  // const [showOldPassword, setShowOldPassword] = useState(false);
  //PC_SI_09-PC_SI_14 - Declaration and initialization of state variables
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [resetDetails, setResetDetails] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [resetError, setResetError] = useState({
    userID: "",
    oldPassword: "",
    newPassword: "",
    Error: "",
  });

  const contextValue: contextValueInterface = useContext(ppsContext);

  useEffect(() => {
    setResetDetails((prev) => ({
      ...prev,
      userID: contextValue.userDetails.loginUserName,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //PC_SI_16-PC_SI_17 - Handle onchange values of fields
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setResetDetails({
      ...resetDetails,
      [e.target.name]: e.target.value.trim(),
    });
  };
  //PC_SI_16-PC_SI_17 - Handle onchange values of fields
  const handleCancel = () => {
    // props.setShowPasswordChangePopUp(false);
    setResetDetails({ oldPassword: "", newPassword: "" });
  };

  //PC_RP_32-PC_RP_43-Method to reset password
  const ChangeNewPassword = () => {
    // let isValid = true
    let isValid = validateFields();
    let UserId = encryptStorage.getItem("user_id");

    if (isValid) {
      setShowLoader(true)
      const poolData = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID || "us-east-2_bxASDPt57",
        ClientId:
          process.env.REACT_APP_CLIENT_ID || "54k3f587qic1sr9197a16crm3j",
      };

      const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: UserId,
        Pool: userPool,
      });
      const authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails({
          Username: contextValue.userDetails.loginUserName.trim(),
          Password: resetDetails.oldPassword.trim(),
        });
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          cognitoUser.changePassword(
            resetDetails.oldPassword,
            resetDetails.newPassword,
            (error, result) => {
              console.log(error);
              if (error) {
                if (error.message === "Incorrect username or password.") {
                  // Authentication failed
                  setShowLoader(false)
                  setResetError((prev) => ({
                    ...prev,
                    Error: "The old password entered is incorrect",
                  }));
                } else {
                  // Invoke the something went wrong popup
                  setShowLoader(false)
                }
              } else {
                //
                setShowLoader(false)
                props.setShowPasswordChangePopUp(false);
                props.setToastDetails((prev) => ({
                  ...prev,
                  isToast: true,
                  toastDescription:
                    "Your password has been changed successfully",
                  toastMessage: "Password Changed Successfully",
                  toastType: "Success",
                }));
              }
            }
          );
        },
        onFailure: function (err) {
          console.log(err);
          setShowLoader(false)
          props.setShowPasswordChangePopUp(false);
          props.setToastDetails((prev) => ({
            ...prev,
            isToast: true,
            toastDescription: "Failed to change the password",
            toastMessage: "Password Change Failed",
            toastType: "Error",
          }));
        },

        newPasswordRequired: function (userAttributes, requiredAttributes) {
          cognitoUser.completeNewPasswordChallenge(
            resetDetails.newPassword.trim(), // Use the current password as the new password
            requiredAttributes,
            {
              onSuccess: async function (result) {
                var idToken = result.getIdToken().getJwtToken();
                var accessToken = result.getAccessToken().getJwtToken();
                //PC_REF_39- Store the refresh token
                var refreshToken = result.getRefreshToken().getToken();

                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("idToken", idToken);
                localStorage.setItem("refreshToken", refreshToken);
                // Check if the user's role matches the required role
                console.log("success change ");
              },
              onFailure: function (err) {
                // hideLoader()
                console.log("falier change ");
                setResetError({ ...resetError, Error: err.message });
              },
            }
          );
        },
      });
    }
  };

  //PC_SI_18-PC_SI_21-Method to validate the input fields
  const validateFields = () => {
    let isValid = true;
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (resetDetails?.oldPassword?.trim() === "") {
      setResetError((prevState) => ({
        ...prevState,
        oldPassword: "Please enter your password",
      }));
      isValid = false;
    } else {
      setResetError((prevState) => ({
        ...prevState,
        oldPassword: "",
      }));
    }
    if (resetDetails?.newPassword?.trim() === "") {
      setResetError((prevState) => ({
        ...prevState,
        newPassword: "Please enter your password",
      }));
      isValid = false;
    } else {
      setResetError((prevState) => ({
        ...prevState,
        newPassword: "",
      }));
    }
    if (
      resetDetails?.newPassword?.trim() === resetDetails?.oldPassword?.trim()
    ) {
      setResetError((prevState) => ({
        ...prevState,
        oldPassword: "Please enter new password",
      }));
      isValid = false;
    } else {
      setResetError((prevState) => ({
        ...prevState,
        oldPassword: "",
      }));
    }
    return isValid;
  };

  return (
    <>
    {showLoader && <Loader></Loader>}
    <div
      className="modal active"
      id="exampleModal4"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-radius-10">
          <div className="modal-header border-0 px-4 pt-4 align-items-start">
            <h5
              className="modal-title font-bold font-18 color-black-v2 mb-2"
              id="exampleModalLabel"
            >
              Change Password
            </h5>
            <button
              type="button"
              className="btn-close custom-btn-close mt-1"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                props.setShowPasswordChangePopUp(false);
              }}
            />
          </div>
          <div className="modal-body px-4 py-2">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 mb-4">
                <div className="position-relative">
                  <label
                    htmlFor="oldPassword"
                    className="font-semibold font-14 color-black mb-2"
                  >
                    Current Password
                  </label>
                  <input
                    type={showOldPassword ? `text` : `password`}
                    id="oldPassword"
                    name="oldPassword"
                    value={resetDetails.oldPassword}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleOnChange(e);
                    }}
                    className="form-control custom-form-fields color-black-v1 font-16 font-regular pe-5"
                    placeholder="Enter your current password"
                  />
                  <button
                    className="position-absolute field-icons p-0 border-0 bg-transparent"
                    onClick={() => {
                      setShowOldPassword(!showOldPassword);
                    }}
                  >
                    <img src="../images/eye-icon.svg" alt="eye-icon" />
                  </button>
                </div>
                {resetError.oldPassword !== "" && (
                  <span className="font-14 font-medium alert-text">
                    {resetError.oldPassword}
                  </span>
                )}
              </div>
              <div className="col-12 mb-4">
                <div className="position-relative">
                  <label
                    htmlFor="newPassword"
                    className="font-semibold font-14 color-black mb-2"
                  >
                    New Password
                  </label>
                  <input
                    type={showNewPassword ? `text` : `password`}
                    id="newPassword"
                    name="newPassword"
                    value={resetDetails.newPassword}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleOnChange(e);
                    }}
                    className="form-control custom-form-fields color-black-v1 font-16 font-regular pe-5"
                    placeholder="Enter your new password"
                  />
                  <button
                    className="position-absolute field-icons p-0 border-0 bg-transparent"
                    onClick={() => {
                      setShowNewPassword(!showNewPassword);
                    }}
                  >
                    <img src="../images/eye-icon.svg" alt="eye-icon" />
                  </button>
                </div>
                {resetError.newPassword !== "" && (
                  <span className="font-14 font-medium alert-text">
                    {resetError.newPassword}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer gap-2 border-0 px-4 pb-4 pt-2 justify-content-end">
            <button
              className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
              onClick={(e) => {
                handleCancel();
              }}
            >
              Cancel
            </button>
            <button
              className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal4"
              onClick={() => {
                ChangeNewPassword();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div></>
  );
};

export default ChangePassword;
