/* eslint-disable jsx-a11y/anchor-is-valid */
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'; // Assuming you'll use this later
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { VerificationDetail, VerificationErr } from '../../Interface/SignIn';
import Loader from '../Loader/Loader';

export const Verifications = () => {

    //PC_VC_07-PC_SI_10 - Declaration and initialization of state variables 

    const [verificationErr, setVerificationErr] = useState<VerificationErr>({
        verificationCodeErr: "",
        confirmPasswordErr: "",
        isnewPasswordError: false,
        error: ""
    });

    const [timer, setTimer] = useState(300);
    const [isTimerRunning, setIsTimerRunning] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    //PC_VC_11-Hook used to navigate
    const location = useLocation();
    const navigate = useNavigate();
    const stateData = location.state;
    console.log(stateData, "user state");

    const [verificationDetail, setVerificationCode] = useState<VerificationDetail>({
        userId: stateData?.userEmailId,
        verificationCode: 0,
        newPassword: "",
        confirmPassword: ""
    });
    //PC_VC_12-PC_VC_18-Timer function
    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isTimerRunning && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            setIsTimerRunning(false)
        }

        return () => clearInterval(interval);
    }, [isTimerRunning, timer]);

    // useEffect(() => {
    //     setVerificationCode({
    //         ...verificationDetail,
    //         userId: location?.state?.userId,
    //     });

    // }, []);

    //PC_VC_40-Function to format seconds to 'MM:SS' format
    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        return `${formattedMinutes}:${formattedSeconds}`;
    };

    //PC_SI_16-PC_SI_17 - Handle onchange values of fields
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVerificationCode({ ...verificationDetail, [e.target.name]: e.target.value.trim() });
        if (e.target.name !== "verificationCode") {
            validateFields(e.target.name, e.target.value.trim())
        } else {
            setVerificationErr({ ...verificationErr, verificationCodeErr: "" })
        }
    }

    //PC_VC_21-PC_VC_28-Validating fields values
    const validateFields = (name?: string, value?: string) => {
        let isValid = true;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/;
        switch (name) {
            case "newPassword":
                if (value && !passwordRegex.test(value)) {
                    setVerificationErr({ ...verificationErr, isnewPasswordError: true });
                    isValid = false;
                } else {
                    setVerificationErr({ ...verificationErr, isnewPasswordError: false });
                    isValid = true
                }
                break;
            case "confirmPassword":
                if (value !== verificationDetail.newPassword) {
                    setVerificationErr({ ...verificationErr, confirmPasswordErr: "The confirmation password does not match the new password" });
                    isValid = false;
                } else {
                    setVerificationErr({ ...verificationErr, confirmPasswordErr: "" });
                    isValid = true
                }
                break;
            case "verificationCode":
                if (!verificationDetail.verificationCode || !(verificationDetail.verificationCode > 5)) {
                    setVerificationErr({ ...verificationErr, verificationCodeErr: "Code must be at least 6 characters" });
                    isValid = false;
                } else {
                    setVerificationErr({ ...verificationErr, confirmPasswordErr: "" });
                    isValid = true
                }
                break;
            default:
                if (!passwordRegex.test(verificationDetail.newPassword)) {
                    setVerificationErr(prevState => ({
                        ...prevState,
                        isnewPasswordError: true,
                    }));
                    isValid = false;
                }
                if (verificationDetail.confirmPassword !== verificationDetail.newPassword) {
                    setVerificationErr(prevState => ({
                        ...prevState,
                        confirmPasswordErr: "The confirmation password does not match the new password",
                    }));
                    isValid = false;
                }
                if (!verificationDetail.verificationCode || !(verificationDetail.verificationCode > 5)) {
                    setVerificationErr(prevState => ({
                        ...prevState,
                        verificationCodeErr: "Code must be at least 6 characters",
                    }));
                    isValid = false;
                }
                break;
        }
        return isValid;
    }

    //PC_VC_29-PC_VC_37-Reset Password
    const handleSubmit = () => {
        // let isValid = true
        let isValid = validateFields()

        if (isValid) {
            // showLoader()
            setShowLoader(true)
            const poolData = {
                UserPoolId: process.env.REACT_APP_USER_POOL_ID || "us-east-2_bxASDPt57",
                ClientId: process.env.REACT_APP_CLIENT_ID || "54k3f587qic1sr9197a16crm3j",
            };

            const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                Username: verificationDetail.userId,
                Pool: userPool,
            });

            let code = verificationDetail.verificationCode.toString()
            cognitoUser.confirmPassword(code, verificationDetail.confirmPassword, {
                onSuccess: function (result) {
                    // hideLoader()
                    console.log("result vereifcation", result);
                    setShowLoader(false)
                    navigate("/reset-confirm")
                    console.log(result);
                },
                onFailure: function (err) {
                    // hideLoader()

                    setShowLoader(false)
                    console.log("err vereifcation", err);
                    setVerificationErr({ ...verificationErr, error: err.message })
                }
            });
        }
    }

    //PC_VC_39-Resend confirmation code
    const resendConfirmationCode = () => {
        if (!isTimerRunning) {
            // showLoader()
            setShowLoader(true)
            const poolData = {
                UserPoolId: process.env.REACT_APP_USER_POOL_ID || "",
                ClientId: process.env.REACT_APP_CLIENT_ID || "",
            };

            const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                Username: verificationDetail.userId,
                Pool: userPool,
            });

            cognitoUser.forgotPassword({
                onSuccess: function (result) {
                    // hideLoader()
                    setShowLoader(false)
                    setTimer(300);
                    setIsTimerRunning(true);
                },
                onFailure: function (err) {
                    // hideLoader()
                    setShowLoader(false)
                    // Handle failure
                    setVerificationErr({ ...verificationErr, error: err.message })
                }
            });
        }
    }
    return (

        <>
            {showLoader && <Loader></Loader>}
            <div className="container-fluid position-relative h-100 min-vh-100">

                <div className="login-bg-image position-absolute h-100 min-vh-100" />
                {/* <img class="position-absolute login-bg-image h-100 w-100" src="../images/background-image.png" alt="backgound-image"> */}
                <div className="row d-flex align-items-center justify-content-center h-100 position-relative ">
                    <div className="col-lg-5 col-md-6 col-sm-8 my-5 cust-client-padding pb-4">
                        <div className="position-relative">
                            <div className="creation-container pb-5 px-4 px-md-5 position-relative">
                                <span className="text-center d-flex align-items-center justify-content-center logo-container">
                                    <img src="../images/client-logo.svg" alt="Logo" className="mb-4" />
                                </span>
                                <h3 className="font-semibold font-27 text-center mb-4 primary-text-color">
                                    Verification
                                </h3>
                                <p className="font-16 font-regular secondary-text-color mb-4">Please enter the code that has been sent to your business email
                                    address.</p>
                                <div className="row">

                                    <div className="col-md-12">

                                        <label htmlFor="VerificationCode" className="font-semibold font-15 primary-text-color mb-2">Verification Code</label>
                                        <input type="text" name="verificationCode" className="form-control custom-form-input" id="VerificationCode" placeholder="Enter Verification Code" value={verificationDetail.verificationCode !== 0 ? verificationDetail.verificationCode : ""} onChange={handleOnChange} onKeyDown={e => { if (e.key === "Enter") handleSubmit() }} />
                                        <div className="mb-3 pt-3 d-flex justify-content-between align-items-center">
                                            <span className="d-flex align-items-center" >
                                                <span className={isTimerRunning ? "timer-icon me-2" : "timer-icon me-2 d-none"}></span>
                                                <label className={isTimerRunning ? "font-12 font-medium primary-text-color" : "font-12 font-medium primary-text-color d-none"}>{formatTime(timer)} mins</label>
                                            </span>
                                            <a href="#" className={isTimerRunning ? "text-decoration-none link-color font-14 text-end font-medium disabled" : "text-decoration-none link-color font-14 text-end font-medium"} onClick={resendConfirmationCode}>Resend Code</a>
                                        </div>
                                        {verificationErr.verificationCodeErr && <label className="required-color font-12 font-regular">{verificationErr.verificationCodeErr}</label>}
                                    </div>
                                    <div className="col-lg-12 mb-4">
                                        <label
                                            htmlFor="userID"
                                            className="font-semibold font-15 primary-text-color mb-2"
                                        >
                                            User ID
                                        </label>
                                        <input
                                            type="text"
                                            id="userID"
                                            className="form-control custom-form-fields color-black-v1 font-16 font-medium"
                                            placeholder="Enter your User ID"
                                            name='userId'
                                            value={verificationDetail.userId}
                                            onChange={handleOnChange}
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-4">
                                        <label
                                            htmlFor="NewPassword"
                                            className="font-semibold font-15 primary-text-color mb-2"
                                        >
                                            New PassWord
                                        </label>
                                        <input maxLength={64} type={showNewPassword ? "text" : "password"} name="newPassword" className="form-control custom-form-fields color-black-v1 font-16 font-medium py-2" id="NewPassword" placeholder="Enter New Password" value={verificationDetail.newPassword} onChange={handleOnChange}
                                            onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                        />
                                        <span className={showNewPassword ? "position-absolute eye-open-icon custom-input-eye-color eye-open" : "position-absolute custom-input-eye-postion custom-input-eye-color eye-close"} onClick={() => setShowNewPassword(!showNewPassword)}></span>
                                        <div className="pt-1">
                                            {verificationErr.isnewPasswordError && <ul className="ps-3">
                                                <li className="required-color font-12 font-regular">Minimum length should be atleast 8 characters</li>
                                                <li className="required-color font-12 font-regular">It should have lowercase letters</li>
                                                <li className="required-color font-12 font-regular">It should have uppercase letters</li>
                                                <li className="required-color font-12 font-regular">It should have symbols</li>
                                                <li className="required-color font-12 font-regular">It should contain numbers</li>
                                            </ul>}
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-4">
                                        <label htmlFor="ConfirmPassword" className="font-semibold font-15 primary-text-color mb-2">Confirm Password</label>
                                        <div className="position-relative">
                                            <input maxLength={64} type={showConfirmPassword ? "text" : "password"} name="confirmPassword" className="form-control custom-form-fields color-black-v1 font-16 font-medium py-2" id="ConfirmPassword" placeholder="Enter Confirm Password" value={verificationDetail.confirmPassword} onChange={handleOnChange} // Prevent pasting
                                                onKeyDown={e => { if (e.key === "Enter") handleSubmit() }} />
                                            <span className={showConfirmPassword ? "position-absolute eye-open-icon custom-input-eye-color eye-open" : "position-absolute custom-input-eye-postion custom-input-eye-color eye-close"} onClick={() => setShowConfirmPassword(!showConfirmPassword)}></span>                                </div>
                                        <div className="pt-1">
                                            {verificationErr.confirmPasswordErr && <label className="required-color font-12 font-regular">{verificationErr.confirmPasswordErr}</label>}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-3 flex-column gap-4">
                                    <button
                                        type="button"
                                        className="btn primary-login-btn font-15 font-semibold text-nowrap w-100"
                                        onClick={() => { handleSubmit() }}
                                    >
                                        Reset Password
                                    </button>
                                    <button
                                        type="button"
                                        className="btn secondary-login-btn font-14 font-medium w-100 text-nowrap"
                                        onClick={() => { navigate('/signup'); }}
                                    >
                                        Back to Sign In
                                    </button>
                                </div>
                            </div>
                            <div className="creation-gradient-container"></div>
                        </div>
                    </div>
                </div>
                <p className="color-white font-semibold font-15 text-center position-absolute w-100 helpline mt-2">
                    Reach our command center 24/7 at:{" "}
                    <a href="tel:+1234567890" className="color-white cust-text-underline">
                        1-800-775-0805
                    </a>{" "}
                    |
                    <a
                        href="mailto:example@example.com"
                        className="color-white cust-text-underline"
                    >
                        command@ppsinctn.com
                    </a>
                </p>
            </div>

        </>
    );
}
