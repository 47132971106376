import React from 'react';
import { feedbackDetails } from "../../Interface/ServiceRequest";

interface FeedbackProps {
    feedbackDetails: feedbackDetails;
    setFeedbackDetails: any
    feedbackId: string;
    setFeedbackId: (id: string) => void;
    sumbitFeedback: (feedbackId: string) => void;
}

const Feedback: React.FC<FeedbackProps> = ({ feedbackDetails, setFeedbackDetails,
    feedbackId, setFeedbackId, sumbitFeedback }) => {

    const handleFeedback = (name: string, value: string | number) => {
        setFeedbackDetails({ ...feedbackDetails, [name]: value });
    };

    console.log(feedbackDetails, "feedbackDetails")
    return (
        <div
            className="modal fade show"
            id="exampleModal7"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: "block", paddingLeft: "0px" }}
        >
            <div className="modal-dialog ">
                <div className="modal-content border-radius-10">
                    <div className="modal-header border-0 px-4 pt-4 align-items-start">
                        <h5
                            className="modal-title font-bold font-18 color-black-v2 mb-2"
                            id="exampleModalLabel"
                        >
                            Let us know how you feel
                        </h5>
                        <button
                            type="button"
                            className="btn-close custom-btn-close mt-1"
                            aria-label="Close"
                            onClick={() => setFeedbackId("")}
                        />
                    </div>
                    <div className="modal-body px-4 py-0">
                        <div className="row align-items-center flex-wrap">
                            <p className="font-15 font-medium color-grey-v4">
                                Your input is valuable in helping us better understand your needs
                                and tailor our service accordingly.
                            </p>
                            <div className="col-md-12">
                                <div className="carousel carousel-bg">
                                    <ul className="carousel__list d-flex align-items-center p-0">
                                        {[
                                            { rating: 1, src: "../images/extremely-bad-emoji.svg", alt: "extremely-bad-emoji" },
                                            { rating: 2, src: "../images/not-satisfied-emoji.svg", alt: "not-satisfied-emoji" },
                                            { rating: 3, src: "../images/neutral-emoji.svg", alt: "neutral-emoji" },
                                            { rating: 4, src: "../images/satisfied-emoji.svg", alt: "satisfied-emoji" },
                                            { rating: 5, src: "../images/extremely-good-emoji.svg", alt: "extremely-good-emoji" }
                                        ].map(item => (
                                            <li
                                                key={item.rating}
                                                className={`carousel__item ${feedbackDetails.feedback_rating === item.rating ? 'selected' : ''}`}
                                                data-pos={item.rating - feedbackDetails.feedback_rating}
                                                onClick={() => { handleFeedback("feedback_rating", item.rating) }}
                                            >
                                                <span className="emoji-bg">
                                                    <img
                                                        src={item.src}
                                                        alt={item.alt}
                                                    />
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <textarea
                                id="textarea"
                                className="form-control service-form-field w-100 custom-textarea font-14 font-regular bg-white"
                                placeholder="Describe in detail (optional)"
                                value={feedbackDetails.feedback_description}
                                name="feedback_description"
                                onChange={(e) => { handleFeedback(e.target.name, e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="modal-footer gap-2 border-0 px-4 pb-4 pt-4 justify-content-end">
                        <button
                            className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                            onClick={() => setFeedbackId("")}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3"
                            onClick={() => { sumbitFeedback(feedbackId) }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feedback;