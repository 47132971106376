import { useEffect, useState } from "react";
import { getDynamicDropdownData, getRegionData, SaveSignUp, VerifyMasterEmail } from "../../Service/Login/SignUpService";
import { ClientData, Errors, industry, masterData, Region, ResponseCleint } from "../../Interface/SignUp";
import { SiteClientForm } from "./SiteClientForm";
import { State, City, ICity } from 'country-state-city';
import { SiteClientPopup } from "./SiteClientPopup";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { CustomToast } from "../Common/CustomToast";
// import { CustomToast } from "../Common/toast";

// PS_CF_SU_01 - PS_CF_SU_86
export const ClientSignUp = () => {

    const [masterformData, setMasterFormData] = useState<ClientData>({
        user_id: '', first_name: '', last_name: '', doing_business_as: '', email_id: '', phone_no: '', address_line_1: '', address_line_2: '', zip_code: '', city: '', state: '', region: '', region_id: '', industry: '', industry_name: ''
    });

    const [MasterClientData, setMasterClientData] = useState<masterData>({
        master_client_email: "", master_name: ""
    })

    const [selectedSignUp, setSelectedSignUp] = useState("Site Client");
    const [presentIn, SetPresentIn] = useState("Site Client");
    const [siteFormData, setSiteFormData] = useState<ClientData>({ user_id: '', first_name: '', last_name: '', doing_business_as: '', email_id: '', phone_no: '', address_line_1: '', address_line_2: '', zip_code: '', city: '', state: '', region: '', region_id: '', industry: '', industry_name: '' });
    const [popUpSiteFormData, setpopUpSiteFormData] = useState<ClientData>({ user_id: '', first_name: '', last_name: '', doing_business_as: '', email_id: '', phone_no: '', address_line_1: '', address_line_2: '', zip_code: '', city: '', state: '', region: '', region_id: '', industry: '', industry_name: '' });
    const [siteClientData, setSiteClientData] = useState<ClientData[]>([]);

    const [error, setError] = useState<Errors>({
        first_name: '',
        last_name: '',
        doing_business_as: '',
        email_id: '',
        phone_no: '',
        address_line_1: '',
        address_line_2: '',
        zip_code: '',
        city: '',
        state: '',
        region: '',
        industry: '',
        industry_name: '',
        master_client_email: '',
        unique_email: '',
    });
    const emptyError: Errors = {
        first_name: '',
        last_name: '',
        doing_business_as: '',
        email_id: '',
        phone_no: '',
        address_line_1: '',
        address_line_2: '',
        zip_code: '',
        city: '',
        state: '',
        region: '',
        industry: '',
        industry_name: '',
        master_client_email: '',
        unique_email: ''
    }
    const [masterError, setMasterError] = useState<Errors>(emptyError)

    let emptyData: ClientData = {
        user_id: '',
        first_name: '',
        last_name: '',
        doing_business_as: '',
        email_id: '',
        phone_no: '',
        address_line_1: '',
        address_line_2: '',
        zip_code: '',
        city: '',
        state: '',
        region: '',
        region_id: '',
        industry: '',
        industry_name: ''

    }

    const [industryDropdownData, setIndustryDropdownData] = useState<industry[]>([
        { industry_uuid: "sample", industry_name: "sample" }
    ]);
    const [regionData, setRegionData] = useState<Region[]>([
        { region_id: "sapmle", region_name: "sapmle" }
    ]);
    const [isOtherIndustrySIte, SetisOtherIndustrySIte] = useState(false);
    const [isOtherIndustryMaster, SetisOtherIndustryMaster] = useState(false);
    const [sumbitButtonEnabled, setSumbitButtonEnabled] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [citiesList, setCitiesList] = useState<ICity[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showToast, setShowToast] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showToastProp, setShowToastProp] = useState({ type: "", title: "", message: "" });
    // const [showToastProp, setShowToastProp] = useState({ toastMessage: "", toastType: "", toastDescription: "", isToast: false });
    const [DisableCity, setDisableCity] = useState(false);
    // toastDescription
    const navigate = useNavigate()

    useEffect(() => {
        fetchDropdownData();
        fetchRegionData();
    }, []);
    useEffect(() => {
        validateIsSumbit()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteFormData, masterformData, popUpSiteFormData, siteClientData]);

    // PS_CF_SU_04
    // Fetch dynamic dropdown data on component mount
    const fetchDropdownData = async () => {
        setShowLoader(true)
        try {
            const response = await getDynamicDropdownData();
            if (response?.code === 200) {

                setIndustryDropdownData(response?.data?.industry);
            } else {
                console.error("Error fetching dynamic data");
                console.error("Error saving form data");
                setShowToastProp({ type: "Error", title: "Failed to fatch Indusry data", message: "Failed to fatch Indusry data" })
                // setShowToastProp({ toastType: "Error", toastMessage: "Failed to fatch Indusry data", toastDescription: "Failed to fatch Indusry data", isToast: true })
            }
        } catch (error: any) {
            console.error(error.toastDescription);
            console.error("Error saving form data");
            setShowToastProp({ type: "Error", title: "Failed to fatch Indusry data", message: "Failed to fatch Indusry data" })
            // setShowToastProp({ toastType: "Error", toastMessage: "Failed to fatch Indusry data", toastDescription: "Failed to fatch Indusry data", isToast: true })
            setShowToast(true)
            setShowLoader(false)
        } finally {
            setShowLoader(false)
            setTimeout(() => {
                setShowToast(false);
            }, 4000);
        }
    };

    const fetchRegionData = async () => {
        setShowLoader(true)
        try {
            const response = await getRegionData();
            if (response?.code === 200) {
                setRegionData(response?.data);
                setShowLoader(false)

            } else {
                console.error("Error fetching dynamic data");
                console.error("Error saving form data");
                setShowToastProp({ type: "Error", title: "Failed to fatch Region data", message: "Failed to fatch Region data" })
                // setShowToastProp({ toastType: "Error", toastMessage: "Failed to fatch Region data", toastDescription: "Failed to fatch Region data", isToast: true })
                setShowLoader(false)
                setShowToast(true)

            }
        } catch (error: any) {
            console.error(error.toastDescription);
            console.error("Error saving form data");
            setShowToastProp({ type: "Error", title: "Failed to fatch Region data", message: "Failed to fatch Region data" })
            // setShowToastProp({ toastType: "Error", toastMessage: "Failed to fatch Region data", toastDescription: "Failed to fatch Region data", isToast: true })
            setShowToast(true)
            setShowLoader(false)
        } finally {
            setShowLoader(false)
            setTimeout(() => {
                setShowToast(false);
            }, 4000);
        }
    };
    // PS_CF_SU_44 - PS_CF_SU_47 
    const VerifyEmail = async () => {
        setShowLoader(true)
        let payload = {
            email_id: MasterClientData.master_client_email
        }
        try {
            const response = await VerifyMasterEmail(payload);

            if (response?.code === 200) {
                if (response?.data?.master_client !== "") {
                    setMasterClientData({ ...MasterClientData, master_name: response?.data?.master_client })
                    setSiteFormData({ ...siteFormData, region: response?.data?.region, region_id: response?.data?.region_id })
                    setShowToastProp({ type: "Success", title: "Verified User Sccessfully", message: "Verified Master User sccessfully" })
                    // setShowToastProp({ toastType: "Success", toastMessage: "Verified User Sccessfully", toastDescription: "Verified Master User sccessfully", isToast: true })
                    setShowLoader(false)
                    setShowToast(true)
                } else {
                    setShowToastProp({ type: "Error", title: "Failed to verify Master User", message: "Failed to verify master user" })
                    // setShowToastProp({ toastType: "Error", toastMessage: "Failed to verify Master User", toastDescription: "Failed to verify master user", isToast: true })
                    setShowLoader(false)
                    setShowToast(true)
                    setError({ ...error, master_client_email: "invalid master Email" })
                }

            } else {
                console.error("Error fetching Master email data");
                console.error("Error saving form data");
                setShowToastProp({ type: "Error", title: "Verifing Master user Failed", message: "Verifing Master user Failed" })
                // setShowToastProp({ toastType: "Error", toastMessage: "Verifing Master user Failed", toastDescription: "Verifing Master user Failed", isToast: true })
                setShowLoader(false)
                setShowToast(true)
            }
        } catch (error) {
            console.error('Failed to fetch Master email data', error);
            console.error("Error saving form data");
            setShowToastProp({ type: "Error", title: "Verifing Master user Failed", message: "Verifing Master user Failed" })
            // setShowToastProp({ toastType: "Error", toastMessage: "Verifing Master user Failed", toastDescription: "Verifing Master user Failed", isToast: true })
            setShowLoader(false)
            setShowToast(true)
        }
        finally {
            setShowLoader(false)
            setTimeout(() => {
                setShowToast(false);
            }, 4000);
        }
    };


    // const formatPhoneNumber = (value: string) => {
    //     let input = value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    //     let areaCode = input.substring(0, 3);
    //     let middle = input.substring(3, 6);
    //     let last = input.substring(6, 10);

    //     if (input.length > 6) {
    //         return `+1 (${areaCode}) ${middle}-${last}`;
    //     } else if (input.length > 3) {
    //         return `+1 (${areaCode}) ${middle}`;
    //     } else if (input.length > 0) {
    //         return `+1 (${areaCode}`;
    //     }
    //     return "";
    // };

    // PS_CF_SU_14
    const handleInputChange = (field: string, value: string) => {
        console.log(presentIn, value, field, "fieldddd");

        console.log(field, "field", presentIn, "presentIn")
        if (field === "state") {
            const citiesList = City.getCitiesOfState('US', value);
            setCitiesList(citiesList);
        }
        if (field === "master_client_email") {
            setMasterClientData((prev) => ({ ...prev, [field]: value }))
        }

        if (presentIn === "Site Client") {
            setSiteFormData((prev) => ({ ...prev, [field]: value }))
            if (field === "phone_no") {
                // let formatedNumber = formatPhoneNumber(value);
                setSiteFormData((prev) => ({ ...prev, [field]: value }))
            }
            if (field === "industry") {
                let industryid = industryDropdownData?.find((ele) => ele?.industry_uuid === value)
                if (industryid?.industry_name === "Other") {
                    SetisOtherIndustrySIte(true)
                } else {
                    SetisOtherIndustrySIte(false)
                }
            }
            if (field === "region_id") {
                let regionID = regionData?.filter((ele) => ele.region_id === value)
                if (regionID.length || regionID !== undefined) {
                    setSiteFormData((prev) => ({ ...prev, region: regionID[0]?.region_name, [field]: value }))
                } else {
                    setSiteFormData((prev) => ({ ...prev, region_id: value, region: "" }))
                }
            }
        } else if (presentIn === "Site Client Popup") {
            setpopUpSiteFormData((prev) => ({ ...prev, [field]: value }))
            if (field === "phone_no") {
                // let formatedNumber = formatPhoneNumber(value);
                setpopUpSiteFormData((prev) => ({ ...prev, [field]: value }))
            }
            if (field === "industry") {
                let industryid = industryDropdownData?.find((ele) => ele?.industry_uuid === value)
                if (industryid?.industry_name === "Other") {
                    SetisOtherIndustrySIte(true)
                } else {
                    SetisOtherIndustrySIte(false)
                }
            }
            if (field === "region_id") {
                let regionID = regionData?.filter((ele) => ele.region_id === value)
                if (regionID.length || regionID !== undefined) {
                    setpopUpSiteFormData((prev) => ({ ...prev, region: regionID[0]?.region_name, [field]: value }))
                } else {
                    setpopUpSiteFormData((prev) => ({ ...prev, region_id: value, region: "" }))
                }
            }
        }
        else {
            setSiteFormData((prev) => ({ ...prev, [field]: value }))
        }
        if (presentIn === "Master Client") {
            if (field === "region_id") {

                let regionID = regionData?.filter((ele) => ele.region_id === value)
                if (regionID.length || regionID !== undefined) {
                    setMasterFormData((prev) => ({ ...prev, region: regionID[0]?.region_name, [field]: value }))
                } else {
                    setMasterFormData((prev) => ({ ...prev, region_id: value, region: "" }))

                }

            }
            if (field === "phone_no") {
                // let formatedNumber = formatPhoneNumber(value);
                // console.log(formatedNumber, "phoneMatch00");
                setMasterFormData((prev) => ({ ...prev, phone_no: value }));
            }
            if (field === "industry") {
                let industryid = industryDropdownData?.find((ele) => ele?.industry_uuid === value)
                setMasterFormData((prev) => ({ ...prev, [field]: value }))
                if (industryid?.industry_name === "Other") {
                    SetisOtherIndustryMaster(true)
                } else {
                    SetisOtherIndustryMaster(false)
                }
            } else {
                setMasterFormData((prev) => ({ ...prev, [field]: value }))
            }
        }
    };

    console.log(masterformData, "master");
    console.log(siteFormData, "site");

    // PS_CF_SU_15
    const handleValidation = (field: string, value: string) => {


        //check all fields

        switch (field) {
            case 'first_name':
            case 'last_name':
                if (!/^[a-zA-Z]+$/.test(value) || "") {
                    setError({ ...error, [field]: `Please Enter the ${field === 'first_name' ? 'First' : 'Last'} Name` })
                } else {
                    setError({ ...error, [field]: "" })
                }
                break;
            case 'email_id':
            case 'master_client_email':
                const emailRegex = /^[a-zA-Z0-9.]+@(?!gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|aol\.com)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (!emailRegex.test(value) || "") {
                    setError({ ...error, [field]: "Please Enter the Business Email Address", unique_email: "" })
                } else {
                    setError({ ...error, [field]: "" })
                }
                break;
            case 'phone_no':
                if (!/^\d{10}$/.test(value) || "") {
                    setError({ ...error, phone_no: "Please Enter the Valid Phone Number" })

                } else {
                    setError({ ...error, phone_no: "" })
                }
                break;
            case 'zip_code':
                if (!/^\d{5,10}$/.test(value) || "") {
                    setError({ ...error, zip_code: "Please Enter the Zip code" })

                } else {
                    setError({ ...error, zip_code: "" })
                }
                break;
            case 'doing_business_as':
                if (value === "") {
                    setError({ ...error, doing_business_as: "Please Enter doing business as" })

                } else {
                    setError({ ...error, doing_business_as: "" })
                }
                break;
            case 'address_line_1':
                if (value === "") {
                    setError({ ...error, address_line_1: "Please Enter Address line 1" })

                } else {
                    setError({ ...error, address_line_1: "" })
                }
                break;
            case 'address_line_2':
                if (value === "") {
                    setError({ ...error, address_line_2: "Please Enter address line 2" })

                } else {
                    setError({ ...error, address_line_2: "" })
                }
                break;
            case 'state':
                if (value === "option") {
                    setError({ ...error, state: "Please Enter the State" })

                } else {
                    setError({ ...error, state: "" })
                }
                break;
            case 'city':
                if (value === "option") {
                    setError({ ...error, city: "Please Enter the city" })

                } else {
                    setError({ ...error, city: "" })
                }
                break;
            case 'industry':
                if (value === "option") {
                    setError({ ...error, industry: "Please Enter the industry" })

                } else {
                    setError({ ...error, industry: "" })
                }
                break;
            default:
                setError({ ...error, [field]: "" })
        }
    };

    // PS_CF_SU_76
    const validateIsSumbit = () => {

        let isNoError = false
        let isFieldValid = false

        //check all fields
        if (presentIn === "Site Client Popup") {
            if (popUpSiteFormData.first_name !== "" && popUpSiteFormData.last_name !== "" && popUpSiteFormData.doing_business_as !== "" && popUpSiteFormData.industry !== "" && popUpSiteFormData.address_line_1 !== "" && popUpSiteFormData.address_line_2 !== "" && popUpSiteFormData.city !== "" && popUpSiteFormData.state !== "" && popUpSiteFormData.region_id !== "") {
                isFieldValid = true
            }

            if (error.first_name === "" && error.last_name === "" && error.doing_business_as === "" && error.industry === "" && error.address_line_1 === "" && error.address_line_2 === "" && error.city === "" && error.state === "" && error.region === "") {
                isNoError = true
            }
            if (isOtherIndustrySIte) {
                if (popUpSiteFormData.industry_name !== "") {
                    isFieldValid = true
                } else {
                    isFieldValid = false
                }
            }
            // Check if site client email is unique and not the same as master email
            console.log(popUpSiteFormData.email_id, masterformData.email_id);
            console.log(popUpSiteFormData.email_id === masterformData.email_id);
            if (error.email_id === "") {
                if (popUpSiteFormData.email_id !== "") {
                    if (popUpSiteFormData.email_id === masterformData.email_id) {
                        console.log("isndeeee");
                        setError((prev) => ({ ...prev, unique_email: "Site Client Email should not match Master Client Email" }));
                    } else if (siteClientData.length) {
                        if (siteClientData.some(client => client.email_id === popUpSiteFormData.email_id)) {
                            setError({ ...error, unique_email: "Business Email Address must be unique", email_id: "" });

                        } else {
                            setError({ ...error, unique_email: "" });
                        }
                    }
                }
            }

        }

        if (presentIn === "Site Client") {
            if (siteFormData.first_name !== "" && siteFormData.last_name !== "" && siteFormData.doing_business_as !== "" && siteFormData.industry !== "" && siteFormData.address_line_1 !== "" && siteFormData.address_line_2 !== "" && siteFormData.city !== "" && siteFormData.state !== "" && siteFormData.region_id !== "") {
                isFieldValid = true
            }

            if (error.first_name === "" && error.last_name === "" && error.doing_business_as === "" && error.industry === "" && error.address_line_1 === "" && error.address_line_2 === "" && error.city === "" && error.state === "" && error.region === "") {
                isNoError = true
            }
            if (isOtherIndustrySIte) {
                if (siteFormData.industry_name !== "") {
                    isFieldValid = true
                } else {
                    isFieldValid = false
                }
            }
            if (error.email_id === "" && error.master_client_email === "") {
                if (MasterClientData.master_client_email !== "" && (MasterClientData.master_client_email === siteFormData.email_id)) {
                    setError({ ...error, unique_email: "Business Email Address must be unique", email_id: "" })

                } else {
                    setError({ ...error, unique_email: "" })
                }
            }


        }
        if (presentIn === "Master Client") {
            if (masterformData.doing_business_as !== "" && masterformData.phone_no !== "" && masterformData.industry !== "" && masterformData.region_id !== "") {
                isFieldValid = true
            }
            if (error.first_name === "" && error.last_name === "" && error.doing_business_as === "" && error.industry === "" && error.email_id === "" && error.phone_no === "" && error.region === ""
            ) {
                isNoError = false
            }

            if (isOtherIndustryMaster) {
                if (masterformData.industry_name !== "") {
                    isFieldValid = true
                } else {
                    isFieldValid = false
                }
            }
            if (siteClientData.length) {
                isFieldValid = true
            } else {
                isFieldValid = false
            }
            // Check if email is unique among all site clients
            if (error.email_id === "") {
                if (siteClientData.length) {
                    if (siteClientData.some(client => client.email_id === MasterClientData.master_client_email)) {
                        setError({ ...error, unique_email: "Business Email Address must be unique", email_id: "" });
                    } else {
                        setError({ ...error, unique_email: "" });
                    }
                }
            }

        }
        setSumbitButtonEnabled(() => (isFieldValid && isNoError))
    };

    // PS_CF_SU_70
    const SavePopupForm = () => {
        if (isEdit) {
            let filterData = siteClientData.map((ele) => {
                if (ele.email_id === popUpSiteFormData.email_id) {
                    return popUpSiteFormData
                }
                return ele
            })
            setSiteClientData(filterData);
            setIsEdit(false)

        } else {
            setSiteClientData([...siteClientData, popUpSiteFormData]);
        }

        setpopUpSiteFormData(emptyData)
        SetPresentIn("Master Client")
        setSumbitButtonEnabled(false)
        setError(masterError)
        SetisOtherIndustrySIte(false)
    };

    // PS_CF_SU_67
    const PopupCancel = () => {
        setSiteFormData(emptyData)
        SetPresentIn("Master Client")
        setSumbitButtonEnabled(false)
        setError(masterError)
        SetisOtherIndustrySIte(false)
    };


    const SaveFromDetails = async () => {
        setShowLoader(true)
        let datas: ResponseCleint
        if (selectedSignUp === "Master Client") {
            datas = {
                client_data: {
                    parent_client: masterformData,
                    parent_client_email: "",
                    site_clients: siteClientData
                }
            }
        } else {
            datas = {
                client_data: {
                    parent_client: masterformData,
                    parent_client_email: MasterClientData.master_client_email,
                    site_clients: [siteFormData]
                }
            }
        }
        let payload = {
            type: "Customer",
            mode: "Add",
            data: datas
        }
        console.log(payload, "payload");


        try {
            const response = await SaveSignUp(payload);
            if (response?.code === 200) {
                console.log("Saved Successfully")
                if (response?.message === "409") {
                    console.log("409");

                    setShowToastProp({ type: "Error", title: "Creation Failed", message: "User Already Exist" })
                    // setShowToastProp({ toastType: "Error", toastMessage: "Creation Failed", toastDescription: "User Already Exist", isToast: true })
                    setShowToast(true)
                } else {
                    setShowToastProp({ type: "Success", title: "Created User Sccessfully", message: "User Created sccessfully" })
                    // setShowToastProp({ toastType: "Success", toastMessage: "Created User Sccessfully", toastDescription: "User Created sccessfully", isToast: true })
                    navigate("/under-review")
                    setShowToast(true)
                }
            } else if (response?.code === 500) {
                console.error("Error saving form data");
                setShowToastProp({ type: "Error", title: "Created User Failed", message: "User already exist" })
                // setShowToastProp({ toastType: "Error", toastMessage: "Created User Failed", toastDescription: "User already exist", isToast: true })
                setShowLoader(false)
                setShowToast(true)

            } else {
                console.error("Error saving form data");
                setShowToastProp({ type: "Error", title: "Created User Failed", message: "User Created Failed" })
                // setShowToastProp({ toastType: "Error", toastMessage: "Created User Failed", toastDescription: "User Created Failed", isToast: true })
                setShowLoader(false)
                setShowToast(true)
            }

        } catch (error) {
            console.error('Failed to saving form data', error);
            setShowToastProp({ type: "Error", title: "Created User Failed", message: "User Created Failed" })
            setShowToast(true)
            // setShowToastProp({ toastType: "Error", toastMessage: "Created User Failed", toastDescription: "User Created Failed", isToast: true })
            setShowLoader(false)
        }
        finally {
            setShowLoader(false)
            setTimeout(() => {
                setShowToast(false);
            }, 4000);
        }
    }
    console.log(showToast, "shw");

    const bindIndustryDropdown = () => {
        return industryDropdownData?.map((data) => {
            return (
                <>
                    <option value={data?.industry_uuid}>{data?.industry_name}</option>
                </>
            )
        })
    }

    const bindRegionDropdown = () => {
        return regionData?.map((data) => {
            return (
                <>
                    <option value={data.region_id}>{data.region_name}</option>
                </>
            )
        })
    }

    const bindStateDropdown = () => {
        return State.getStatesOfCountry('US').map((state) => (
            <option key={state.isoCode} value={state.isoCode}  >
                {state.name}
            </option>
        ))
    }

    const bindCityDropdown = () => {
        if (citiesList.length === 0) {
            setDisableCity(true)
        } else {
            setDisableCity(false)
        }
        return citiesList.map((city: ICity) => {
            return (
                <option key={city.name} value={city.name}>
                    {city.name}
                </option>
            )
        })
    }


    return (
        <div className="container-fluid position-relative h-100 min-vh-100 z-index-0 login-bg-image">
            {/* {showToastProp.isToast &&
                <Toast props={{
                    toastDescription: showToastProp.toastDescription,
                    toastMessage: showToastProp.toastMessage,
                    toastType: showToastProp.toastType,
                    setToastDetails: setShowToast,

                }}></Toast>} */}
            {showToast &&
                <CustomToast props={showToastProp} setShowToast={setShowToast}></CustomToast>}
            {showLoader && <Loader></Loader>}
            {/* <div class=" position-absolute h-100 min-vh-100"></div> */}
            <div className="row d-flex align-items-center justify-content-center h-100 position-relative ">
                <div className="col-md-7 my-5 cust-client-padding">
                    <div className="position-relative">
                        <div className="creation-container pb-5 position-relative">
                            <span className="text-center d-flex align-items-center justify-content-center logo-container">
                                <img src="../images/client-logo.svg" alt="Logo" className="mb-4" />
                            </span>
                            <h3 className="font-semibold font-27 text-center mb-4 primary-text-color">
                                Request for Account Creation
                            </h3>
                            <div className="row">
                                <div className="col-lg-12 mt-3">
                                    <label className="font-semibold font-15 primary-text-color mb-2">
                                        How would you describe yourself?
                                    </label>
                                    <div className="row">
                                        <div className="col-lg-6 col-12 d-flex mb-4">
                                            <label
                                                className={`form-check-label form-control service-form-field py-2 ps-5 position-relative ${selectedSignUp === "Master Client" ? 'active-discount account-creation' : 'account-creation'}`}
                                                htmlFor="optiontext"
                                            >
                                                <input
                                                    className="form-check-input custom-form-check-input checked"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="optiontext"
                                                    defaultValue="Master Client"
                                                    checked={selectedSignUp === "Master Client"}
                                                    onClick={() => {
                                                        setSelectedSignUp("Master Client")
                                                        SetPresentIn("Master Client")
                                                        setSiteFormData(emptyData)
                                                        SetisOtherIndustrySIte(false)
                                                        SetisOtherIndustryMaster(false)
                                                        setSumbitButtonEnabled(false)
                                                        setError(emptyError)
                                                    }}
                                                />
                                                <span className="font-semibold font-14 color-black mb-2">
                                                    Master Client
                                                </span>
                                                <span className="font-regular font-12 color-grey-v3 mb-0 d-block">
                                                    Oversees multiple locations, ensuring consistency across
                                                    sites.
                                                </span>
                                            </label>
                                        </div>
                                        <div className="col-lg-6 col-12 d-flex mb-4">
                                            <label
                                                className={`form-check-label form-control service-form-field py-2 ps-5 position-relative ${selectedSignUp === "Site Client" ? 'active-discount account-creation' : 'account-creation'}`}
                                                htmlFor="optiontext1"
                                            >
                                                <input
                                                    className="form-check-input custom-form-check-input checked"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="optiontext1"
                                                    defaultValue="Site Client"
                                                    checked={selectedSignUp === "Site Client"}
                                                    onClick={() => {
                                                        setSelectedSignUp("Site Client")
                                                        SetPresentIn("Site Client")
                                                        setSiteFormData(emptyData)
                                                        setMasterFormData(emptyData)
                                                        SetisOtherIndustrySIte(false)
                                                        SetisOtherIndustryMaster(false)
                                                        setSumbitButtonEnabled(false)
                                                        setError(emptyError)
                                                    }}
                                                />
                                                <span className="font-semibold font-14 color-black mb-2">
                                                    Site Client
                                                </span>
                                                <span className="font-regular font-12 color-grey-v3 mb-0 d-block">
                                                    Manages a specific location, overseeing user access and
                                                    resources for that site.
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {selectedSignUp === "Site Client" ?
                                    <SiteClientForm
                                        handleInputChange={handleInputChange}
                                        handleValidation={handleValidation}
                                        error={error}
                                        siteFormData={siteFormData}
                                        MasterClientData={MasterClientData}
                                        VerifyEmail={VerifyEmail}
                                        bindRegionDropdown={bindRegionDropdown}
                                        bindIndustryDropdown={bindIndustryDropdown}
                                        bindStateDropdown={bindStateDropdown}
                                        bindCityDropdown={bindCityDropdown}
                                        DisableCity={DisableCity}
                                        isOtherIndustrySIte={isOtherIndustrySIte}
                                    ></SiteClientForm>

                                    :
                                    <>
                                        <div className="col-lg-6 mb-4">
                                            <label
                                                htmlFor="FullName"
                                                className="font-semibold font-15 primary-text-color mb-2"
                                            >
                                                First Name
                                            </label>
                                            <input
                                                type="text"
                                                id="FullName"
                                                // className="form-control custom-form-fields color-black-v1 font-14 font-medium"
                                                className={`form-control custom-form-fields color-black-v1 font-14 font-medium ${error.first_name !== "" && presentIn === "Master Client" ? 'critical-field' : ''}`}
                                                placeholder="Enter First Name"
                                                name="first_name"
                                                value={masterformData.first_name}
                                                onChange={(e: any) => {
                                                    handleInputChange(e.target.name, e.target.value)
                                                    handleValidation(e.target.name, e.target.value)
                                                }}
                                            />
                                            {error.first_name !== "" && presentIn === "Master Client" &&
                                                <span className="font-14 font-medium alert-text">
                                                    {error.first_name}
                                                </span>
                                            }
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <label
                                                htmlFor="LastName"
                                                className="font-semibold font-15 primary-text-color mb-2"
                                            >
                                                Last Name
                                            </label>
                                            <input
                                                type="text"
                                                id="LastName"
                                                // className="form-control custom-form-fields color-black-v1 font-14 font-medium"
                                                className={`form-control custom-form-fields color-black-v1 font-14 font-medium ${error.last_name !== "" && presentIn === "Master Client" ? 'critical-field' : ''}`}
                                                placeholder="Enter Last Name"
                                                name="last_name"
                                                value={masterformData.last_name}
                                                onChange={(e: any) => {
                                                    handleInputChange(e.target.name, e.target.value)
                                                    handleValidation(e.target.name, e.target.value)
                                                }}
                                            />
                                            {error.last_name !== "" && presentIn === "Master Client" &&
                                                <span className="font-14 font-medium alert-text">
                                                    {error.last_name}
                                                </span>
                                            }
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <label
                                                htmlFor="business"
                                                className="font-semibold font-15 primary-text-color mb-2"
                                            >
                                                Doing Business as
                                            </label>
                                            <input
                                                type="text"
                                                id="business"
                                                className={`form-control custom-form-fields color-black-v1 font-14 font-medium ${error.doing_business_as !== "" && presentIn === "Master Client" ? 'critical-field' : ''}`}
                                                placeholder="Enter your DBA"
                                                name="doing_business_as"
                                                value={masterformData.doing_business_as}
                                                onChange={(e: any) => {
                                                    handleInputChange(e.target.name, e.target.value)
                                                    handleValidation(e.target.name, e.target.value)
                                                }}
                                            />
                                            {/* <span className="font-14 font-medium alert-text">
                                                Please enter the DBA name
                                            </span> */}
                                            {error.doing_business_as !== "" && presentIn === "Master Client" &&
                                                <span className="font-14 font-medium alert-text">
                                                    {error.doing_business_as}
                                                </span>
                                            }
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <label
                                                htmlFor="industry3"
                                                className="font-semibold font-15 primary-text-color mb-2"
                                            >
                                                Specify your Industry
                                            </label>
                                            <select

                                                id="Industry3"
                                                // className="w-100 d-block form-select custom-form-fields critical-field color-black-v1 font-14 font-medium cust-form-arrow"
                                                // className="font-semibold font-15 primary-text-color mb-2"
                                                className={`w-100 d-block form-select custom-form-fields primary-text-color font-14 font-medium ${error.industry !== "" && presentIn === "Master Client" ? 'critical-field' : ''}`}
                                                name="industry"
                                                value={masterformData.industry}
                                                onChange={(e: any) => {
                                                    handleInputChange(e.target.name, e.target.value)
                                                    handleValidation(e.target.name, e.target.value)
                                                }}

                                            >
                                                <option value="option" className="font-semibold font-15 primary-text-color mb-2">Select</option>
                                                {bindIndustryDropdown()}

                                            </select>
                                            {/* <span className="font-14 font-medium alert-text">
                                                Please select the industry type
                                            </span> */}
                                            {error.industry !== "" && presentIn === "Master Client" &&
                                                <span className="font-14 font-medium alert-text">
                                                    {error.industry}
                                                </span>
                                            }
                                        </div>
                                        {isOtherIndustryMaster &&
                                            <div className="col-lg-6 mb-4">
                                                <label
                                                    htmlFor="IndustryName"
                                                    className="font-semibold font-15 primary-text-color mb-2"
                                                >
                                                    Industry Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="IndustryName"
                                                    // className="form-control custom-form-fields  color-black-v1 font-14 font-medium"
                                                    className={`form-control custom-form-fields color-black-v1 font-14 font-medium  ${error.industry_name !== "" && presentIn === "Site Client Popup" ? 'critical-field' : ''}`}
                                                    name="industry_name"
                                                    value={masterformData.industry_name}
                                                    placeholder="Enter your Industry"
                                                    onChange={(e: any) => {
                                                        handleInputChange(e.target.name, e.target.value)
                                                        handleValidation(e.target.name, e.target.value)
                                                    }}

                                                />
                                                {error.industry_name !== "" && presentIn === "Site Client Popup" &&
                                                    <span className="font-14 font-medium alert-text">
                                                        {error.industry_name}
                                                    </span>
                                                }
                                            </div>
                                        }
                                        <div className="col-lg-6 mb-4">
                                            <label
                                                htmlFor="email"
                                                className="font-semibold font-15 primary-text-color mb-2"
                                            >
                                                Business Email Address
                                            </label>
                                            <input
                                                type="text"
                                                id="email"
                                                // className="form-control custom-form-fields critical-field color-black-v1 font-14 font-medium"
                                                className={`form-control custom-form-fields color-black-v1 font-14 font-medium ${error.email_id !== "" && presentIn === "Master Client" ? 'critical-field' : ''}`}
                                                placeholder="Enter Business Email Address"

                                                name="email_id"
                                                value={masterformData.email_id}
                                                onChange={(e: any) => {
                                                    handleInputChange(e.target.name, e.target.value)
                                                    handleValidation(e.target.name, e.target.value)
                                                }}
                                            />
                                            {/* <span className="font-14 font-medium alert-text">
                                                Please enter the business email address
                                            </span> */}
                                            {error.email_id !== "" && presentIn === "Master Client" &&
                                                <span className="font-14 font-medium alert-text">
                                                    {error.email_id}
                                                </span>
                                            }
                                            {error.unique_email !== "" && presentIn === "Master Client" &&
                                                <span className="font-14 font-medium alert-text">
                                                    {error.unique_email}
                                                </span>
                                            }
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <label
                                                htmlFor="phone"
                                                className="font-semibold font-15 primary-text-color mb-2"
                                            >
                                                Phone #
                                            </label>
                                            <input
                                                type="text"
                                                id="phone"
                                                className={`form-control custom-form-fields color-black-v1 font-14 font-medium  ${error.phone_no !== "" && presentIn === "Master Client" ? 'critical-field' : ''}`}
                                                placeholder="Enter Phone #"
                                                // pattern="\+1 \(\d{3}\) \d{3}-\d{4}"
                                                name="phone_no"
                                                maxLength={10}
                                                value={masterformData.phone_no}
                                                onChange={(e: any) => {
                                                    handleInputChange(e.target.name, e.target.value)
                                                    handleValidation(e.target.name, e.target.value)
                                                }}
                                            />
                                            {error.phone_no !== "" && presentIn === "Master Client" &&
                                                <span className="font-14 font-medium alert-text">
                                                    {error.phone_no}
                                                </span>
                                            }
                                        </div>
                                        <div className="col-lg-6 mb-4 position-relative">
                                            <label
                                                htmlFor="region"
                                                className="font-semibold font-15 primary-text-color mb-2"
                                            >
                                                Region
                                            </label>
                                            <select
                                                // name="example"
                                                id="region"
                                                // className="w-100 d-block form-select custom-form-fields critical-field color-black-v1 font-14 font-medium cust-form-arrow"
                                                className={`w-100 d-block form-select custom-form-fields color-black-v1 font-14 font-medium  ${error.region !== "" && presentIn === "Master Client" ? 'critical-field' : ''}`}
                                                name="region_id"
                                                value={masterformData.region_id}
                                                onChange={(e: any) => {
                                                    handleInputChange(e.target.name, e.target.value)
                                                    handleValidation(e.target.name, e.target.value)
                                                }}
                                            >
                                                <option value="option" className="font-semibold font-15 primary-text-color mb-2"
                                                >Select</option>
                                                {bindRegionDropdown()}
                                            </select>
                                            {error.region !== "" && presentIn === "Master Client" &&
                                                <span className="font-14 font-medium alert-text">
                                                    {error.region}
                                                </span>
                                            }
                                        </div>
                                        <div className="d-flex align-items-center gap-2 my-4">
                                            <h3 className="font-22 font-semibold color-black mb-0">
                                                Site Info
                                            </h3>
                                        </div>

                                        <div className="col-md-12">
                                            <button
                                                className="text-decoration-none link font-14 font-semibold d-flex align-items-center gap-2 p-0 px-2 mb-3 mb-sm-0 border-0 bg-transparent w-auto  "
                                                onClick={() => {
                                                    SetPresentIn("Site Client Popup")
                                                    setpopUpSiteFormData(emptyData)
                                                    setMasterError(error)
                                                    setError(emptyError)
                                                }}
                                            >
                                                <img src="../images/plus-icon.svg" alt="plus icon" />
                                                Add New Site Info
                                            </button>
                                        </div>
                                        {siteClientData?.length !== 0 &&
                                            siteClientData?.map((data) => {
                                                return (
                                                    <>
                                                        <div className="col-md-12 mb-4">
                                                            <div className="site-info-container p-2 d-flex align-items-center justify-content-between">
                                                                <div className="d-flex align-items-center flex-wrap">
                                                                    <span className=" font-16 color-black-v1 font-medium px-4 py-2">
                                                                        {data.first_name + " " + data.last_name}
                                                                    </span>
                                                                    <span className="cust-rt-border" />
                                                                    <span className="font-16 color-black-v1  font-medium px-4 py-2">
                                                                        {data.doing_business_as}
                                                                    </span>
                                                                </div>
                                                                <div className="d-flex align-items-center gap-2 pe-3">
                                                                    <button className="action-btn edit-icon btn border-0"
                                                                        onClick={() => {
                                                                            setpopUpSiteFormData(data)
                                                                            SetPresentIn("Site Client Popup")
                                                                            setIsEdit(true)
                                                                        }}>
                                                                        <img src="../images/edit-icon.svg" alt="edit" />
                                                                    </button>
                                                                    <button className="action-btn delete-icon btn border-0"
                                                                        onClick={() => {
                                                                            let clientData = siteClientData.filter((ele) => ele !== data)
                                                                            setSiteClientData(clientData)
                                                                        }}>
                                                                        <img src="../images/delete-icon.svg" alt="delete-icon" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }

                                    </>}

                            </div>
                            <div className="d-flex align-items-center justify-content-center mt-5 flex-column gap-4">
                                <button
                                    type="button"
                                    className="btn primary-login-btn font-15 font-semibold text-nowrap"
                                    disabled={
                                        presentIn === 'Master Client'
                                            ? !sumbitButtonEnabled && siteClientData.length === 0
                                            : presentIn === 'Site Client'
                                                ? !sumbitButtonEnabled
                                                : true // Disable for any other case
                                    }
                                    onClick={() => { SaveFromDetails() }}
                                >
                                    Submit Request
                                </button>
                                <span className="position-relative w-80 d-flex justify-content-center ">
                                    <span className="horizontal-line" />
                                    <span className="font-14 font-medium primary-text-color-light bg-white text-center z-index-1 p-1 px-sm-4 px-2">
                                        Already have an account
                                    </span>
                                </span>
                                <button
                                    type="button"
                                    className="btn secondary-login-btn font-14 font-medium text-nowrap"
                                    onClick={() => { navigate("/") }}
                                >
                                    Sign In
                                </button>
                            </div>
                        </div>
                        <div className="creation-gradient-container"></div>
                    </div>
                </div>
            </div>
            <p className="color-white font-semibold font-15 text-center position-absolute w-100 helpline mt-2">
                Reach our command center 24/7 at:{" "}
                <a href="tel:+1234567890" className="color-white cust-text-underline">
                    1-800-775-0805
                </a>{" "}
                |
                <a
                    href="mailto:example@example.com"
                    className="color-white cust-text-underline"
                >
                    {" "}command@ppsinctn.com
                </a>
            </p>
            {/* {site client popup} */}
            {
                presentIn === "Site Client Popup" &&
                <SiteClientPopup
                    handleInputChange={handleInputChange}
                    handleValidation={handleValidation}
                    error={error}
                    siteFormData={popUpSiteFormData}
                    bindRegionDropdown={bindRegionDropdown}
                    bindIndustryDropdown={bindIndustryDropdown}
                    bindStateDropdown={bindStateDropdown}
                    bindCityDropdown={bindCityDropdown}
                    isOtherIndustrySIte={isOtherIndustrySIte}
                    presentIn={presentIn}
                    SetPresentIn={SetPresentIn}
                    SavePopupForm={SavePopupForm}
                    PopupCancel={PopupCancel}
                    sumbitButtonEnabled={sumbitButtonEnabled}
                    DisableCity={DisableCity}
                ></SiteClientPopup>
            }
        </div >
    )
}

export default ClientSignUp;

