import { client } from '../../Client/Client';
import { getHeaderDetails} from '../../Configs/BackendRoutes';
import { backendUrl as URL  } from '../../Configs/Constant';

export const headerDetailsGet = async () => {
    const config = {
        method: 'GET',
        url: URL + getHeaderDetails.getHeaderDet,
        headers: {
            'Content-Type': 'application/json',
        },
    };
    console.log("HeaderPayload",config)
    const response = await client(config);
    console.log("getgrid",response)
    return response;
};
export const readNotifications = async (data: any) => {
    const config = {
        method: 'POST',
        url: URL + getHeaderDetails.readNotifications,
        headers: {
            'Content-Type': 'application/json',
            'Connection-ID':'12345',
            'Email-ID':'john.das@example.com',
            'User-UUID':'66d36418-f0f5-4c9a-ac03-01be6d2ac2ce',
            'User-Type':'admin'
        },
        data
    };
    const response = await client(config);
    console.log("Res",response)
    return response;
};