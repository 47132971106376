import { toastProps } from "../../Interface/Common"


export const CustomToast: React.FC<toastProps> = ({ props, setShowToast }) => {

    return (
        <>
            {props.type === "Success" ?
                <div
                    className="position-fixed top-0 start-50 translate-middle-x p-3"
                    style={{ zIndex: 999 }}
                >
                    <div
                        className="toast cust-toast w-100 p-1 toast-border show"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        <div className="toast-body d-flex align-items-center justify-content-between gap-5">
                            <div className="d-flex align-items-center gap-3">
                                <img src="../images/toast-sucess.svg" alt="Successfully" />
                                <div className="d-block">
                                    <p className="mb-0 font-4 font-semibold color-black text-nowrap mb-2"
                                    >
                                        {props.title}
                                    </p>
                                    <p className="font-medium font-14 mb-0 color-grey-v3 text-nowrap">
                                        {props.message}
                                    </p>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={() => { setShowToast(false) }}
                            />
                        </div>
                    </div>
                </div>


                :
                <div
                    className="position-fixed top-0 start-50 translate-middle-x p-3"
                    style={{ zIndex: 999 }}
                >
                    <div
                        className="toast cust-toast w-100 p-1 toast-border show"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        <div className="toast-body d-flex align-items-center justify-content-between gap-5">
                            <div className="d-flex align-items-center gap-3">
                                <img src="../images/toast-sucess.svg" alt="Successfully" />
                                <div className="d-block">
                                    <p className="mb-0 font-4 font-semibold color-black text-nowrap mb-2"
                                        style={{ color: "red" }}>
                                        {props.title}
                                    </p>
                                    <p className="font-medium font-14 mb-0 color-grey-v3 text-nowrap">
                                        {props.message}
                                    </p>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={() => { setShowToast(false) }}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}