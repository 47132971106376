function NoRecordFound() {

    return (
        <div className="d-flex align-items-center justify-content-center p-5 flex-column">
            <img
                className="no-record-img"
                src="../images/no-record.gif"
                alt="No reord"
            />
            {/* <img src="../images/no-record.svg" alt="no-record"> */}
            <p className="font-medium font-20 color-grey-v1 mb-0 mt-3 text-center">
                No Records Found
            </p>
        </div>
    )
}

export default NoRecordFound;