import React, { useEffect, useState } from 'react';
import './App.css';
import {  Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ServiceRequestForm from './Components/ServiceRequest/ServiceRequestForm';
import ServiceRequestGrid from './Components/ServiceRequest/ServiceRequestGrid';
import { HeaderComponent} from './Components/Header/Header';
import { contextValueInterface, UserDetails,Notifications, ApiResponse } from './Interface/HeaderDetails';
import { headerDetailsGet } from './Service/HeaderDetails/HeaderDetail';
import { encryptStorage,ppsContext } from './Configs/Constant';
import SignIn from './Components/Login/SignIn';
import ForgotPassword from './Components/Login/ForgotPassword';
import ResetPassword from './Components/Login/ResetPassword';
import ResetComfirm from './Components/Login/ResetPswrdComfimation';
import ClientSignUp from './Components/Login/SignUp';
import UnderReviewConfirmation from './Components/Login/UnderReview';
import { Verifications } from './Components/Login/verification';
import ServiceRequestView from './Components/ServiceRequest/ServiceRequestView';
import Profile from './Components/Profile/Profile';

function App() {
  const [userDetails, setUserDetails] = useState<UserDetails>({
    user_uuid: "",
    role: "",
    region: "",
    loginUserName: "",
    userName: "",
    rmEmail:"",
        rmPhone:""
  });
  const [notificationsCount, setNotificationsCount] = useState<number>(0);
  const [notifications, setNotifications] = useState<Notifications[]>([]);
  const contextValue: contextValueInterface = {
    userDetails,
    setUserDetails,
    notifications,
    setNotifications,
    notificationsCount,
    setNotificationsCount
  }
  useEffect(() => {
    if(window.location.pathname !== "/"){
      fetchUserDetails();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function fetchUserDetails() {
    try {
      const response = await headerDetailsGet();
      console.log("HeaderRes", response);
      let NotificationRes: ApiResponse = response;
      if (NotificationRes?.code === 200) {
        let userDetails = NotificationRes?.data;
        let user_id = encryptStorage.getItem("user_id")
        contextValue.setUserDetails((prev) => ({ ...prev, user_uuid:userDetails.user_uuid, userName:userDetails.user_name,
          role: userDetails.role,
           region: userDetails.region,loginUserName:user_id,rmEmail:userDetails.rm_email,rmPhone:userDetails.rm_phone }))
        contextValue.setNotifications(userDetails.notification_details)
        contextValue.setNotificationsCount(userDetails.unread_notifications_count)
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  }
  return (
    <>
      <Router>
      <ppsContext.Provider value={contextValue}>

      {window.location.pathname !== '/' && window.location.pathname !== '/sign-up' && <HeaderComponent />}
        <Routes>
          <Route path='' element={<SignIn />} />
          <Route path='/sign-up' element={<ClientSignUp />} />
          <Route path='/under-review' element={<UnderReviewConfirmation />} />
          <Route path='/' element={<SignIn />} />
          <Route path='/forgotPassword' element={<ForgotPassword />} />
          <Route path='/resetPassword' element={<ResetPassword />} />
          <Route path='/reset-confirm' element={<ResetComfirm />} />
          {/* <Route path='/change-password' element={<ChangePassword />} /> */}
          <Route path='/verification' element={<Verifications />} />
          <Route path='service-request' element={<ServiceRequestGrid />} />
          <Route path='fill-service-request' element={<ServiceRequestForm />} />
          <Route path='view-service-request' element={<ServiceRequestView />} />
          <Route path='profile' element={<Profile/>} />
        </Routes>
        </ppsContext.Provider>
      </Router>
    </>
  )
}

export default App;
