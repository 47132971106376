
import { equipmentCorrection, } from "../Configs/Constant";

function WebsocketClient(userId: string, topics: string[]): WebSocket {
  let queryParams = "";
  queryParams=topics.length>0?topics.map(topic => `topics=${encodeURIComponent(topic)}`).join("&"):""
  //const url: any = "http://localhost:8084"
  //const wsURL = url.replace(/^http(s)?:\/\//, "ws://");
  const wsURL ="wss://qnno8icbyi.execute-api.us-east-2.amazonaws.com/dev"
  const conn = new WebSocket(
     topics.length>0?
    `${wsURL}${equipmentCorrection.websocket}?subscriptionId=${encodeURIComponent(userId)}&${queryParams}`:
    `${wsURL}${equipmentCorrection.websocket}?subscriptionId=${encodeURIComponent(userId)}`
  );
  return conn;
}

export default WebsocketClient;
