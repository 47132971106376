import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import Loader from "../Loader/Loader";


export default function ForgotPassword() {

    const navigate = useNavigate();

    //PC_FP_06-PC_FP_07-State variables to store email id and error
    const [showLoader, setShowLoader] = useState(false);
    const [emailId, setEmailId] = useState("");
    const [error, setError] = useState("");

    //PC_FP_09-Store the email id from sign page
    // useEffect(() => {
    //     setEmailId(location?.state?.emailId);
    // }, []);

    //PC_FP_11-PC_FP_13-on change functionality for email id field
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailId(e.target.value.trim());
    }

    console.log(emailId);

    //PC_FP_14-PC_FP_21-Submit functionality for send verification code

    const handleSubmit = () => {
        // let isValid = true
        let isValid = validateEmail()
        if (isValid) {
            // showLoader();
            setShowLoader(true)
            const poolData = {
                UserPoolId: process.env.REACT_APP_USER_POOL_ID || "us-east-2_bxASDPt57",
                ClientId: process.env.REACT_APP_CLIENT_ID || "54k3f587qic1sr9197a16crm3j",
            };

            const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                Username: emailId,
                Pool: userPool,
            });
            cognitoUser.forgotPassword({

                onSuccess: function (result) {
                    // hideLoader()
                    //PC_FP_24-Navigate to verification page
                    console.log("result", result);
                    setShowLoader(false)
                    navigate("/verification", { state: { emailId: emailId } });
                },
                onFailure: function (err) {
                    console.log("err", err);
                    handleAuthFailure(err);
                    // hideLoader()
                    setShowLoader(false)
                }
            });
        }
    }


    //PC_SI_55-PC_SI_58 - Method to check error and display the error message to the user
    const handleAuthFailure = (err: any) => {
        if (err.code === "UserNotFoundException" || err.message.includes("Incorrect username or password.")) {
            setError("Incorrect Email Address or Password");
        } else if (err.code === "NotAuthorizedException") {
            setError("Sorry, you are unauthorized to access this application");
        } else {
            setError(err.message);
        }
    }

    //PC_FP_15-PC_FP_19-Validation of email
    const validateEmail = () => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|aol\.com)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailId?.trim() || !emailRegex?.test(emailId)) {
            setError("Please enter a valid email address");
            return false
        } else {
            setError("");
            return true;
        }
    }


    return (
        <>
         {showLoader && <Loader></Loader>}
            <div className="container-fluid position-relative h-100 min-vh-100">
                <div className="login-bg-image position-absolute h-100 min-vh-100" />
                {/* <img class="position-absolute login-bg-image h-100 w-100" src="../images/background-image.png" alt="backgound-image"> */}
                <div className="row d-flex align-items-center justify-content-center h-100 position-relative ">
                    <div className="col-lg-5 col-md-6 col-sm-8 my-5 cust-client-padding pb-4">
                        <div className="position-relative">
                            <div className="creation-container pb-5 px-4 px-md-5 position-relative">
                                <span className="text-center d-flex align-items-center justify-content-center logo-container">
                                    <img src="../images/client-logo.svg" alt="Logo" className="mb-4" />
                                </span>
                                <h3 className="font-semibold font-27 text-center mb-4 primary-text-color">
                                    Forgot Password
                                </h3>
                                <div className="row">
                                    <div className="col-lg-12 mb-4">
                                        <label
                                            htmlFor="email"
                                            className="font-semibold font-15 primary-text-color mb-2"
                                        >
                                            Email Address
                                        </label>
                                        <input
                                            type="text"
                                            id="email"
                                            className="form-control custom-form-fields color-black-v1 font-16 font-medium"
                                            placeholder="Enter Email Address"
                                            name="emailID"
                                            onChange={(e) => { handleOnChange(e) }}
                                        />
                                        {error !== "" &&
                                            <span className="font-14 font-medium alert-text">
                                                {error}
                                            </span>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-3 flex-column gap-4">
                                    <button
                                        type="button"
                                        className="btn primary-login-btn font-15 font-semibold text-nowrap w-100"
                                        onClick={() => { handleSubmit() }}
                                    >
                                        Reset Password
                                    </button>
                                    <span className="position-relative w-80 d-flex justify-content-center ">
                                        <span className="horizontal-line" />
                                        <span className="font-14 font-medium primary-text-color-light bg-white z-index-1 p-1 px-4 text-center"
                                            onClick={() => { navigate("/") }}>
                                            Remember Password
                                        </span>
                                    </span>
                                    <button
                                        type="button"
                                        className="btn secondary-login-btn font-14 font-medium w-100 text-nowrap"
                                        onClick={() => { navigate('/'); }}
                                    >
                                        Sign In
                                    </button>
                                </div>
                            </div>
                            <div className="creation-gradient-container"></div>
                        </div>
                    </div>
                </div>
                <p className="color-white font-semibold font-15 text-center position-absolute w-100 helpline mt-2">
                    Reach our command center 24/7 at:{" "}
                    <a href="tel:+1234567890" className="color-white cust-text-underline">
                        1-800-775-0805
                    </a>{" "}
                    |
                    <a
                        href="mailto:example@example.com"
                        className="color-white cust-text-underline"
                    >
                        {" command@ppsinctn.com"}
                    </a>
                </p>
            </div>

        </>
    )
}