export default function UnderReviewConfirmation() {

    return (
        <>
            <div className="container-fluid position-relative text-center h-100 min-vh-100 login-bg-image">
                <div className="row d-flex align-items-center justify-content-center vh-100 Login-bg-image position-relative ">
                    <div className="col-md-9 col-lg-7 col-xl-5 my-4 cust-client-padding">
                        <div className="position-relative">
                            <div className="creation-container border position-relative pb-2 pb-sm-5">
                                <span className="text-center d-flex align-items-center justify-content-center logo-container">
                                    <img
                                        src="../images/connect-app-logo.svg"
                                        alt="Logo"
                                        className="mb-4"
                                    />
                                </span>
                                <h3 className="font-semibold font-27 text-center mb-4 primary-text-color">
                                    You will hear back from us soon
                                </h3>
                                <p className="font-16 font-medium primary-text-color mb-4 text-center px-1 px-sm-5 cust-lh">
                                    Your details are under review. Once reviewed by our team, you will
                                    receive an confirmation email shortly.
                                </p>
                            </div>
                            <div className="creation-gradient-container" />
                        </div>
                    </div>
                </div>
                <p className="color-white font-semibold font-15 text-center position-absolute w-100 helpline mt-2">
                    Reach our command center 24/7 at:{" "}
                    <a href="tel:+1234567890" className="color-white cust-text-underline">
                        1-800-775-0805
                    </a>{" "}
                    |
                    <a
                        href="mailto:example@example.com"
                        className="color-white cust-text-underline"
                    >
                        command@ppsinctn.com
                    </a>
                </p>
            </div>
        </>
    )
}