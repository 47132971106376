import React from 'react';

interface FlagModalProps {
  filter: any; // Replace 'any' with the actual type
  onClose: () => void;
  type?:string;
}

const FlagModal: React.FC<FlagModalProps> = ({ filter, onClose,type }) => {

  return (
    <>{type==="Contacts" && 
      <div className="modal fade show" id="exampleModal5" tabIndex={-1} aria-labelledby="exampleModalLabel" style={{display: 'block'}} aria-modal="true" role="dialog">
      <div className="modal-dialog modal-dialog-centered   modal-lg">
        <div className="modal-content border-radius-10">
          <div className="modal-header border-0 px-4 pt-4 align-items-center">
            <h5 className="modal-title font-bold font-18 color-black-v2" id="exampleModalLabel">
              Contact our PPS Key People</h5>
            <button type="button" className="btn-close custom-btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}/>
          </div>
          <div className="modal-body px-4 py-2">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-4">
                <span className="font-16 font-medium color-black d-flex align-items-center gap-2">
                  <img className="icon-sty" src="../images/cantact-us-call.svg" alt="call" />
                  Call us @
                </span>
                <div className="row mt-2 ps-4">
                  <div className="col-lg-12 mb-4">
                    <p className="font-medium font-14 color-grey-v3 mb-1">Headquarters
                    </p>
                    <a href="tel:+1234567890" className="color-black font-14 font-medium text-decoration-none">654 434 8866</a>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <p className="font-medium font-14 color-grey-v3 mb-1">Regional Director
                    </p>
                    <a href="tel:+1234567890" className="color-black font-14 font-medium text-decoration-none">{filter.RMPhone}</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <span className="font-16 font-medium color-black d-flex align-items-center gap-2">
                  <img className="icon-sty" src="../images/contact-us-mail.svg" alt="mail" />
                  Let us know
                </span>
                <div className="row mt-2 ps-4">
                  <div className="col-lg-6 col-12 mb-4">
                    <p className="font-medium font-14 color-grey-v3 mb-1">Your Regional Manager Email
                    </p>
                    <a href="mailto:example@example.com" className="color-black font-14 font-medium text-decoration-none">{filter.RMEmail}</a>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <p className="font-medium font-14 color-grey-v3 mb-1">Billing Email
                    </p>
                    <a href="mailto:example@example.com" className="color-black font-14 font-medium text-decoration-none">bill_info@pps.com</a>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <p className="font-medium font-14 color-grey-v3 mb-1">Our Manager Head Email
                    </p>
                    <a href="mailto:example@example.com" className="color-black font-14 font-medium text-decoration-none">davidjeffery@pps.com</a>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <p className="font-medium font-14 color-grey-v3 mb-1">Our CEO Email
                    </p>
                    <a href="mailto:example@example.com" className="color-black font-14 font-medium text-decoration-none">Raleigh@pps.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    }
        </>
    
   
  );
};

export default FlagModal;

