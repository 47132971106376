/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom";
import { ppsContext } from "../../Configs/Constant";
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { contextValueInterface } from "../../Interface/SignIn";
import Loader from "../Loader/Loader";


export default function ResetPassword() {

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    //PC_SI_09-PC_SI_14 - Declaration and initialization of state variables 
    const [resetDetails, setResetDetails] = useState({
        userID: "",
        oldPassword: "",
        newPassword: ""
    });
    const [resetError, setResetError] = useState({
        userID: "",
        oldPassword: "",
        newPassword: "",
        Error: ""
    });

    //PC_SI_16-PC_SI_17 - Handle onchange values of fields
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setResetDetails({ ...resetDetails, [e.target.name]: e.target.value.trim() });
    }
    const location = useLocation();
    const navigate = useNavigate();
    const StateData = location.state;
    console.log(StateData, "StateDataStateDataStateData");
    console.log(resetDetails, "logindetailss");

    const contextValue: contextValueInterface = useContext(ppsContext)

    useEffect(() => {
        setResetDetails((prev) => ({ ...prev, userID: contextValue.userDetails.loginUserName }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //PC_SI_23-PC_SI_30-Method to authenticate the user in cognito and invoke Post Login APIs
    const ResetPassword = async () => {
        setShowLoader(true)
        const poolData = {
            UserPoolId: process.env.REACT_APP_USER_POOL_ID || "us-east-2_bxASDPt57",
            ClientId: process.env.REACT_APP_CLIENT_ID || "54k3f587qic1sr9197a16crm3j",
        };

        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: resetDetails.userID.trim(),
            Pool: userPool,
        });
        const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
            Username: resetDetails.userID.trim(),
            Password: resetDetails.oldPassword.trim()
        });

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                console.log('Authentication successful:', result);

                // Now that the user is authenticated, change the password
                const newPassword = resetDetails.newPassword.trim();
                setShowLoader(false)
                cognitoUser.changePassword(resetDetails.oldPassword.trim(), newPassword, function (err, result) {
                    if (err) {
                        console.log('Error changing password:', err);
                        setShowLoader(false)
                        return;
                    }
                    navigate("/");
                    console.log('Password changed successfully:', result);
                });
            },
            onFailure: function (err) {
                setShowLoader(false)
                console.log('Authentication failed:', err);
            }
        });
    }

    // const ChangeNewPassword = async () => {
    //     try {

    //         const poolData = {
    //             UserPoolId: 'us-east-2_bxASDPt57',
    //             ClientId: '3f5rfhd2fhcujiva99a44egt5t',
    //             ClientSecret: 'os8f8oot6t3scer1t1p4ohvviqlekf59djclm9n497ks5bk498p', // Add your client secret
    //             Region: Region, // Add your region
    //         };
    //         const client = new CognitoIdentityProviderClient({ region: poolData.Region, });
    //         const secretHash = generateSecretHash(resetDetails.userID.trim(), poolData.ClientId, poolData.ClientSecret);
    //         let sesss = encryptStorage.getItem("session")
    //         console.log(sesss);

    //         // Function to respond to the new password challenge
    //         const respondToAuthChallengeCommand = new RespondToAuthChallengeCommand({
    //             ChallengeName: "NEW_PASSWORD_REQUIRED",
    //             ClientId: poolData.ClientId,
    //             Session: StateData.session,
    //             ChallengeResponses: { NEW_PASSWORD: resetDetails.newPassword, USERNAME: resetDetails.userID, SECRET_HASH: secretHash, }
    //         });

    //         // const respondToAuthChallengeCommand = new RespondToAuthChallengeCommand({
    //         //     ChallengeName: "NEW_PASSWORD_REQUIRED",
    //         //     ClientId: poolData.ClientId,
    //         //     ChallengeResponses: {
    //         //         requiredAttributes: [],
    //         //         USERNAME: resetDetails.userID,
    //         //         NEW_PASSWORD: resetDetails.newPassword,
    //         //         SECRET_HASH: secretHash,
    //         //     },
    //         //     ChallengeResponses: { 'userAttributes.name': resetDetails.userID, NEW_PASSWORD: resetDetails.newPassword, USERNAME: resetDetails.userID },
    //         //     Session: StateData.session,
    //         // });


    //         const response = await client.send(respondToAuthChallengeCommand,);
    //         console.log(response);
    //         return response;
    //     } catch (error) {
    //         console.error("Error responding to new password challenge:", error);
    //     }


    // }
    //PC_RP_32-PC_RP_43-Method to reset password
    const ChangeNewPassword = () => {
        // let isValid = true
        setShowLoader(true)
        let isValid = validateFields()
        if (isValid) {
            // showLoader()
            const poolData = {
                UserPoolId: process.env.REACT_APP_USER_POOL_ID || "us-east-2_bxASDPt57",
                ClientId: process.env.REACT_APP_CLIENT_ID || "54k3f587qic1sr9197a16crm3j",
            };

            const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                Username: resetDetails.userID.trim(),
                Pool: userPool,
            });
            const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
                Username: resetDetails.userID.trim(),
                Password: resetDetails.oldPassword.trim()
            });


            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (result) {
                },
                onFailure: function (err) {
                    console.log(err);
                    setShowLoader(false)
                },

                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    cognitoUser.completeNewPasswordChallenge(

                        resetDetails.newPassword.trim(), // Use the current password as the new password
                        requiredAttributes,
                        {
                            onSuccess: async function (result) {

                                var idToken = result.getIdToken().getJwtToken();
                                var accessToken = result.getAccessToken().getJwtToken();
                                //PC_REF_39- Store the refresh token
                                var refreshToken = result.getRefreshToken().getToken()

                                localStorage.setItem("accessToken", accessToken)
                                localStorage.setItem("idToken", idToken)
                                localStorage.setItem("refreshToken", refreshToken)

                                const decodedToken = jwtDecode(accessToken) as { [key: string]: any };
                                const username = decodedToken["username"];
                                const expirationTime = decodedToken["exp"]; // Extract the expiration time from the decoded token                       

                                if (username) {
                                    localStorage.setItem("emailId", username)
                                }
                                if (expirationTime) {
                                    const expirationDate = new Date(expirationTime * 1000); // Convert expiration time to milliseconds
                                    localStorage.setItem("accessTokenExpiration", expirationDate.toISOString()); // Store expiration time as a date string in session storage
                                } else {
                                    console.error("Access token expiration time not found in decoded token.");
                                    // Handle the absence of expiration time, set a default value, or throw an error as appropriate
                                }
                                setShowLoader(false)
                                navigate("/");
                                // Check if the user's role matches the required role

                            },
                            onFailure: function (err) {
                                // hideLoader()
                                setShowLoader(false)
                                setResetError({ ...resetError, Error: err.message })
                            }
                        }
                    );
                }
            });

        }

    }



    //PC_SI_18-PC_SI_21-Method to validate the input fields  
    const validateFields = () => {
        let isValid = true;

        if (resetDetails?.userID?.trim() === "") {
            setResetError(prevState => ({
                ...prevState,
                userID: "Please enter a valid email address",
            }));
            isValid = false;
        } else {
            setResetError(prevState => ({
                ...prevState,
                userID: "",
            }));
        }
        if (resetDetails?.oldPassword?.trim() === "") {
            setResetError(prevState => ({
                ...prevState,
                oldPassword: "Please enter your password",
            }));
            isValid = false;
        } else {
            setResetError(prevState => ({
                ...prevState,
                oldPassword: "",
            }));
        }
        if (resetDetails?.newPassword?.trim() === "") {
            setResetError(prevState => ({
                ...prevState,
                newPassword: "Please enter your password",
            }));
            isValid = false;
        } else {
            setResetError(prevState => ({
                ...prevState,
                newPassword: "",
            }));
        }
        if (resetDetails?.newPassword?.trim() === resetDetails?.oldPassword?.trim()) {
            setResetError(prevState => ({
                ...prevState,
                newPassword: "Please enter new password",
            }));
            isValid = false;
        } else {
            setResetError(prevState => ({
                ...prevState,
                newPassword: "",
            }));
        }
        return isValid;
    }

    return (
        <>
            {showLoader && <Loader></Loader>}
            <div className="container-fluid position-relative h-100 min-vh-100">
                <div className="login-bg-image position-absolute h-100 min-vh-100" />
                <div className="row d-flex align-items-center justify-content-center h-100 position-relative ">
                    <div className="col-lg-5 col-sm-9 my-4 cust-client-padding">
                        <div className="position-relative">
                            <div className="creation-container pb-4 position-relative">
                                <span className="text-center d-flex align-items-center justify-content-center logo-container">
                                    <img
                                        src="../images/command-app-logo.svg"
                                        alt="Logo"
                                        className="mb-4"
                                    />
                                </span>
                                <h3 className="font-semibold font-27 text-center mb-4 primary-text-color">
                                    Sign In
                                </h3>
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <label
                                            htmlFor="userID"
                                            className="font-semibold font-15 primary-text-color mb-2"
                                        >
                                            User ID
                                        </label>
                                        <input
                                            type="text"
                                            id="userID"
                                            className="form-control custom-form-fields color-black-v1 font-16 font-medium"
                                            placeholder="Enter your User ID"
                                            name="userID"
                                            value={resetDetails.userID}
                                            onChange={handleOnChange}
                                        />
                                        {resetError.userID !== "" &&
                                            <span className="font-14 font-medium alert-text">
                                                {resetError.userID}
                                            </span>
                                        }
                                    </div>
                                    <div className="col-lg-12 mb-4 position-relative">
                                        <label
                                            htmlFor="oldPassword"
                                            className="font-semibold font-15 primary-text-color mb-2"
                                        >
                                            Current Password
                                        </label>
                                        <input
                                            type={showOldPassword ? 'text' : 'password'}
                                            id="oldPassword"
                                            className="form-control custom-form-fields color-black-v1 font-16 font-medium pe-5"
                                            placeholder="Enter your password"
                                            name="oldPassword"
                                            value={resetDetails.oldPassword}
                                            onChange={handleOnChange}
                                        />
                                        <button className="position-absolute field-icons p-0 border-0 bg-transparent"
                                            onClick={() => setShowOldPassword(!showOldPassword)}
                                        >
                                            <img src="../images/eye-icon.svg" alt="eye-icon" />
                                        </button>
                                        {resetError.oldPassword !== "" &&
                                            <span className="font-14 font-medium alert-text">
                                                {resetError.oldPassword}
                                            </span>
                                        }
                                    </div>
                                    <div className="col-lg-12 mb-4 position-relative">
                                        <label
                                            htmlFor="newPassword"
                                            className="font-semibold font-15 primary-text-color mb-2"
                                        >
                                            New Password
                                        </label>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            id="newPassword"
                                            className="form-control custom-form-fields color-black-v1 font-16 font-medium pe-5"
                                            placeholder="Enter your password"
                                            name="newPassword"
                                            value={resetDetails.newPassword}
                                            onChange={handleOnChange}
                                        />
                                        <button className="position-absolute field-icons p-0 border-0 bg-transparent"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            <img src="../images/eye-icon.svg" alt="eye-icon" />
                                        </button>
                                        {resetError.newPassword !== "" &&
                                            <span className="font-14 font-medium alert-text">
                                                {resetError.newPassword}
                                            </span>
                                        }
                                    </div>
                                    {resetError.Error !== "" &&
                                        <span className="font-14 font-medium alert-text">
                                            {resetError.Error}
                                        </span>
                                    }
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-4 flex-column gap-4">
                                    {StateData?.type === "NEW_PASSWORD_REQUIRED" || "Change Password" ?
                                        <button
                                            type="button"
                                            className="btn primary-login-btn font-15 font-semibold text-nowrap w-100"
                                            onClick={ChangeNewPassword}
                                        >
                                            Change Password
                                        </button>
                                        :
                                        <button
                                            type="button"
                                            className="btn primary-login-btn font-15 font-semibold text-nowrap w-100"
                                            onClick={ResetPassword}
                                        >
                                            Reset Password
                                        </button>
                                    }
                                    {StateData?.type === "Change Password" &&
                                        <a className="font-15 font-medium link-v2"
                                            onClick={() => { navigate("/forgotPassword") }}>
                                            Forgot Password
                                        </a>
                                    }
                                    {/* <span className="position-relative w-80 d-flex justify-content-center ">
                                        <span className="horizontal-line" />
                                        <span className="font-14 font-medium primary-text-color-light bg-white z-index-1 p-1 px-sm-4 px-2 text-center">
                                            Doesn’t have an account
                                        </span>
                                    </span>
                                    <button
                                        type="button"
                                        className="btn secondary-login-btn font-14 font-medium w-50 text-nowrap"
                                    >
                                        Request an Account
                                    </button> */}
                                </div>
                            </div>
                            <div className="creation-gradient-container"></div>
                        </div>
                    </div>
                </div>
                <p className="color-white font-semibold font-15 text-center position-absolute w-100 helpline mt-2">
                    Reach our command center 24/7 at:{" "}
                    <a href="tel:+1234567890" className="color-white cust-text-underline">
                        1-800-775-0805
                    </a>{" "}
                    |
                    <a
                        href="mailto:example@example.com"
                        className="color-white cust-text-underline"
                    >
                        command@ppsinctn.com
                    </a>
                </p>
            </div>
        </>
    )
}