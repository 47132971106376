import { useNavigate } from "react-router-dom";

export default function ResetComfirm() {
    const navigate = useNavigate();

    return (
        <>
            <div className="container-fluid position-relative text-center h-100 min-vh-100">
                {/* <img class="position-absolute login-bg-image h-100 w-100" src="../images/background-image.png" */}
                {/* alt="backgound-image"> */}
                <div className="login-bg-image position-absolute h-100 min-vh-100" />
                <div className="row d-flex align-items-center justify-content-center vh-100 Login-bg-image position-relative ">
                    <div className="col-lg-5 col-sm-9 my-5 cust-client-padding">
                        <div className="position-relative">
                            <div className="creation-container px-sm-5 pb-4 pb-sm-5 position-relative">
                                <span className="text-center d-flex align-items-center justify-content-center logo-container">
                                    <img src="../images/client-logo.svg" alt="Logo" className="mb-4" />
                                </span>
                                <h3 className="font-semibold font-27 text-center mb-4 primary-text-color">
                                    Reset Confirmation
                                </h3>
                                <p className="font-16 font-medium color-grey-v6 mb-4 text-center px-sm-4 cust-lh">
                                    Password Reset Successfully
                                </p>
                                <div className="d-flex align-items-center justify-content-center mt-sm-5 flex-column gap-4">
                                    <button
                                        type="button"
                                        className="btn primary-login-btn font-15 font-semibold text-nowrap w-100"
                                        onClick={() => { navigate("/") }}

                                    >
                                        Go to Sign In
                                    </button>
                                </div>
                            </div>
                            <div className="creation-gradient-container"></div>
                        </div>
                    </div>
                </div>
                <p className="color-white font-semibold font-15 text-center position-absolute w-100 helpline mt-2">
                    Reach our command center 24/7 at:{" "}
                    <a href="tel:+1234567890" className="color-white cust-text-underline">
                        1-800-775-0805
                    </a>{" "}
                    |
                    <a
                        href="mailto:example@example.com"
                        className="color-white cust-text-underline"
                    >
                        {" "}command@ppsinctn.com
                    </a>
                </p>
            </div>
        </>
    )
}