/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useContext, useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useLocation, useNavigate } from "react-router-dom";
import { ServiceRequestDetails, ServiceRequestDynamicData, ServiceRequestDynamicDataResponse, ValidateServiceRequestDetails } from "../../Interface/ServiceRequest";
import { createServiceRequest, fetchSingleServiceRequest, getServiceRequestDynamicData, updateServiceRequest } from "../../Service/ServiceRequest/ServiceRequestService";
import Calendar, { DateObject } from 'react-multi-date-picker'; // New Date Picker import
import moment from 'moment';
import ConfirmRequest from "./ConfirmRequest";
import ServiceRequestSubmittedPopup from "./ServiceRequestSubmittedPopup";
import Loader from "../Loader/Loader";


function ServiceRequestForm() {

    const navigate = useNavigate();
    const { state }: any = useLocation();


    const [validateServiceRequest, setValidateServiceRequest] = useState<ValidateServiceRequestDetails>({
        service_request: false,
        location_type_uuid: false,
        cause_of_incident_uuid: false,
        dates: false,
        start_time: false,
        end_time: false,
        officer_request_type: false,
        requested_duties: false,
        address_line_1: false,
        address_line_2: false,
        zip_code: false,
        city: false,
        state: false,
        store_details: false,
        service_details: false,
        discount_uuid: false
    });
    const [serviceRequestDetails, setServiceRequestDetails] = useState<ServiceRequestDetails>({
        service_request: "",
        location_type_uuid: "",
        cause_of_incident_uuid: "",
        dates: { start_date: "", end_date: "" },
        start_time: "",
        end_time: "",
        officer_request_type: [],
        requested_duties: [],
        address_line_1: "",
        address_line_2: "",
        zip_code: "",
        city: "",
        state: "",
        store_details: [],
        service_details: "",
        discount_uuid: "",
    });
    const [serviceRequestDynamicData, setServiceRequestDynamicData] = useState<ServiceRequestDynamicData>();
    const [isStoreDetails, setIsStoreDetails] = useState<boolean>(false);
    const [isConfirmRequest, setIsConfirmRequest] = useState<boolean>(false);
    const [isSubmittedSuccess, setIsSubmittedSuccess] = useState<boolean>(false);
    const [prevServiceDetails, setPrevServiceDetails] = useState<any>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [navigatedValue, setNavigatedValue] = useState<any>(state);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // const ppsContextValue = useContext(ppsContext);

    useEffect(() => {
        handlePageload();
    }, []);

    const handlePageload = async () => {
        try {
            await fetchDynamicData();
            if (state?.id) {
                await fetchServiceRequest()
            }
        }
        catch (error: any) {
            console.log(error.message, "handlePageload")
        }
    };

    const fetchDynamicData = async () => {
        try {
            setIsLoading(true)
            const response: ServiceRequestDynamicDataResponse = await getServiceRequestDynamicData();
            if (response && response?.code === 200) {
                const address = response.data?.services_address[0]
                setServiceRequestDynamicData(response.data)
                setServiceRequestDetails({ ...serviceRequestDetails, ...address })
            }
            setIsLoading(false);
        }
        catch (error: any) {
            console.log(error.message, "fetchDynamicData")
        }
    };

    const fetchServiceRequest = async () => {
        try {
            setIsLoading(true);
            let response = await fetchSingleServiceRequest(state?.id)
            if (response.code === 200) {
                setServiceRequestDetails(response.data);
                setPrevServiceDetails(response.data);
            }
            setIsLoading(false);
        } catch (error: any) {
            console.log(error, "fetchServiceRequest")
        }
    }

    console.log(serviceRequestDetails, "serviceRequestDetails")

    const handleServiceRequest = (name: string, value?: string | boolean | number) => {
        try {
            let updatedDetails: any = { ...serviceRequestDetails };
            let updatedValidation: any = { ...validateServiceRequest };

            if (name === "is_store_details" && value) {
                updatedDetails.store_details = [{}];
            } else if (name === "is_store_details" && !value) {
                updatedDetails.store_details = [];
            } else if (name === "add_another") {
                updatedDetails.store_details.push({});
            } else {
                updatedDetails[name] = value;
            }

            // Validate the field
            updatedValidation[name] = !validateField(name, value);

            setServiceRequestDetails(updatedDetails);
            setValidateServiceRequest(updatedValidation);
        } catch (error: any) {
            console.log(error.message, "handleServiceRequest");
        }
    };


    const validateField = (name: string, value: any): boolean => {
        switch (name) {
            case "service_request":
            case "address_line_1":
            case "city":
            case "state":
                return value != null && !!value.toString().trim();
            case "zip_code":
                return /^\d{5,10}$/.test(value);
            case "officer_request_type":
            case "requested_duties":
                return Array.isArray(value) && value?.length > 0;
            case "store_details":
                if (isStoreDetails) {
                    return Array.isArray(value) && value.every((store: any) =>
                        store != null &&
                        !!store.store_number && /^\d+$/.test(store.store_number) &&
                        !!(store.store_name?.trim())
                    );
                }
                return true;
            default:
                return value != null && !!value;
        }
    };

    const handleMultiSelect = (type: string, value: any, action: boolean) => {
        try {
            let updatedDetails = { ...serviceRequestDetails };
            let valueObj = updatedDetails[type as keyof ServiceRequestDetails] as any[];

            if (action) {
                valueObj.push(value);
            } else {
                valueObj = valueObj.filter((obj: any) =>
                    obj[type === "officer_request_type" ? "request_type_uuid" : "officer_duty_uuid"] !==
                    value[type === "officer_request_type" ? "request_type_uuid" : "officer_duty_uuid"]
                );
            }

            updatedDetails[type as keyof ServiceRequestDetails] = valueObj;
            setServiceRequestDetails(updatedDetails);

            // Validate after update
            setValidateServiceRequest(prev => ({
                ...prev,
                [type]: !validateField(type, valueObj)
            }));
        } catch (error: any) {
            console.log(error.message, "handleMultiSelect");
        }
    };

    const handleMultiSelectOnchange = (type: string, name: string, value: string | number, index: number) => {
        try {
            let updatedDetails = { ...serviceRequestDetails };
            let valueObj = updatedDetails[type as keyof ServiceRequestDetails] as any[];

            if (type === "store_details" && (name === "store_number" || name === "store_name")) {
                if (name === "store_number" && !/^\d*$/.test(value.toString())) {
                    return; // Only allow numeric input for store number
                }
                valueObj[index][name] = value;
            } else {
                valueObj[index][name] = value;
            }

            updatedDetails[type as keyof ServiceRequestDetails] = valueObj;
            setServiceRequestDetails(updatedDetails);

            // Validate after update
            if (type === "store_details") {
                setValidateServiceRequest(prev => ({
                    ...prev,
                    store_details: !validateField("store_details", valueObj)
                }));
            }
        } catch (error: any) {
            console.log(error, "handleMultiSelectOnchange");
        }
    };

    const validateServiceRequestDetails = (): boolean => {
        let isValid = true;
        const validationResult = { ...validateServiceRequest };

        Object.keys(serviceRequestDetails).forEach((key) => {
            const isFieldValid = validateField(key, serviceRequestDetails[key as keyof ServiceRequestDetails]);
            validationResult[key as keyof ValidateServiceRequestDetails] = !isFieldValid;
            if (!isFieldValid) isValid = false;
        });

        setValidateServiceRequest(validationResult);
        return isValid;
    }

    const submitServiceRequest = () => {
        try {
            if (validateServiceRequestDetails()) {
                setIsConfirmRequest(true);
            }
        } catch (error: any) {
            console.log(error, "submitServiceRequest");
        }
    };

    const saveServiceRequest = async () => {
        try {
            if (!validateServiceRequestDetails()) {
                console.log('Validation failed!');
                return;
            }

            const serviceRequestObj = {
                ...serviceRequestDetails,
                service_request_uuid: navigatedValue?.id,
                response_time: navigatedValue?.responseTime,
                is_modified: JSON.stringify(prevServiceDetails) === JSON.stringify(serviceRequestDetails) ? 0 : 1,
                is_extended_time: navigatedValue?.status === "in-progress" ?
                    (prevServiceDetails?.dates?.start_date !== serviceRequestDetails?.dates?.start_date) || (prevServiceDetails?.dates?.end_date !== serviceRequestDetails?.dates?.end_date) ||
                        (prevServiceDetails?.start_time !== serviceRequestDetails?.start_time) || (prevServiceDetails?.end_time !== serviceRequestDetails?.end_time) ? 1 : 0 : 0,
                prev_start_date: navigatedValue?.status === "in-progress" ?
                    (prevServiceDetails?.dates?.start_date !== serviceRequestDetails?.dates?.start_date) || (prevServiceDetails?.dates?.end_date !== serviceRequestDetails?.dates?.end_date) ||
                        (prevServiceDetails?.start_time !== serviceRequestDetails?.start_time) || (prevServiceDetails?.end_time !== serviceRequestDetails?.end_time) ? prevServiceDetails?.dates?.start_date : null : null,
                prev_end_date: navigatedValue?.status === "in-progress" ?
                    (prevServiceDetails?.dates?.start_date !== serviceRequestDetails?.dates?.start_date) || (prevServiceDetails?.dates?.end_date !== serviceRequestDetails?.dates?.end_date) ||
                        (prevServiceDetails?.start_time !== serviceRequestDetails?.start_time) || (prevServiceDetails?.end_time !== serviceRequestDetails?.end_time) ? prevServiceDetails?.dates?.end_date : null : null,
                prev_start_time: navigatedValue?.status === "in-progress" ?
                    (prevServiceDetails?.dates?.start_date !== serviceRequestDetails?.dates?.start_date) || (prevServiceDetails?.dates?.end_date !== serviceRequestDetails?.dates?.end_date) ||
                        (prevServiceDetails?.start_time !== serviceRequestDetails?.start_time) || (prevServiceDetails?.end_time !== serviceRequestDetails?.end_time) ? prevServiceDetails?.start_time : null : null,
                prev_end_time: navigatedValue?.status === "in-progress" ?
                    (prevServiceDetails?.dates?.start_date !== serviceRequestDetails?.dates?.start_date) || (prevServiceDetails?.dates?.end_date !== serviceRequestDetails?.dates?.end_date) ||
                        (prevServiceDetails?.start_time !== serviceRequestDetails?.start_time) || (prevServiceDetails?.end_time !== serviceRequestDetails?.end_time) ? prevServiceDetails?.end_time : null : null
            };
            setIsLoading(true);
            const response = navigatedValue?.id && !navigatedValue?.isDuplicate
                ? await updateServiceRequest(serviceRequestObj)
                : await createServiceRequest(serviceRequestObj);

            if (response.code === 200 || response.code === 201) {
                setIsConfirmRequest(false);
                setIsSubmittedSuccess(true);
            } else {
                setIsConfirmRequest(false);
                // Handle error
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error occurred: ', error);
        }
    };

    console.log(validateServiceRequest, "validateServiceRequest")

    return (
        <>
            {isLoading ? <Loader /> : <div className="container-fluid">
                <div className="row service-form-padding">
                    <div className="d-flex align-items-center gap-3 mb-5">
                        <a className="icon-round-btn cust-round-width cursor-pointer"
                            onClick={() => { navigate(-1) }} >
                            <img
                                className="icon-sty"
                                src="../images/back-arrow.svg"
                                alt="back-arrow"
                            />
                        </a>
                        <h2 className="color-black-v2 font-600 font-24 mb-0">
                            Service Request Form
                        </h2>
                    </div>
                    <div className="col-lg-6 mb-4 mb-lg-5">
                        <div className="position-relative">
                            <label
                                htmlFor="service"
                                className="font-semibold font-18 color-black-v2 mb-2"
                            >
                                Service Request <span className="madatory-text">*</span>
                            </label>
                            <p className="font-regular font-12 color-grey cust-mb">
                                Let us know the service request
                            </p>
                            <input
                                type="text"
                                id="service"
                                className="form-control service-form-field color-black-v2 font-14 font-regular"
                                placeholder="Enter your service request"
                                name="service_request"
                                value={serviceRequestDetails.service_request}
                                onChange={(e: any) => { handleServiceRequest(e.target.name, e.target.value) }}
                            />
                            {validateServiceRequest.service_request && <span className="font-14 font-medium alert-text">
                                {'Please Enter Your Service Request'}
                            </span>}
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4 mb-lg-5">
                        <div className="position-relative">
                            <label
                                htmlFor="Location"
                                className="font-semibold font-18 color-black-v2 mb-2"
                            >
                                Location Type <span className="madatory-text">*</span>
                            </label>
                            <p className="font-regular font-12 color-grey cust-mb">
                                Select the location type
                            </p>
                            <select
                                name="location_type_uuid"
                                id="Location"
                                className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular"
                                value={serviceRequestDetails.location_type_uuid}
                                onChange={(e: any) => { handleServiceRequest(e.target.name, e.target.value) }}
                            >
                                <option value="">Select</option>
                                {serviceRequestDynamicData?.location_type?.map((value, index) => (
                                    <option value={value.location_uuid} key={index}>{value.location_name}</option>
                                ))}
                            </select>
                            {validateServiceRequest.location_type_uuid && <span className="font-14 font-medium alert-text">
                                {'Please Select the Location Type'}
                            </span>}
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4 mb-lg-5">
                        <div className="position-relative">
                            <label
                                htmlFor="Incident"
                                className="font-semibold font-18 color-black-v2 mb-2"
                            >
                                Cause of Incident <span className="madatory-text">*</span>
                            </label>
                            <p className="font-regular font-12 color-grey cust-mb">
                                Select the cause of Incident
                            </p>
                            <select
                                name="cause_of_incident_uuid"
                                id="Incident"
                                className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular"
                                value={serviceRequestDetails.cause_of_incident_uuid}
                                onChange={(e: any) => { handleServiceRequest(e.target.name, e.target.value) }}
                            >
                                <option value="">Select</option>
                                {serviceRequestDynamicData?.cause_of_incident?.map((value, index) => (
                                    <option value={value.incident_uuid} key={index}>{value.incident_name}</option>
                                ))}
                            </select>
                            {validateServiceRequest.cause_of_incident_uuid && <span className="font-14 font-medium alert-text">
                                {'Please Select the Cause of Incident'}
                            </span>}
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4 mb-lg-5">
                        <div className="position-relative">
                            <label
                                htmlFor="Date1"
                                className="font-semibold font-18 color-black-v2 mb-2"
                            >
                                Choose a Service Date
                                <span className="madatory-text">*</span>
                            </label>
                            <p className="font-regular font-12 color-grey cust-mb">
                                When its happening?
                            </p>
                            {/* <img
                            src="../images/calender-icon.svg"
                            alt="calendar-icon"
                            className="calendar-date cursor-pointer"
                        // onClick={() => inputRef.current[index] && inputRef.current[index]?.click()}
                        /> */}
                            <Calendar
                                id="Date1"
                                key={`calendar`}
                                range
                                className="w-100"
                                numberOfMonths={2}
                                format="MM/DD/YYYY"
                                onChange={(e: DateObject[]) => {
                                    let dates = {
                                        start_date: e[0] ? moment(e[0].toDate()).format('MM/DD/YYYY') : '',
                                        end_date: e[1] ? moment(e[1].toDate()).format('MM/DD/YYYY') : ''
                                    };
                                    setServiceRequestDetails({ ...serviceRequestDetails, dates: dates });
                                }}
                                render={(value, openCalendar) => {
                                    return (
                                        <input
                                            style={{
                                                width: '100%',
                                                fontFamily: "Montserrat-Regular", // Default font family
                                                fontSize: "14px", // Default font size
                                                cursor: 'pointer',
                                                color: 'Black',
                                                padding: '10px', // Add padding if needed
                                                border: '1px solid #ccc', // Add a border if needed
                                                borderRadius: '5px' // Add border-radius if needed
                                            }}
                                            value={
                                                serviceRequestDetails.dates.start_date && serviceRequestDetails.dates.end_date
                                                    ? `${moment(serviceRequestDetails.dates.start_date).format("MM/DD/YYYY")} - ${moment(serviceRequestDetails.dates.end_date).format("MM/DD/YYYY")}`
                                                    : ""
                                            }
                                            onClick={openCalendar}
                                            readOnly
                                            className="fcalendar-month-name service-form-field color-black-v2 font-14 font-regular w-100"
                                            placeholder="Select date"
                                        />
                                    );
                                }}
                                mapDays={({ date }) => {
                                    const props: any = {};
                                    const isToday = date.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD");
                                    const isSelectedStart = serviceRequestDetails.dates.start_date &&
                                        date.format("YYYY-MM-DD") === moment(serviceRequestDetails.dates.start_date).format("YYYY-MM-DD");
                                    const isSelectedEnd = serviceRequestDetails.dates.end_date &&
                                        date.format("YYYY-MM-DD") === moment(serviceRequestDetails.dates.end_date).format("YYYY-MM-DD");
                                    const isInRange = isSelectedStart || isSelectedEnd || (serviceRequestDetails.dates.start_date && serviceRequestDetails.dates.end_date && date.toDate() >= moment(serviceRequestDetails.dates.start_date).toDate() && date.toDate() <= moment(serviceRequestDetails.dates.end_date).toDate());

                                    // Disable previous dates
                                    if (date.toDate() < new Date()) {
                                        props.disabled = true;
                                        props.style = { color: isToday ? "#009FB8" : "#ccc" };
                                        return props;
                                    }

                                    if (isInRange) {
                                        props.style = {
                                            ...props.style,
                                            backgroundColor: isSelectedStart || isSelectedEnd ? "#009FB8" : "#e0f7fa",
                                            color: isSelectedStart || isSelectedEnd ? "#fff" : "#009FB8",
                                            borderRadius: "50%",
                                            fontWeight: "bold",
                                            fontFamily: "Montserrat-Regular" // Custom font for selected date
                                        };
                                    }

                                    // Highlight today's date if it's not disabled
                                    if (isToday) {
                                        props.style = {
                                            ...props.style,
                                            color: "#009FB8",
                                            fontWeight: "bold",
                                            borderRadius: "50%",
                                            fontFamily: "Montserrat-Regular" // Default font for today's date
                                        };
                                    }

                                    return props;
                                }}
                            />
                            {validateServiceRequest.dates && <span className="font-14 font-medium alert-text">
                                {'Please Select a Service Date'}
                            </span>}
                            {/* Ends here */}
                        </div>
                    </div >
                    <div className="col-lg-6 mb-4 mb-lg-5">
                        <div className="position-relative">
                            <label
                                htmlFor="Date1"
                                className="font-semibold font-18 color-black-v2 mb-2"
                            >
                                Choose a Service Time
                                <span className="madatory-text">*</span>
                            </label>
                            <p className="font-regular font-12 color-grey cust-mb">
                                When its happening?
                            </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <label
                                        htmlFor="Date1"
                                        className="font-semibold font-12 color-black-v2 mb-2"
                                    >
                                        Start Time
                                    </label>
                                    <input
                                        type="time"
                                        id="Date1"
                                        className="form-control service-form-field color-black-v2 font-14 font-regular"
                                        name="start_time"
                                        value={serviceRequestDetails.start_time}
                                        onChange={(e: any) => { handleServiceRequest(e.target.name, e.target.value) }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label
                                        htmlFor="Date1"
                                        className="font-semibold font-12 color-black-v2 mb-2"
                                    >
                                        End Time
                                    </label>
                                    <input
                                        type="time"
                                        id="Date1"
                                        className="form-control service-form-field color-black-v2 font-14 font-regular"
                                        name="end_time"
                                        value={serviceRequestDetails.end_time}
                                        onChange={(e: any) => { handleServiceRequest(e.target.name, e.target.value) }}
                                    />
                                </div>
                            </div>
                            {(validateServiceRequest.start_time || validateServiceRequest.end_time) && <span className="font-14 font-medium alert-text">
                                {'Please Select a Service Time'}
                            </span>}
                        </div>
                    </div>
                    <div className="col-lg-12 mb-4 mb-lg-5">
                        <div className="position-relative">
                            <label className="font-semibold font-18 color-black-v2 mb-2">
                                Officer Requested Types <span className="madatory-text">*</span>
                            </label>
                            <p className="font-regular font-12 color-grey cust-mb">
                                Which request do you need?
                            </p>
                            <div className="d-flex align-items-center flex-wrap">
                                {serviceRequestDynamicData?.officer_request_type?.map((value, index) => {
                                    const isSelected = serviceRequestDetails?.officer_request_type?.some(
                                        (type: any) => type.request_type_uuid === value.request_type_uuid
                                    );

                                    return (
                                        <button
                                            key={index}
                                            className={`outline-btn font-medium font-12 m-2 d-flex align-items-center gap-2 ${isSelected ? 'active' : ''}`}
                                            onClick={() => handleMultiSelect("officer_request_type", value, !isSelected)}
                                        >
                                            <img src="../images/armed-guards.svg" alt="armed-guards" />
                                            {value.request_type_name}
                                        </button>
                                    );
                                })}
                            </div>
                            {validateServiceRequest.officer_request_type &&
                                <span className="font-14 font-medium alert-text">Please select at least one Officer Request Type</span>}
                        </div>
                    </div>
                    {serviceRequestDetails?.officer_request_type?.length > 0 && (
                        <div className="col-lg-12 mb-4 mb-lg-5">
                            <div className="position-relative">
                                <label className="font-semibold font-18 color-black-v2 mb-2">
                                    Security People Count <span className="mandatory-text">*</span>
                                </label>
                                <p className="font-regular font-12 color-grey cust-mb">
                                    How many security people do you want for the service?
                                </p>
                                <div className="row align-items-center flex-wrap">
                                    {serviceRequestDetails.officer_request_type?.map((value: any, index: number) => (
                                        <div className="col-lg-4 col-sm-6 col-12" key={index}>
                                            <label htmlFor={`armed-${index}`} className="font-medium font-12 color-grey mb-2">
                                                {value?.request_type_name} Count
                                            </label>
                                            <input
                                                id={`armed-${index}`}
                                                type="number" // changed to number
                                                className={`form-control service-form-field color-black-v2 font-14 font-regular`}
                                                placeholder={`Enter ${value?.request_type_name} Count`}
                                                name="request_count"
                                                value={value.request_count}
                                                onChange={(e: any) => handleMultiSelectOnchange("officer_request_type", e.target.name, Number(e.target.value), index)}
                                            />
                                            {validateServiceRequest.requested_duties &&
                                                <span className="font-14 font-medium alert-text">Please enter a valid number</span>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-lg-12 mb-4 mb-lg-5">
                        <div className="position-relative">
                            <label className="font-semibold font-18 color-black-v2 mb-2">
                                Officer Requested Duties
                                <span className="madatory-text">*</span>
                            </label>
                            <p className="font-regular font-12 color-grey cust-mb">
                                What duties will the officer be responsible for?
                            </p>
                            <div className="d-flex align-items-center flex-wrap">
                                {serviceRequestDynamicData?.officer_duties?.map((value, index) => {
                                    const isSelected = serviceRequestDetails.requested_duties.some(
                                        (duty: any) => duty.officer_duty_uuid === value.officer_duty_uuid
                                    );

                                    return (
                                        <button
                                            className={`outline-btn font-medium font-12 m-2 d-flex align-items-center gap-2 ${isSelected ? 'active' : ''}`}
                                            key={index}
                                            onClick={() => handleMultiSelect("requested_duties", value, !isSelected)}
                                        >
                                            <img src="../images/binacular.svg" alt="observe icon" key={index} />
                                            {value.officer_duty}
                                        </button>
                                    );
                                })}

                                {serviceRequestDetails.requested_duties.some((obj: any) => obj.officer_duty === "Others") && (
                                    <input
                                        id="unarmed"
                                        type="text"
                                        className="form-control service-form-field color-black-v2 font-14 font-regular w-auto"
                                        placeholder="Enter Request Duty"
                                        name="other_request_type"
                                        value={
                                            serviceRequestDetails.requested_duties.find((obj: any) => obj.officer_duty === "Others")?.other_request_type || ""
                                        }
                                        onChange={(e: any) => handleMultiSelectOnchange("requested_duties", e.target.name, e.target.value, serviceRequestDetails.requested_duties.findIndex((obj: any) => obj.officer_duty === "Others"))}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mb-4 mb-lg-5">
                        <div className="position-relative">
                            <label className="font-semibold font-18 color-black-v2 mb-2">
                                Service Location <span className="madatory-text">*</span>
                            </label>
                            <p className="font-regular font-12 color-grey cust-mb">
                                Where its happening?
                            </p>
                            <div className="d-flex align-items-center justify-content-between my-3">
                                <div className="d-flex align-items-center gap-2">
                                    <input
                                        id="add"
                                        className="form-check-input custom-checkbox m-0"
                                        type="checkbox"
                                        name="is_store_details"
                                        checked={isStoreDetails}
                                        onChange={(e) => { handleServiceRequest(e.target.name, !isStoreDetails); setIsStoreDetails(!isStoreDetails) }}
                                    />
                                    <label
                                        htmlFor="add"
                                        className="font-medium font-13 color-grey cursor-pointer"
                                    >
                                        Add Store Info
                                    </label>
                                </div>
                                {/* <button className="text-decoration-none link font-14 font-semibold d-flex align-items-center gap-2 p-0 px-2 mb-sm-0 border-0 bg-transparent w-auto text-nowrap">
                                Select Service Location
                            </button> */}
                            </div>
                            <div className="row align-items-center flex-wrap ">
                                {isStoreDetails && serviceRequestDetails.store_details?.length > 0 && serviceRequestDetails.store_details?.map((value: any, index: number) => (
                                    <div className="row align-items-center flex-wrap ">
                                        <div className="col-md-3 mb-3 mb-lg-0 p-2">
                                            <input
                                                type="text"
                                                className="form-control service-form-field color-black font-14 font-medium"
                                                placeholder="Store Number"
                                                name="store_number"
                                                value={serviceRequestDetails.store_details[index].store_number}
                                                onChange={(e: any) => { handleMultiSelectOnchange("store_details", e.target.name, e.target.value, index) }}
                                            />
                                        </div>
                                        <div className="col-md-3 mb-3 mb-lg-0 p-2">
                                            <input
                                                type="text"
                                                className="form-control service-form-field color-black font-14 font-medium"
                                                placeholder="Store Name"
                                                name="store_name"
                                                value={serviceRequestDetails.store_details[index].store_name}
                                                onChange={(e: any) => { handleMultiSelectOnchange("store_details", e.target.name, e.target.value, index) }}
                                            />
                                        </div>
                                    </div>
                                ))}
                                {serviceRequestDetails.store_details?.length > 0 &&
                                    <button className="text-decoration-none link font-14 font-semibold d-flex align-items-center gap-2 p-0 px-2 mb-3 mb-sm-0 border-0 bg-transparent w-auto"
                                        onClick={() => { handleServiceRequest("add_another") }} >
                                        <img src="../images/plus-icon.svg" alt="plus icon" />
                                        Add Another
                                    </button>}
                            </div>
                            <div className="row align-items-center flex-wrap">
                                <div className="col-md-3 mb-3 mb-lg-0 p-2">
                                    <input
                                        type="text"
                                        className="form-control service-form-field color-black font-14 font-medium"
                                        placeholder="Enter Address line 1"
                                        defaultValue="123 Spring Street"
                                        name="address_line_1"
                                        value={serviceRequestDetails.address_line_1}
                                        onChange={(e: any) => { handleServiceRequest(e.target.name, e.target.value) }}
                                    />
                                    {validateServiceRequest.address_line_1 &&
                                        <span className="font-14 font-medium alert-text">Please enter Address line 1</span>}
                                </div>
                                <div className="col-md-3 mb-3 mb-lg-0 p-2">
                                    <input
                                        type="text"
                                        className="form-control service-form-field color-black font-14 font-medium"
                                        placeholder="Enter Address line 2"
                                        name="address_line_2"
                                        value={serviceRequestDetails.address_line_2}
                                        onChange={(e: any) => { handleServiceRequest(e.target.name, e.target.value) }}
                                    />
                                    {validateServiceRequest.address_line_2 &&
                                        <span className="font-14 font-medium alert-text">Please enter Address line 2</span>}
                                </div>
                                <div className="col-md-2 mb-3 mb-lg-0 p-2">
                                    <input
                                        type="text"
                                        className="form-control service-form-field color-black font-14 font-medium"
                                        placeholder="Zip Code"
                                        name="zip_code"
                                        value={serviceRequestDetails.zip_code}
                                        onChange={(e: any) => { handleServiceRequest(e.target.name, Number(e.target.value)) }}
                                    />
                                    {validateServiceRequest.zip_code &&
                                        <span className="font-14 font-medium alert-text">Please enter a valid Zip Code</span>}
                                </div>
                                <div className="col-md-2 col-6 mb-3 mb-lg-0 p-2">
                                    <input
                                        type="text"
                                        className="form-control service-form-field color-black font-14 font-medium"
                                        placeholder="City"
                                        name="city"
                                        value={serviceRequestDetails.city}
                                        onChange={(e: any) => { handleServiceRequest(e.target.name, e.target.value) }}
                                    />
                                    {validateServiceRequest.city &&
                                        <span className="font-14 font-medium alert-text">Please enter city</span>}
                                </div>
                                <div className="col-md-2 col-6 mb-3 mb-lg-0 p-2">
                                    <input
                                        type="text"
                                        className="form-control service-form-field color-black font-14 font-medium"
                                        placeholder="State"
                                        name="state"
                                        value={serviceRequestDetails.state}
                                        onChange={(e: any) => { handleServiceRequest(e.target.name, e.target.value) }}
                                    />
                                    {validateServiceRequest.state &&
                                        <span className="font-14 font-medium alert-text">Please enter state</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mb-4 mb-lg-5">
                        <div className="position-relative">
                            <label className="font-semibold font-18 color-black-v2 mb-2">
                                About Service Details
                            </label> <span className="madatory-text">*</span>
                            {validateServiceRequest?.service_details && <span className="font-14 font-medium alert-text">
                                {'Please Enter the Service Details'}</span>}
                            <p className="font-regular font-12 color-grey cust-mb">
                                Describe your service briefly here
                            </p>
                            <div className="row align-items-center flex-wrap">
                                <div className="col-md-12">
                                    <textarea
                                        className="form-control service-form-field w-100 custom-textarea font-14 font-regular"
                                        placeholder="Enter the description about the service"
                                        name="service_details"
                                        value={serviceRequestDetails.service_details}
                                        onChange={(e: any) => { handleServiceRequest(e.target.name, e.target.value) }}
                                        maxLength={255}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mb-4 mb-lg-5">
                        <div className="position-relative">
                            <label className="font-semibold font-18 color-black-v2 mb-2">
                                Apply Discounts
                            </label> <span className="madatory-text">*</span>
                            {validateServiceRequest?.discount_uuid && <span className="font-14 font-medium alert-text">
                                {'Please Select a Discount Option'}</span>}
                            <p className="font-regular font-12 color-grey cust-mb">
                                Receive discounts on services by making timely payments.
                            </p>
                            <div className="row align-items-center flex-wrap">
                                <div className="col-md-12">
                                    {serviceRequestDynamicData?.discounts?.map((value, index) => (
                                        <label
                                            className={`form-check-label form-control service-form-field py-2 mb-3 ps-5 position-relative ${serviceRequestDetails.discount_uuid === value.discount_uuid ? 'active-discount' : ''}`}
                                            htmlFor="optiontext" key={index}
                                        >
                                            <input
                                                className={`form-check-input custom-form-check-input ${serviceRequestDetails.discount_uuid === value.discount_uuid ? 'checked' : ""}`}
                                                type="radio"
                                                name="discount_uuid"
                                                id="optiontext"
                                                checked={serviceRequestDetails.discount_uuid === value.discount_uuid ? true : false}
                                                key={index}
                                                value={value.discount_uuid}
                                                onChange={(e) => { handleServiceRequest(e.target.name, e.target.value) }}
                                            />
                                            <span className="font-semibold font-12 color-black mb-2">
                                                {value.discount_amount !== "No" ? value.discount_amount + '%' : value.discount_amount} Discount in Invoiced amount
                                            </span>
                                            <span className="font-regular font-12 color-black mb-0 d-block">
                                                Complete payment in {value.discount_days} Days and get the Discount offer
                                            </span>
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end gap-5  mt-5">
                        <button className="btn px-0 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                            onClick={() => { navigate(-1) }}>
                            Cancel
                        </button>
                        <button
                            className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                            disabled={navigatedValue?.id ? JSON.stringify(prevServiceDetails) === JSON.stringify(serviceRequestDetails) ? true : false : false}
                            onClick={() => { submitServiceRequest() }}
                        >
                            Submit
                        </button>
                    </div>
                    {isConfirmRequest && <ConfirmRequest props={{
                        serviceRequestDetails: serviceRequestDetails,
                        setServiceRequestDetails: setServiceRequestDetails,
                        saveServiceRequest: saveServiceRequest,
                        isConfirmRequest: isConfirmRequest,
                        setIsConfirmRequest: setIsConfirmRequest,
                        serviceRequestDynamicData: serviceRequestDynamicData
                    }}
                    />}
                    {(isConfirmRequest || isSubmittedSuccess) && <div className="modal-backdrop fade show"></div>}
                    {isSubmittedSuccess && <ServiceRequestSubmittedPopup />}
                </div >
            </div >
            }
        </>
    )
}

export default ServiceRequestForm;