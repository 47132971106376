import { EncryptStorage } from "encrypt-storage";
import { createContext } from "react";
import { ProfileAcknowledgmentsInterface } from "../Interface/Profile";

export const encryptStorage = new EncryptStorage("jhvklrgdjldfflsdbfajnlksvdbsfalksdbsglvkfbdvnsalsdlnsvassvjan", {
    stateManagementUse: true,
    storageType: "localStorage",
});

// export const backendUrl = 'http://pps-dev-eu2-alb-719323620.us-east-2.elb.amazonaws.com'
export const backendUrl = 'https://ho0wtlk8dg.execute-api.us-east-2.amazonaws.com/dev'
// export const backendUrl2 = "http://localhost:8081"


export const ppsContext: any = createContext("");

export function getDifferenceInMinutes(date1: string): number {
    // Parse the dates
    const d1 = new Date(new Date().toISOString());
    const d2 = new Date(date1);

    // Get the difference in milliseconds
    const diffInMs = Math.abs(d1.getTime() - d2.getTime());

    // Convert difference from milliseconds to minutes
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

    return diffInMinutes;
}

export const equipmentCorrection = {
    websocket:"/v1/api/pps-notification/subscribe"
}


export const isRegexValidMobile: (value: string) => boolean = (
    value: string
  ) => {
    return contactRegexFormat?.test(value);
  };

export const contactRegexFormat: RegExp = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;
export function usPhoneNumberFormat(phoneNumber: string) {
    // Remove non-numeric characters
    const cleaned = phoneNumber?.replace(/\D/g, '');
    
    // Ensure the cleaned number has exactly 10 digits
    if (cleaned?.length !== 10) {
        return '-';
    }
  
    // Extract area code, first 3 digits, and last 4 digits
    const match = cleaned?.match(/(\d{3})(\d{3})(\d{4})/);
  
    if (match) {
        return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
    }
  
    return '-';
  }

export function formatPhoneNumber(phoneNumber: string): string |undefined {
    // Ensure the input is exactly 9 digits
    try {
        if (phoneNumber.length !== 10 || !/^\d+$/.test(phoneNumber)) {
            // throw new Error("Input must be a 9-digit string.");
        }
        
         // Extract parts of the phone number
         const areaCode = phoneNumber.slice(0, 3);
         const firstPart = phoneNumber.slice(3, 6);
         const secondPart = phoneNumber.slice(6, 9);
     
         // Format the phone number
         return `(${areaCode}) ${firstPart}-${secondPart}`;
        
    } catch (error) {
        console.log(error, "Error in Viewing Phone Number in Profile")
    }

   
}



export function daysUntilExpiry(expiryDateStr: string): number {
    // Parse the expiry date string
    // const [day, month, year] = expiryDateStr.split('-').map(Number);
    const [year, month, day] = expiryDateStr.split('-').map(Number);

    const expiryDate = new Date(year, month - 1, day); // month is 0-indexed in JS Date
    
    // Get today's date
    const today = new Date();
    
    // Reset the time part of the dates to ensure we're only comparing dates
    expiryDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    
    // Calculate the difference between the dates
    const diffTime = expiryDate.getTime() - today.getTime();
    const daysLeft = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    return daysLeft;
}


export function extractWebsiteName(url: string): string {
    // Regular expression to match the domain name
    // eslint-disable-next-line no-useless-escape
    const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/i;
    
    // Try to match the domain
    const match = url.match(domainRegex);
    
    if (match && match[1]) {
      // Extract the domain name
      let domain = match[1];
      
      // Remove TLD (Top Level Domain) if present
      domain = domain.split('.').slice(0, -1).join('.');
      
      // Handle common social media sites
      switch (domain.toLowerCase()) {
        case 'facebook':
        case 'fb':
          return 'Facebook';
        case 'instagram':
          return 'Instagram';
        case 'twitter':
          return 'Twitter';
        case 'linkedin':
          return 'LinkedIn';
        case 'youtube':
          return 'YouTube';
        default:
          // Capitalize the first letter of each word for other websites
          return domain.split('.').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
          ).join(' ');
      }
    }
    
    // Return null or a default value if no match is found
    return 'Link';
  }

  export function convertStringToObject(inputString: string): ProfileAcknowledgmentsInterface {
    // Split the string by commas to get key-value pairs
    const pairs = inputString?.split(',');

    // Initialize an empty object with the specified interface type
    const result: ProfileAcknowledgmentsInterface = {
        subcontracting: false,
        drugScreen: false,
        randomDrugScreening: false,
        backgroundCheck: false
    };

    // Iterate over each pair and split by colon to get key and value
    pairs.forEach(pair => {
        const [key, value] = pair?.split(':');
        // Convert value to boolean and add to object
        result[key as keyof ProfileAcknowledgmentsInterface] = value?.toLowerCase() === 'true';
    });

    return result;
}

