import { useNavigate } from "react-router-dom";

function ServiceRequestSubmittedPopup() {

    const navigate = useNavigate();

    return (
        <div
            className="modal fade show"
            id="exampleModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="false"
            style={{ display: "block" }}
        >
            <div className="modal-dialog modal-dialog-centered   ">
                <div className="modal-content border-radius-10">
                    <div className="modal-header border-0 px-4 pt-3 justify-content-center flex-column gap-2">
                        <span className="sucess-container">
                            <span className="sucess-sub-container">
                                <img
                                    className="sucess-icon"
                                    src="../images/success.svg"
                                    alt="success"
                                />
                            </span>
                        </span>
                        <h5
                            className="modal-title font-bold font-18 color-black-v2 mb-0"
                            id="exampleModalLabel"
                        >
                            Service Request Submitted
                        </h5>
                        <p className="mb-0 font-15 font-medium color-grey-v2">
                            Service request has been posted. Here’s what’s next:
                        </p>
                    </div>
                    <div className="modal-body px-4 pt-2 pb-4">
                        <div className="row px-3 justify-content-center">
                            <ul className="list-style-none">
                                <li className="font-medium font-14 text-color-2 d-flex gap-3 mb-3">
                                    <span className="list-count font-13 font-semibold">1</span>
                                    <span className="w-90">
                                        Your request is submitted to PPS and can be updated anytime.
                                    </span>
                                </li>
                                <li className="font-medium font-14 text-color-2 d-flex gap-3 mb-3">
                                    <span className="list-count font-13 font-semibold">2</span>
                                    <span className="w-90">PPS makes an offer for you</span>
                                </li>
                                <li className="font-medium font-14 text-color-2 d-flex gap-3 mb-3">
                                    <span className="list-count font-13 font-semibold">3</span>
                                    <span className="w-90">
                                        You will receive a confirmation of the offer within 90 min.
                                    </span>
                                </li>
                            </ul>
                            <div className="d-block text-center">
                                <p className="font-13 font-medium text-color-2 text-center mb-0">
                                    If you need any additional information, please contact our
                                    regional managing director
                                </p>
                                <p className="font-13 font-medium text-color-2 text-center mb-0">
                                    <span className="font-bold">Sebastien</span> at{" "}
                                    <a
                                        href="mailto:example@example.com"
                                        className="font-bold text-color-2 text-decoration-none"
                                    >
                                        Sebastien.r@pps.com
                                    </a>
                                </p>
                            </div>
                            <button
                                className="btn primary-btn color-white font-semibold font-14 primary-btn px-5 w-auto mt-4"
                                onClick={() => {
                                    navigate('/service-request')
                                }}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceRequestSubmittedPopup;